import React, { Component } from 'react';
import uniqueid from 'uniqid';
import { orderBy } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Cryptr from 'cryptr';
import ReactSvgLineChart from 'react-svg-line-chart';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import {
  showPortfolioRightPanel,
  showPortfolioStakeholderRightPanel,
  changeSelectedPortfolioAndProject,
  getPortfolioDashbordProjects,
  showOpenTextRightPanel,
  showOpenTextNonMatchRightPanel,
  getUserFiltersForPortfolio,
  showOpenTextDropQuesRightPanel,
} from '../actions/portfolio';
import portfolioConstant from '../actions/constant';
import '../styles/portfolio.scss';
import Authorise from '../../../Authorise';
import {
  isAnyPortfolioExist,
  getSelectedPortfolio,
  getSelectedProject,
  getPortfolio,
} from '../selectors/portfolio';
import { updateProjectSaveState } from '../../project/actions/project';
import RenderIf from '../../../ui-components/Common';
import { Spinner } from '../../../ui-components/Loader';

import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { levelOneOrganizations, levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import '../../../../assets/js/delta-scroll';
import expandCardsSvg from '../../../../assets/images/component/svg/expand-cards.svg';
import collapseCardsSvg from '../../../../assets/images/component/svg/collapse-cards.svg';
import settingIconSvg from '../../../../assets/images/component/svg/setting-icon.svg';
import settingEditIconSvg from '../../../../assets/images/component/svg/setting-edit-icon.svg';
import { showHideOverlay } from '../../common/overlay/actions/overlay';
import Feedback from '../../../ui-components/Feedback/Feedback.jsx';

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

// dummy blur graph data
const trendBlurData = [
  { x: 1, y: 58 },
  { x: 2, y: 58 },
  { x: 3, y: 73 },
  { x: 4, y: 64 },
  { x: 5, y: 13 },
  { x: 6, y: 24 },
  { x: 7, y: 92 },
];

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardColumns: [],
      isLoaded: false,
      activePoint: [],
      activePosition: null,
      tooltipPosition: 'right',
      insufficientDataForAllTopics: false,
      currentPorfolioId: 0,
      isExpandWidget: false,
      dashboardWidgetsData: [],
    };
    this.showPortfolioRightPanel = this.showPortfolioRightPanel.bind(this);
    this.showPortfolioStakeholderRightPanel = this.showPortfolioStakeholderRightPanel.bind(this);
    this.getApplicableRole = this.getApplicableRole.bind(this);
    this.onWidgetExpand = this.onWidgetExpand.bind(this);
    this.resetWidgetsPosition = this.resetWidgetsPosition.bind(this);
    this.onWidgetCollapsed = this.onWidgetCollapsed.bind(this);
    this.openProject = this.openProject.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.getKeyPhrases = this.getKeyPhrases.bind(this);
    this.setTooltipPosition = this.setTooltipPosition.bind(this);
    this.showOpenTextDropQuesRightPanel = this.showOpenTextDropQuesRightPanel.bind(this);
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    const portfolioId = this.props.match.params.id;
    if (portfolioId || this.props.selectedPortfolio.PortfolioId) {
      const decryptedString = portfolioId ? cryptr.decrypt(portfolioId)
        : this.props.selectedPortfolio.PortfolioId;
      const encryptedPortfolioId = cryptr.encrypt(decryptedString);
      const that = this;
      this.props.changeSelectedPortfolioAndProject(parseInt(decryptedString, 10), null).then(() => {
        that.navigate(`/portfolio-dashboard/${encryptedPortfolioId}`);
        that.getDashboardDetails();
      });
    } else if (this.props.selectedPortfolio.PortfolioId
      && this.props.selectedPortfolio.PortfolioId !== 0) {
      this.getDashboardDetails();
    } else {
      this.setState({
        isLoaded: true,
      });
    }
    $('body').on('mousewheel', '.data-scroller-container', function (event, delta) {
      if (delta === -1) {
        if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
          this.scrollLeft -= (delta * 30);
        }
      } else if (delta === 1) {
        if ($(this).scrollTop() === 0) {
          this.scrollLeft -= (delta * 30);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPortfolio.PortfolioId
      && ((this.props.selectedPortfolio.PortfolioId !== prevProps.selectedPortfolio.PortfolioId)
        || (this.props.projectDetails.isSaved
          && this.props.selectedPortfolio.PortfolioId === prevProps.selectedPortfolio.PortfolioId
          && JSON.stringify(prevProps.portfolioData.portfoliosData)
          !== JSON.stringify(this.props.portfolioData.portfoliosData)
        )
      )
    ) {
      this.setState({ currentPorfolioId: 0 });
      this.props.updateProjectSaveState();
      this.props.changeSelectedPortfolioAndProject(this.props.selectedPortfolio.PortfolioId, null);
      this.getDashboardDetails();
    }
  }

  getDashboardDetails() {
    const { PortfolioId } = this.props.selectedPortfolio;
    const { portfoliosData } = this.props.portfolioData;
    const { isSysAdmin } = this.props;
    this.setState({ currentPorfolioId: PortfolioId });
    const that = this;
    if (PortfolioId) {
      this.setState({
        isLoaded: false,
        insufficientDataForAllTopics: false,
        isExpandWidget: false,
      });
      // find portfolio associated projects id
      const selectedPortfolioData = portfoliosData.find(p => p.PortfolioId === PortfolioId);
      if (isSysAdmin
        || (selectedPortfolioData.PortfolioAdmin || selectedPortfolioData.PortfolioStakeholder)) {
        const selectedPortfolioProjects = selectedPortfolioData.items.map(pr => pr.ProjectId);
        // get projects widgets data
        if (selectedPortfolioProjects[0] !== null) {
          this.props.getPortfolioDashbordProjects(
            PortfolioId,
          ).then((resp) => {
            const ProjectWidgets = resp && resp.ProjectWidgets;
            const projectTiles = [];
            if (ProjectWidgets) {
              if (resp.portfolioId
                === this.state.currentPorfolioId) {
                const projectWidgetsLength = Math.ceil(ProjectWidgets.length / 2);
                const colWidget = projectWidgetsLength > 1
                  ? portfolioConstant.maxProjectRowsLength
                  : portfolioConstant.minProjectRowsLength;
                // set parameters for isExpanded or columnIndex
                for (let i = 0; i < colWidget; i += 1) {
                  const widgets = [];

                  if (ProjectWidgets.length > 2) {
                    widgets.push(ProjectWidgets[projectWidgetsLength * i]);
                    for (let j = 1; j < projectWidgetsLength; j += 1) {
                      if (ProjectWidgets.length > projectWidgetsLength * i + j) {
                        widgets.push(ProjectWidgets[projectWidgetsLength * i + j]);
                      }
                    }
                  } else {
                    widgets.push(ProjectWidgets[2 * i]);
                    if (ProjectWidgets.length > 2 * i + 1) widgets.push(ProjectWidgets[2 * i + 1]);
                  }

                  widgets.forEach((pWidget, index) => {
                    pWidget.isExpanded = false;
                    pWidget.columnIndex = index;
                    projectTiles.push(pWidget);
                  });
                }
                that.setState({ dashboardWidgetsData: ProjectWidgets });
                that.setWidgetsAndWidth(projectTiles,
                  ProjectWidgets.length);
              }
            } else {
              that.resetDashboardColumnState();
            }
          });
          this.props.getUserFiltersForPortfolio(PortfolioId);
        } else {
          this.resetDashboardColumnState();
        }
      } else {
        const { ProjectId } = this.props.selectedPortfolio;
        this.props.changeSelectedPortfolioAndProject(PortfolioId, ProjectId);
        this.navigate(`/project-dashboard/${cryptr.encrypt(PortfolioId)}/${cryptr.encrypt(ProjectId)}`);
      }
    } else {
      this.setState({
        isLoaded: true,
      });
    }
  }

  resetDashboardColumnState() {
    this.setState({
      dashboardColumns: [],
      isLoaded: true,
    });
  }

  navigate(path) {
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  showPortfolioRightPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.props.showHideOverlay(true);
    this.props.showPortfolioRightPanel(true);
  }


  showPortfolioStakeholderRightPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.props.showHideOverlay(true);
    this.props.showPortfolioStakeholderRightPanel(true);
  }

  getApplicableRole() {
    const { roles } = this.props;
    let role = '';
    if (roles) {
      if (roles.portfolioAdministrator) role = 'portfolioAdministrator';
      else if (roles.portfolioStakeholder) {
        role = 'portfolioStakeholder';
      }
    }
    return role;
  }


  // change UI width according widgets & its position
  setWidgetsAndWidth(widgets, widgetsLength) {
    const expandedWidgetCount = widgets.filter(w => w.isExpanded).length;
    const unexpandedWidgetCount = widgets.filter(w => !w.isExpanded).length;
    const totalColumns = Math.ceil(expandedWidgetCount + unexpandedWidgetCount / 2);
    const countTotalColumns = widgetsLength > 2
      ? totalColumns : portfolioConstant.maxProjectRowsLength;
    const columnsData = [];
    for (let i = 0; i < countTotalColumns; i += 1) {
      const column = {
        index: i,
        widgetsData: widgets.filter(w => w.columnIndex === i),
      };
      columnsData.push(column);
    }
    columnsData.forEach((column) => {
      column.widgetsData = orderBy(column.widgetsData, 'title', 'asc');
    });
    this.setState({
      dashboardColumns: columnsData,
      isLoaded: true,
    });
  }

  // open project
  openProject(widget) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const { PortfolioId } = this.props.selectedPortfolio;
    const newProjectId = widget.projectId;
    this.props.changeSelectedPortfolioAndProject(PortfolioId, newProjectId, true);
    this.navigate(`/project-dashboard/${cryptr.encrypt(PortfolioId)}/${cryptr.encrypt(newProjectId)}`);
  }

  // widgets methods for position changing on expand/collapse
  resetWidgetsPosition(column, widget) {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (!widget.isExpanded) this.onWidgetExpand(column, widget);
    else this.onWidgetCollapsed(column, widget);
  }

  onWidgetExpand(column, widget) {
    const { dashboardColumns } = this.state;
    const findAnotherWidget = dashboardColumns[column.index].widgetsData
      .find(wd => wd.projectId !== widget.projectId);
    const currenWidgetIndex = dashboardColumns[column.index].widgetsData
      .findIndex(wd => wd.projectId === widget.projectId);
    dashboardColumns[column.index].widgetsData[currenWidgetIndex].isExpanded = true;
    if (findAnotherWidget) {
      // create model for new column
      const dashboardColumnModel = {
        index: column.index + 1,
        widgetsData: currenWidgetIndex === 0 ? [findAnotherWidget]
          : [dashboardColumns[column.index].widgetsData[currenWidgetIndex]],
      };
      // increment the column index by 1 after that column
      dashboardColumns.forEach((dashboardColumn) => {
        if (dashboardColumn.index > column.index) {
          dashboardColumn.index += 1;
        }
      });
      // splice second widget
      dashboardColumns[column.index].widgetsData.splice(1, 1);
      // add column at next index
      dashboardColumns.splice(column.index + 1, 0, dashboardColumnModel);
      // adjust widget position
      if (!dashboardColumnModel.widgetsData[0].isExpanded) {
        dashboardColumns.forEach((dashboardColumn, index) => {
          if (index > column.index + 1) {
            if (dashboardColumns[dashboardColumn.index] != null) {
              const nextColumnWidget = dashboardColumns[dashboardColumn.index].widgetsData[0];
              if (!nextColumnWidget.isExpanded
                && !dashboardColumns[dashboardColumn.index - 1].widgetsData[0].isExpanded) {
                dashboardColumns[dashboardColumn.index - 1].widgetsData.push(nextColumnWidget);
                dashboardColumns[dashboardColumn.index].widgetsData.splice(0, 1);
              }
            }
          }
          if (dashboardColumns[index] && dashboardColumns[index].widgetsData.length === 0) {
            dashboardColumns.splice(index, 1);
          }
        });
      }
    }
    this.setState({
      dashboardColumns,
      isExpandWidget: true,
    });
  }

  onExpand() {
    const { dashboardWidgetsData } = this.state;
    this.setState({ isLoaded: false });
    const projectTiles = [];

    dashboardWidgetsData.forEach((pWidget, index) => {
      pWidget.isExpanded = true;
      pWidget.columnIndex = index;
      projectTiles.push(pWidget);
    });
    const expandedWidgetCount = dashboardWidgetsData.filter(w => w.isExpanded).length;
    const unexpandedWidgetCount = dashboardWidgetsData.filter(w => !w.isExpanded).length;
    const totalCount = Math.ceil(expandedWidgetCount + unexpandedWidgetCount);
    const countTotalColumns = dashboardWidgetsData.length > 2
      ? totalCount : portfolioConstant.maxProjectRowsLength;
    const columnsData = [];
    for (let i = 0; i < countTotalColumns; i += 1) {
      const column = {
        index: i,
        widgetsData: projectTiles.filter(w => w.columnIndex === i),
      };
      columnsData.push(column);
    }
    columnsData.forEach((column) => {
      column.widgetsData = orderBy(column.widgetsData, 'title', 'asc');
    });

    this.setState({
      isLoaded: true,
      dashboardColumns: columnsData,
      isExpandWidget: true,
    });
  }

  onCollapse() {
    const { dashboardWidgetsData } = this.state;
    const projectTiles = [];
    this.setState({ isLoaded: false });
    const projectWidgetsLength = Math.ceil(dashboardWidgetsData.length / 2);
    const colWidget = projectWidgetsLength > 1
      ? portfolioConstant.maxProjectRowsLength
      : portfolioConstant.minProjectRowsLength;
    // set parameters for isExpanded or columnIndex
    for (let i = 0; i < colWidget; i += 1) {
      const widgets = [];

      if (dashboardWidgetsData.length > 2) {
        widgets.push(dashboardWidgetsData[projectWidgetsLength * i]);
        for (let j = 1; j < projectWidgetsLength; j += 1) {
          if (dashboardWidgetsData.length > projectWidgetsLength * i + j) {
            widgets.push(dashboardWidgetsData[projectWidgetsLength * i + j]);
          }
        }
      } else {
        widgets.push(dashboardWidgetsData[2 * i]);
        if (dashboardWidgetsData.length > 2 * i + 1) widgets.push(dashboardWidgetsData[2 * i + 1]);
      }

      widgets.forEach((pWidget, index) => {
        pWidget.isExpanded = false;
        pWidget.columnIndex = index;
        projectTiles.push(pWidget);
      });
    }
    this.setWidgetsAndWidth(projectTiles, dashboardWidgetsData.length);
    this.setState({ isExpandWidget: false });
  }


  onWidgetCollapsed(column, widget) {
    const { dashboardColumns } = this.state;
    const expendedWidgets = [];
    widget.isExpanded = false;
    let findAnotherColumnIndex;
    if (dashboardColumns[column.index - 1]
      && !dashboardColumns[column.index - 1].widgetsData[0].isExpanded
      && dashboardColumns[column.index - 1].widgetsData.length === 1
    ) {
      findAnotherColumnIndex = column.index - 1;
    } else {
      findAnotherColumnIndex = column.index + 1;
    }
    for (let i = findAnotherColumnIndex; i < dashboardColumns.length; i += 1) {
      findAnotherColumnIndex = i;
      if (dashboardColumns[findAnotherColumnIndex]
        && dashboardColumns[findAnotherColumnIndex]
          .widgetsData.filter(w => w.isExpanded).length === 0
        && dashboardColumns[findAnotherColumnIndex - 1]
        && dashboardColumns[findAnotherColumnIndex - 1].widgetsData.length === 1
        && !dashboardColumns[findAnotherColumnIndex - 1].widgetsData[0].isExpanded
      ) {
        if (dashboardColumns[findAnotherColumnIndex]
          && !dashboardColumns[findAnotherColumnIndex].widgetsData[0].isExpanded) {
          // splice additional dashboard column
          if (column.index > findAnotherColumnIndex) {
            dashboardColumns[findAnotherColumnIndex].widgetsData.push(widget);
            if (dashboardColumns[column.index].widgetsData.length === 1) {
              dashboardColumns.splice(column.index, 1);
            } else dashboardColumns[column.index].widgetsData.splice(0, 1);
          } else if (dashboardColumns[findAnotherColumnIndex - 1].widgetsData.length < 2) {
            dashboardColumns[findAnotherColumnIndex - 1].widgetsData
              .push(dashboardColumns[findAnotherColumnIndex].widgetsData[0]);
            if (dashboardColumns[findAnotherColumnIndex].widgetsData.length === 1) {
              dashboardColumns.splice(findAnotherColumnIndex, 1);
            } else dashboardColumns[findAnotherColumnIndex].widgetsData.splice(0, 1);
          }
        }
      }
    }
    // reset column index
    dashboardColumns.forEach((colmn, index) => {
      colmn.index = index;
    });

    dashboardColumns.map((item) => {
      dashboardColumns[item.index].widgetsData.map((exItem) => {
        expendedWidgets.push(exItem);
      });
    });
    const expendedWidgetsCount = expendedWidgets.filter(item => item.isExpanded === true);
    // eslint-disable-next-line
    expendedWidgetsCount && expendedWidgetsCount.length > 0
      ? this.setState({ isExpandWidget: true })
      : this.setState({ isExpandWidget: false });


    this.setState({
      dashboardColumns,
    });
  }

  // spark trend line
  handlePointHover = (activePoint, e) => {
    if (e != null && activePoint != null && activePoint.date !== undefined) {
      this.setState({
        activePoint,
        activePosition: {
          x: e.currentTarget.getAttribute('cx'),
          y: e.currentTarget.getAttribute('cy'),
          date: activePoint.date,
          score: activePoint.y,
          projectId: activePoint.projectId,
        },
      });
    }
  }

  setTooltipPosition(elId) {
    const el = document.getElementById(elId);
    const winWidth = window.innerWidth;
    const elLeftDistance = el.getBoundingClientRect().left + 240;
    const elPosition = Math.ceil(winWidth - elLeftDistance);
    // eslint-disable-next-line
    elPosition < 300
      ? this.setState({ tooltipPosition: 'left' })
      : this.setState({ tooltipPosition: 'right' });
  }


  hideTooltip() {
    if (this.state.activePosition) {
      this.setState({
        activePosition: null,
      });
    }
  }

  showOpenTextRightPanel(keyPhrase, widget) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const { roles, isSysAdmin } = this.props;
    if (roles.portfolioAdministrator || roles.portfolioStakeholder || isSysAdmin) {
      const openTextModel = {
        projectId: widget.projectId,
        projectName: widget.projectTitle,
        keyPhrase,
      };
      this.props.showOpenTextRightPanel(openTextModel);
    }
  }

  showOpenTextNonMatchRightPanel(keyPhrases, widget) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const { roles, isSysAdmin } = this.props;
    if (roles.portfolioAdministrator || roles.portfolioStakeholder || isSysAdmin) {
      const openTextModel = {
        projectId: widget.projectId,
        projectName: widget.projectTitle,
        keyPhrases,
      };
      this.props.showOpenTextNonMatchRightPanel(openTextModel);
    }
  }

  getKeyPhrases(keyPhrases) {
    const phrasearray = [];
    keyPhrases.map((i) => {
      phrasearray.push(i.phrase);
    });
    return phrasearray;
  }

  showOpenTextDropQuesRightPanel(projectData) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.props.showOpenTextDropQuesRightPanel(projectData.totalRespondedOTQuestions,
      projectData.projectId);
    this.props.showHideOverlay(true);
  }

  showNoOfOpenTextQuestions(projectData, openTextQuestions) {
    return <p className='ovrall-talk'><i className="fa fa-comments-o mr-2 mb-1"></i>
      Based on {projectData.isDrafted
        ? `${openTextQuestions} open text question${openTextQuestions > 1 ? 's' : ''}`
        : <a onClick={(e) => {
          e.stopPropagation();
          this.showOpenTextDropQuesRightPanel(projectData);
        }}
        >
          {openTextQuestions} open text question{openTextQuestions > 1 ? 's' : ''}
        </a>}
    </p>;
  }

  render() {
    const {
      isPortfolioExist, selectedPortfolio, selectedProject, isSysAdmin, systemTerminology,
    } = this.props;

    const {
      dashboardColumns, isLoaded, activePosition, activePoint, tooltipPosition,
      insufficientDataForAllTopics, isExpandWidget,
    } = this.state;
    const portfolioReplace = levelOneOrganizations.find(
      o => o.value === systemTerminology.level_one,
    );
    const portfolioReplaceText = portfolioReplace && portfolioReplace.text;

    const projectReplace = levelTwoOrganizations.find(
      o => o.value === systemTerminology.level_two,
    );
    const projectReplaceText = projectReplace && projectReplace.text;

    const newProjectTitle = selectedProject && selectedProject.ProjectTitle.replace(/#projectReplaceText#/g, projectReplaceText);
    return (
      <div>
        <Spinner isLoading={!isLoaded} />
        <RenderIf showComponent={isLoaded}>
          <div>
            {isPortfolioExist ? (
              <div>
                <div className="a-page-header a-transition-500ms">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-lg-3">
                      <div className="page-title-section">
                        <p className="a-page-title m-0 text-ellipses">
                          {newProjectTitle}

                        </p>
                        <div className="d-flex align-items-center">
                          <span className="a-page-subtitle port-text-ellipses">
                            {selectedPortfolio.PortfolioTitle}
                          </span>
                          {!selectedPortfolio.protfolioActive && <span className="in-active-badge iab-dark ml-2">inactive</span>}
                        </div>

                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 text-sm-left text-md-right text-lg-right text-xl-right custom-stake-settings">
                      <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                        perform="project:filter-project-dashboard"
                        permit={() => (<>

                          {!isExpandWidget && <button onClick={() => this.onExpand()}
                            className='a-btn  a-btn-lg cstm-align hover-black-btn btn-expand mt-3 mb-2'>
                            <i class="material-icons a-btn-icon md-24 mb-1 mr-1">
                              <ReactSVG wrapper="svg" width="12" height="12" src={expandCardsSvg} />
                            </i><text>Expand cards</text>
                          </button>}
                          {isExpandWidget && <button onClick={() => this.onCollapse()}
                            className='a-btn  a-btn-lg cstm-align hover-black-btn btn-expand mt-3 mb-2'>
                            <i class="material-icons a-btn-icon md-24 mb-1 mr-1"><ReactSVG wrapper="svg" width="12" height="12" src={collapseCardsSvg} /></i><text>Collapse cards</text>
                          </button>}
                        </>)
                        } />
                      <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                        perform="portfolio:add-stakeholder"
                        permit={() => (
                          <button className="a-btn a-btn-lg a-btn-primary ml-2 mt-md-2 mt-sm-2 square-btn"
                            onClick={this.showPortfolioStakeholderRightPanel}>
                            <i className="appkiticon mr-0 a-btn-icon icon-audience-fill" />
                          </button>)} />
                      <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                        perform="portfolio:modify"
                        permit={() => (
                          <button onClick={this.showPortfolioRightPanel}
                            className="a-btn a-btn-lg a-btn-primary ml-2 mt-md-2 mt-sm-2 square-btn">
                            <ReactSVG wrapper='svg' width="13px" height="12px" src={settingIconSvg} />
                          </button>)} />
                    </div>
                  </div>
                </div>
                {/* projects tiles listing */}

                <div className="data-scroller-container" >
                  <div className="data-container clearfix widget-project-grid" style={{ width: `${300 * dashboardColumns.length}px` }}>

                    {
                      !insufficientDataForAllTopics
                      && dashboardColumns.map(column => (
                        <div key={uniqueid()} className={`col-t1 ${column.index}`}>
                          {column.widgetsData.map(widget => (
                            <div key={uniqueid()} className="data-item-box"
                              onClick={() => {
                                this.resetWidgetsPosition(column, widget);
                              }}
                            >
                              {!widget.active && <div class="custom-tag in-active-tag left-0">Inactive</div>}
                              <span className="pop-out" onClick={(e) => { e.stopPropagation(); this.openProject(widget); }}>
                                <i class="material-icons" style={{ fontSize: '14px' }}>launch</i>
                              </span>
                              <div
                                className="a-panel a-shadow-sm text-center">

                                <div className="project-title">
                                  <p className="m-0 capitalize-text">{widget.projectTitle}</p>
                                </div>
                                <div className="project-percentage">
                                  {widget.showScore
                                    && <h3 className="a-dropdown-item" style={widget.scoreColor}>{(widget.overallScore)}%</h3>
                                  }
                                  {!widget.showScore
                                    && <h3 className="a-dropdown-item no-oc-data"><span>{(widget.overallScore)}%</span></h3>
                                  }

                                </div>
                                {widget.showTrendLines && <div className="project-progress ">
                                  <p className={`m-0 ${widget.showPercentageDiff && widget.showScore
                                    ? '' : ' no-trends-data'} `}>

                                    <i
                                      /* eslint-disable no-nested-ternary */
                                      className={`appkiticon 
                                      ${widget.percentageDiff === 0 || widget.percentageDiff === null ? 'grey-color no-min-width' : (widget.percentageDiff < 0 ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill')} `}
                                    /* eslint-disable no-use-before-define */
                                    >
                                      <span className='preview-pane-font'><span>&nbsp;</span>
                                        {widget.showPercentageDiff
                                          && widget.showScore ? widget.percentageDiff === 0 ? 'No change' : `${Math.abs(widget.percentageDiff)}%` : ''} {' '}
                                      </span>
                                    </i> in past {widget.periodLength / 2} {widget.periodLength > 2 ? 'weekdays' : 'weekday'}
                                  </p>
                                </div>}

                                {widget.showTrendLines && <div className={`graph-line 
                                    ${!(widget.trendData
                                    && widget.trendData.length >= 2) ? ' blur-graph' : ''}`}
                                  onMouseLeave={this.hideTooltip}>
                                  {activePosition
                                    && activePosition.projectId === widget.projectId
                                    && <span className="graph-tooltip"
                                      style={{
                                        left: `${activePosition.x - 20}px`,
                                        top: `${parseFloat(activePosition.y) + 20}px`,
                                      }}
                                    >{Math.round(activePosition.score)}% <br />{activePosition.date}
                                    </span>
                                  }

                                  <ReactSvgLineChart
                                    data={(widget.trendData
                                      && widget.trendData.length >= 2)
                                      ? widget.trendData
                                        .map(point => ({
                                          ...point,
                                          active: point.x === activePoint.x,
                                        }))
                                      : trendBlurData.map(point => ({
                                        ...point,
                                      }))
                                    }
                                    pointsOnHover={this.handlePointHover}
                                    axisWidth={0}
                                    gridWidth={0}
                                    labelsVisible={false}
                                    pathColor={!(widget.trendData
                                      && widget.trendData.length >= 2) ? 'grey' : '#fa5928'}
                                    pathVisible={true}
                                    pointsColor={!(widget.trendData
                                      && widget.trendData.length >= 2) ? 'grey' : '#fa5928'}
                                    pointsRadius={3}
                                    labelsCountY={20}
                                    labelsStepX={1}
                                    pointsStrokeColor={!(widget.trendData
                                      && widget.trendData.length >= 2) ? 'grey' : '#fa5928'}
                                    pointsStrokeWidth={2}
                                    pointsVisible={true}
                                    viewBoxHeight={50}
                                    viewBoxWidth={250}
                                  />
                                </div>}

                                <div className="a-accordion-wrapper portfolio-accordion-wrapper">
                                  <div className="a-accordion">
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        // if (widget.isExpanded) {
                                        this.resetWidgetsPosition(column, widget);
                                      }}
                                      className="a-accordion-title bg-transparent panel-expander" >
                                      <i className={`appkiticon
                                    ${widget.isExpanded ? ' icon-up-chevron-circle-outline'
                                          : ' icon-down-chevron-circle-outline'}
                                    `}
                                      ></i></div>
                                    {widget.isExpanded
                                      && <div className="a-accordion-content bg-transparent d-block "
                                      // "custom-animation"
                                      >
                                        <div className="tag-container">


                                          {widget.domains.map(domain => (
                                            <div key={uniqueid()}
                                              id={widget.projectTitle}
                                              className={`a-tag a-tag-d2e
                                          ${domain.color}  `}
                                              data-for={domain.title || domain.domainName} data-tip
                                              onMouseEnter={
                                                () => this.setTooltipPosition(widget.projectTitle)
                                              }
                                              onClick={(e) => {
                                                e.stopPropagation(); this.openProject(widget);
                                              }}
                                            >
                                              {!domain.isCustomDomain && <span className={`appkiticon-cust ${domain.domainIcon} left-icon`}></span>}
                                              {domain.isCustomDomain && (
                                                <div className="custom-domain-icon">
                                                  <ReactSVG src={settingEditIconSvg} />
                                                </div>
                                              )}
                                              <span className="a-mr-10 tag-title capitalize-text">{domain.title || domain.domainName}</span>
                                              {domain.showScore && <span
                                                className={'appkiticon-cust right-icon'}
                                              >
                                                {`${domain.overallScore === null ? '' : `${domain.overallScore}%`}`}
                                              </span>}
                                              {domain.description && <ReactTooltip id={domain.title} className='tooltip' place={tooltipPosition}
                                                effect='solid'>
                                                {domain.description}
                                              </ReactTooltip>}
                                            </div>
                                          ))}

                                        </div>


                                        <div className="people-comments-section">
                                          {(widget.noOfOpenTextQuestionsAssociated > 0 && widget.isExpanded) && <div className="project-section-title font-weight-normal">
                                            {
                                              this.showNoOfOpenTextQuestions(
                                                widget,
                                                widget.noOfOpenTextQuestionsAssociated,
                                              )
                                            }
                                            {(widget.totalKeyPhraseCount > 0
                                              || widget.totalEmptyKeyPhraseCount > 0) ? null
                                              : <p className="text-center no-response">
                                                No responses yet
                                              </p>}
                                          </div>}
                                          {widget.showScore && widget.talkAbout
                                            && widget.talkAbout.length > 0
                                            && widget.talkAbout.map((keyPhrase, index) => (
                                              <div key={index} className={'a-badge badge-general'}
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  this.props.showHideOverlay(true);
                                                  this.showOpenTextRightPanel(
                                                    keyPhrase.phrase, widget,
                                                  );
                                                }}
                                              >{keyPhrase.phrase}
                                              </div>
                                            ))
                                          }
                                          {widget.showScore && (widget.totalKeyPhraseCount > 0
                                            || widget.totalEmptyKeyPhraseCount > 0)
                                            && <div key={widget.projectId} className={'a-badge badge-general'}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.props.showHideOverlay(true);
                                                this.showOpenTextNonMatchRightPanel(
                                                  this.getKeyPhrases(widget.talkAbout), widget,
                                                );
                                              }}
                                            >...
                                            </div>
                                          }
                                          {(widget.noOfOpenTextQuestionsAssociated === undefined
                                            || widget.noOfOpenTextQuestionsAssociated === 0) && <div className="no-resp-data">
                                              There are no open text questions assigned to
                                              this topic
                                            </div>
                                          }
                                        </div>
                                        {widget.surveyEngagement && (
                                          <div className="survey-section">
                                            <p className="project-section-title">
                                              <i class="material-icons" style={{ fontSize: '14px' }}>person_outline</i> Stakeholder Response Rate</p>
                                            <div className="a-customized-ratings a-customized-primary a-customized-white-background a-customized-8">
                                              <div className="a-ratings-detail">
                                                <div className="a-ratings-total">
                                                  <div className="a-ratings-percent"
                                                    style={{ width: `${widget.surveyEngagement.project}%` }}>
                                                  </div>
                                                  {widget.surveyEngagement.showRedLine
                                                    && <div className="average-marker" style={{ left: `${widget.surveyEngagement.allProjectsAvg}%` }}
                                                    ></div>
                                                  }
                                                </div>
                                              </div>

                                            </div>
                                            <div className="a-ratings-value">{widget.surveyEngagement.project}%
                                              {widget.surveyEngagement.project
                                                !== widget.surveyEngagement.allProjectsAvg
                                                && widget.surveyEngagement.showRedLine && (
                                                  <span className='widget-survey-engagement'>
                                                    {widget.surveyEngagement.project
                                                      > widget.surveyEngagement.allProjectsAvg
                                                      ? ' (Above Average)' : ' (Below Average)'}
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    }

                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          }
                        </div>
                      ))
                    }
                  </div>
                </div >
              </div >

            ) : (
              <div className="no-content-found ">
                You don't have access to any {portfolioReplace === undefined ? 'portfolios' : portfolioReplace.text.toLowerCase()} or {projectReplace === undefined ? 'projects' : projectReplace.text.toLowerCase()} yet.
              </div>
            )
            }
          </div >
        </RenderIf >
        <Feedback pageRef={`/${portfolioReplaceText && portfolioReplaceText.toLowerCase()}-dashboard`} selectedPortfolioTitle={selectedPortfolio.PortfolioTitle} isShowSupportBtn={true} />
      </div >
    );
  }
}

const mapStateToProps = state => ({
  isPortfolioExist: isAnyPortfolioExist(state),
  selectedPortfolio: getSelectedPortfolio(state),
  selectedProject: getSelectedProject(state),
  isSysAdmin: state.login.isSysAdmin,
  roles: state.portfolio.portfolioData.roles,
  portfolioData: getPortfolio(state),
  projectDetails: state.project.projectDetails,
  systemTerminology: getSystemTerminologySelector(state),
  userFiltersForPortfolio: state.portfolio.portfolioData.userFiltersForPortfolio,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
});

const mapDispatchToProps = {
  push,
  showPortfolioRightPanel,
  showPortfolioStakeholderRightPanel,
  changeSelectedPortfolioAndProject,
  getPortfolioDashbordProjects,
  updateProjectSaveState,
  showOpenTextRightPanel,
  showOpenTextNonMatchRightPanel,
  getSystemTerminology,
  getUserFiltersForPortfolio,
  showHideOverlay,
  showOpenTextDropQuesRightPanel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Portfolio);
