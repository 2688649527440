import React, { Component } from 'react';
import { push } from 'connected-react-router';
import '../styles/projects.scss';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import Cryptr from 'cryptr';
import { uniqBy } from 'lodash';
import DatePicker from 'react-datepicker';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import MaterialIcon from 'material-icons-react';
import ReactTooltip from 'react-tooltip';
import Authorise from '../../../Authorise';
import RenderIf from '../../../ui-components/Common';
import { getProject, getPortfolios, getStages } from '../selectors/project';
import { SpinnerButton, Spinner } from '../../../ui-components/Loader';
import { getPortfolio, getSelectedPortfolio } from '../../portfolio/selectors/portfolio';
import SumoSelect from '../../../ui-components/SumoSelect/SumoSelect';
import DeleteConfirmation from '../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import PreviewWelcomeMessage from '../../../ui-components/PreviewWelcomeMessage/PreviewWelcomeMessage.jsx';
import UnsavedChangesConfirmation from '../../../ui-components/UnsavedChangesConfirmation/UnsavedChangesConfirmation.jsx';
import constant from '../actions/constant';
import {
  getPortfoliosData,
  hideProjectRightPanel,
  showPortfolioRightPanel,
  changeSelectedPortfolioAndProject,
  getPortfolioQuestionModelList,
  setSelectedQuestionModelId,
  updatePortfolioData,
} from '../../portfolio/actions/portfolio';
import {
  validateUserByEmail,
  createNewUser,
} from '../../account/actions/iam';
import {
  saveProjectAction,
  checkProjectTitleAction,
  getProjectDetailsAction,
  updateProjectAction,
  deleteProject,
  checkUncheckDomain,
  showLoadingSpinner,
  hideLoadingSpinner,
  updateFormState,
  getSelectedPortfolioAdmins,
  updateProjectAdmins,
  emptyPreSelectedNewPortfolioId,
  updateProjectDetails,
  checkIfDropShouldRunImmediately,
  showRecordProcessing,
  hideRecordProcessing,
  updateProjectViewers,
  projectActiveState,
  projectAdhocDropDate,
  projectUpdatedData,
} from '../actions/project';
import {
  disableUnsavedChangesModal, disableUnsavedChanges,
  enableUnsavedChanges, enableUnsavedChangesModal,
} from '../../general/actions/general';
import { RegisterEmail } from '../../../ui-components/Account';
import authService from '../../../redux/services/authService';
import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { levelOneOrganizations, levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import chunkedPromises from '../../../utils/utils';
import 'react-datepicker/dist/react-datepicker.css';
import { showHideOverlay, updateUnsavedChanges } from '../../common/overlay/actions/overlay';

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function isProjectExist(values, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'Project with the same name already exists.';
    checkProjectTitleAction(values.name.trim().replace(/  +/g, ' '),
      values.id, values.selectedPortfolio).then((val) => {
        if (val) {
          setError(actions, 'name', errorMessage);
          return resolve(true);
        }
        return resolve(false);
      });
  });
}

function checkCompletionMessageLength(e, setFieldTouched, setFieldError, setFieldValue) {
  setFieldValue('completionMessage', e.target.value);
  setFieldValue('completionMessageCharacter', e.target.value.length);
  const errorMessage = 'You have exceeded the maximum character limit';
  if (e.target.value !== '') {
    if (e.target.value.length > 700) {
      setFieldTouched('completionMessage', true);
      window.setTimeout(() => {
        setFieldError('completionMessage', errorMessage);
      }, 100);
    }
  }
}

const cryptr = new Cryptr(__CONFIG__.encryptSecret);
class AddProject extends Component {
  timeZones = [];

  defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      loggedInUserId: 0,
      isLoading: true,
      projectAdmins: [],
      adminsToBeRemoved: [],
      projectOpenTextQuestions: [],
      showDeleteModal: false,
      showUnsavedChangesModal: false,
      isSubmitting: false,
      isSubmitted: false,
      isTopicSubmitted: false,
      preSelectNewPortfolio: true,
      showRegisterProjectAdministratorModel: false,
      RegisteringUserEmail: '',
      isPortfolioChanged: true,
      isQuestionSetChanged: true,
      showAdministratorDelete: false,
      selectedAdministratorEmailIdForDelete: '',
      deleteAdministratorTitle: 'Remove #projectReplaceText# Administrator',
      deleteAdministratorConfirmationMessage: `Are you sure you want to remove this user 
        from #projectReplaceText# Administrators?`,
      deleteViewerTitle: 'Remove #projectReplaceText# Viewer',
      deleteViewerConfirmationMessage: `Are you sure you want to remove this user 
        from #projectReplaceText# Viewers?`,
      immediateDropTriggerConfirmationMessage: null,
      immediateDropTriggerConfirmationTitle: null,
      showImmediateDropModal: false,
      showRegisterProjectViewerModel: false,
      viewers: [],
      projectViewers: [],
      viewersToBeRemoved: [],
      showViewerDelete: false,
      selectedViewerEmailIdForDelete: '',
      nextDropDate: '',
      isDropRunningImmediate: false,
      disableAnalysisPeriodIncrement: false,
      showPreviewWelcomeMessageModel: false,
      projectTitle: '',
      welcomeMessage: '',
      totalEmails: 0,
      adHocDate: '',
      isRestrictedUser: false,
    };
    this.validateAndSaveProject = this.validateAndSaveProject.bind(this);
    this.hideProjectRightPanel = this.hideProjectRightPanel.bind(this);
    this.showPortfolioRightPanel = this.showPortfolioRightPanel.bind(this);
    this.confirmAdministratorDelete = this.confirmAdministratorDelete.bind(this);
    this.cancelAdministratorDelete = this.cancelAdministratorDelete.bind(this);
    this.removeProjectAdministrator = this.removeProjectAdministrator.bind(this);
    this.changePortfolio = this.changePortfolio.bind(this);
    this.getApplicableRole = this.getApplicableRole.bind(this);
    this.emptyPreSelectedNewPortfolioId = this.emptyPreSelectedNewPortfolioId.bind(this);
    this.toggleUnsavedChanges = this.toggleUnsavedChanges.bind(this);
    this.removeProjectViewers = this.removeProjectViewers.bind(this);
    this.confirmViewerDelete = this.confirmViewerDelete.bind(this);
    this.cancelViewerDelete = this.cancelViewerDelete.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.resetDisableAnalysisPeriodIncrement = this.resetDisableAnalysisPeriodIncrement.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    const timezones = moment.tz.names();
    this.timeZones = timezones.map((zone) => {
      const offSet = moment.tz(zone).format('Z');
      return {
        text: `(GMT ${offSet}) ${zone}`,
        value: zone,
      };
    });
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    const { projectRightPanel, selectedProjectId, selectedPortfolio } = this.props.portfolioData;
    const { isRestrictedUser, PortfolioId } = this.props.selectedPortfolio;
    this.props.showLoadingSpinner();
    this.props.disableUnsavedChanges();
    this.props.disableUnsavedChangesModal();
    const id = projectRightPanel.isInEditMode ? selectedProjectId : 0;
    this.props.getProjectDetailsAction(id, projectRightPanel.isInEditMode).then((res) => {
      this.props.hideLoadingSpinner();
      this.setState({
        projectAdmins: res.projectAdmins,
        projectViewers: res.projectViewers,
        isLoading: false,
      });
      if (projectRightPanel.isInEditMode) {
        const projectData = {
          projectId: selectedProjectId,
          id: selectedProjectId,
          selectedDropTimezone: this.props.projectDetails.selectedDropTimezone,
          selectedDropHour: this.props.projectDetails.selectedDropHour,
          analysisPeriod: this.props.projectDetails.analysisPeriod,
          isActive: this.props.projectDetails.isActive,
          portfolioId: this.props.portfolioData.selectedPortfolio,
          isDropRecurring: this.props.projectDetails.isDropRecurring,
        };
        if (selectedPortfolio === PortfolioId) {
          this.setState({ isRestrictedUser });
        }
        this.props.checkIfDropShouldRunImmediately(projectData).then((dropRes) => {
          if (dropRes) {
            this.setState({
              isDropRunningImmediate: true,
            });
          }
        });
      }
      this.props.setSelectedQuestionModelId(this.props.projectDetails.selectedQuestionSet);
    });


    const userInfo = authService.getUserInfo();
    this.setState({ loggedInUserId: userInfo.user_id });
    document.addEventListener('wheel', this.handleMouseWheel);
    document.addEventListener('keypress', this.resetDisableAnalysisPeriodIncrement);
    document.addEventListener('keyup', this.resetDisableAnalysisPeriodIncrement);
    document.addEventListener('keydown', this.resetDisableAnalysisPeriodIncrement);
  }

  componentWillUnmount() {
    document.removeEventListener('wheel', this.handleMouseWheel);
    document.removeEventListener('keypress', this.resetDisableAnalysisPeriodIncrement);
    document.removeEventListener('keyup', this.resetDisableAnalysisPeriodIncrement);
    document.removeEventListener('keydown', this.resetDisableAnalysisPeriodIncrement);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleMouseWheel(e) {
    if (e.target.name === 'analysisPeriod') {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ disableAnalysisPeriodIncrement: true });
    }
  }

  resetDisableAnalysisPeriodIncrement() {
    this.setState({ disableAnalysisPeriodIncrement: false });
  }

  toggleUnsavedChanges(values) {
    if (!this.props.unsavedChanges) {
      this.props.enableUnsavedChanges();
    }
    if (values && values.analysisPeriod) {
      const { projectRightPanel } = this.props.portfolioData;
      let nextDropFullDate = '';
      let currentDate = '';
      if (projectRightPanel.isInEditMode) {
        currentDate = moment(this.props.projectDetails.lastDropDate).tz(
          values.selectedDropTimezone,
        );
      } else {
        currentDate = moment().tz(values.selectedDropTimezone);
      }

      const dropDate = this.getFutureDropDates(currentDate, values.analysisPeriod);
      nextDropFullDate = moment(dropDate).tz(values.selectedDropTimezone);
      const nextDropDate = moment(nextDropFullDate).tz(values.selectedDropTimezone).format('dddd, D MMMM YYYY');
      if (projectRightPanel.isInEditMode) {
        if (values.isDropRecurring) {
          this.props.checkIfDropShouldRunImmediately(values).then((res) => {
            if (res) {
              this.setState({
                isDropRunningImmediate: true,
              });
            } else {
              this.setState({
                nextDropDate,
                isDropRunningImmediate: false,
              });
            }
          });
        } else {
          this.isAdhocDropExecutable(values);
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.unsavedChanges) {
      this.setState({ isSubmitted: true });
    }
    if (nextProps.unsavedChanges && nextProps.unsavedChangesModal && !this.state.isSubmitting) {
      this.setState({ showUnsavedChangesModal: true });
    }
  }

  retainUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.setState({ showUnsavedChangesModal: false });
  }

  cancelUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.setState({ showUnsavedChangesModal: false });
    this.props.hideProjectRightPanel();
  }

  showSpinner(value) {
    this.setState({ isSubmitting: value, showUnsavedChangesModal: false });
  }

  projectAdminsCount(adminsToBeRemoved, viewersToBeRemoved, admins, viewers) {
    if (admins.length > 0 || viewers.length > 0) {
      this.setState({
        totalEmails: (admins.length + viewers.length)
          - (adminsToBeRemoved.length + viewersToBeRemoved.length),
      });
    } else {
      this.setState({ totalEmails: 0 });
    }
  }

  submitProjectDetails() {
    const {
      values, actions, adminsToBeRemoved, viewersToBeRemoved, admins, viewers,
    } = this.state;

    this.setState({ isSubmitted: true, isTopicSubmitted: true });
    this.projectAdminsCount(adminsToBeRemoved, viewersToBeRemoved, admins, viewers);
    const self = this;
    values.projectId = values.id;
    const { projectRightPanel } = self.props.portfolioData;
    self.showSpinner(true);
    const uniqProjectAdmins = uniqBy(values.projectAdmins, 'email_address');
    const uniqProjectViewers = uniqBy(viewers, 'email_address');
    const uniqProjectAdminOrViewer = uniqProjectAdmins.concat(uniqProjectViewers);
    let projectId = 0;
    let portfolioId = 0;
    isProjectExist(values, actions).then(async (isExist) => {
      if (isExist) {
        self.showSpinner(false);
        return;
      }
      if (!projectRightPanel.isInEditMode) {
        this.props.showRecordProcessing(0, 0);
        await chunkedPromises(uniqProjectAdminOrViewer, 10,
          async (c) => {
            const filterProjectAdmins = c.filter(item => item.isAdmin === true);
            const filterProjectViewers = c.filter(item => item.isAdmin === false);
            values.projectAdmins = filterProjectAdmins.length > 0 ? filterProjectAdmins : [];
            values.projectViewers = filterProjectViewers.length > 0 ? filterProjectViewers : [];
            actions.setFieldValue('selectedQuestionSet', values.selectedQuestionSet);
            await this.props.saveProjectAction(values).then((res) => {
              // eslint-disable-next-line
              portfolioId = res.portfolioId;
              // eslint-disable-next-line
              projectId = res.projectId;
              values.id = projectId;
              this.props.showRecordProcessing(c.length, res.failedRecords);
              this.props.projectActiveState(values.isActive);
              const currentDate = moment().tz(values.selectedDropTimezone);
              const dropDate = this.getFutureDropDates(currentDate, values.analysisPeriod);
              let nextDropFullDate = moment(dropDate).tz(values.selectedDropTimezone);
              const nextDropDate = nextDropFullDate.date();
              const nextDropMonth = nextDropFullDate.format('MMMM');
              const nextShortMonthYear = nextDropFullDate.format('MMM YYYY');
              const nextFullDropDate = `${nextDropDate} ${nextShortMonthYear}`;
              nextDropFullDate = `${nextDropDate} ${nextDropMonth}`;
              const updatedData = {
                selectedStage: this.props.lifeCycleStageList
                  .find(x => x.value === parseInt(values.lifecycleStage, 10)).text,
                nextDropDate: nextDropFullDate,
                nextFullDropDate,
              };
              const adhocData = {
                nextFullAdhocDropDate: '',
                AdhocDropOffset: '',
                projectDropTimezone: values.selectedDropTimezone,
                isNextAdhocDropScheduled: false,
                isProjectAdhoc: values.isDropRecurring,
              };
              this.props.projectAdhocDropDate(adhocData);
              this.props.projectUpdatedData(updatedData);
            });
            return c;
          }).then(() => {
            self.props.disableUnsavedChanges();
            self.props.disableUnsavedChangesModal();
            self.showSpinner(false);
            self.getPortfolioDataAndHideRightPanel();
            self.props.updateProjectDetails(values);
            (async () => {
              await self.props.getPortfoliosData();
              self.props.hideRecordProcessing();
              this.setState({ isTopicSubmitted: false });
              this.props.changeSelectedPortfolioAndProject(portfolioId, projectId);
              this.props.push(`/project-dashboard/${cryptr.encrypt(parseInt(portfolioId, 10))}/${cryptr.encrypt(parseInt(projectId, 10))}`);
            })();
          });
      } else {
        this.props.showRecordProcessing(0, 0);
        const newProjectAdmins = uniqBy(admins, 'email_address');
        const newProjectViewers = uniqBy(viewers, 'email_address');
        const newProjectAdminOrViewers = newProjectAdmins.concat(newProjectViewers);
        const newUserArray = newProjectAdminOrViewers.length > 0
          ? newProjectAdminOrViewers : uniqProjectAdminOrViewer;
        await chunkedPromises(newUserArray, 10,
          async (c) => {
            values.adminsToBeAdded = c.filter(item => item.isAdmin === true);
            values.adminsToBeRemoved = adminsToBeRemoved;
            values.viewersToBeAdded = c.filter(item => item.isAdmin === false);
            values.viewersToBeRemoved = viewersToBeRemoved;
            await this.props.updateProjectAction(values).then((res) => {
              const newleAddedUsersCount = newProjectAdminOrViewers.length > 0 ? c.length : 0;
              this.props.showRecordProcessing(newleAddedUsersCount, res.failedRecords);
              const currentDate = moment(this.props.projectDetails.lastDropDate)
                .tz(values.selectedDropTimezone);
              const dropDate = this.getFutureDropDates(currentDate, values.analysisPeriod);
              let nextDropFullDate = moment(dropDate).tz(values.selectedDropTimezone);
              const nextDropDate = nextDropFullDate.date();
              const nextDropMonth = nextDropFullDate.format('MMMM');
              const nextShortMonthYear = nextDropFullDate.format('MMM YYYY');
              const nextFullDropDate = `${nextDropDate} ${nextShortMonthYear}`;
              nextDropFullDate = `${nextDropDate} ${nextDropMonth}`;
              const updatedData = {
                selectedStage: this.props.lifeCycleStageList
                  .find(x => x.value === parseInt(values.lifecycleStage, 10)).text,
                nextDropDate: nextDropFullDate,
                nextFullDropDate,
              };
              this.props.projectUpdatedData(updatedData);
              this.props.projectActiveState(values.isActive);
              if (res.isNextAdhocDropScheduled && !values.isDropRecurring) {
                let nextFullAdhocDropDate = moment(values.adHocDropDate).tz(
                  values.selectedDropTimezone,
                );
                const nextAdhocDate = nextFullAdhocDropDate.date();
                const nextAdhocMonth = nextFullAdhocDropDate.format('MMM YYYY');
                const timeZoneOffset = moment().tz(values.selectedDropTimezone).format('Z');
                nextFullAdhocDropDate = `${nextAdhocDate} ${nextAdhocMonth}`;
                const AdhocDropOffset = `${constant.timeConstant[values.selectedDropHour].text} (GMT ${timeZoneOffset})`;
                this.props.projectAdhocDropDate({
                  nextFullAdhocDropDate,
                  AdhocDropOffset,
                  dropTimeZone: values.selectedDropTimezone,
                  isNextAdhocDropScheduled: res.isNextAdhocDropScheduled,
                  isProjectAdhoc: values.isDropRecurring,
                });
              } else {
                const adhocData = {
                  nextFullAdhocDropDate: '',
                  AdhocDropOffset: '',
                  projectDropTimezone: values.selectedDropTimezone,
                  isNextAdhocDropScheduled: false,
                  isProjectAdhoc: values.isDropRecurring,
                };
                this.props.projectAdhocDropDate(adhocData);
              }
            });
            return c;
          }).then(() => {
            self.props.disableUnsavedChanges();
            self.props.disableUnsavedChangesModal();
            self.showSpinner(false);
            self.getPortfolioDataAndHideRightPanel();
            self.props.getPortfoliosData();
            self.props.updateProjectDetails(values);
            self.props.hideRecordProcessing();
            this.setState({ isTopicSubmitted: false });
          });
      }
    });
  }

  retainProjectDetails() {
    this.setState({
      immediateDropTriggerConfirmationMessage: null,
      immediateDropTriggerConfirmationTitle: null,
      showImmediateDropModal: false,
      values: null,
      actions: null,
    });
  }

  validateAndSaveProject(values, actions) {
    const { lastDropDate, isDrafted, isDropRecurring } = this.props.projectDetails;
    const { roles, isSysAdmin } = this.props;
    const { isActive } = values;
    values.projectId = values.id;
    this.setState({
      values,
      actions,
    });
    let isValidForRole = false;
    if (roles || isSysAdmin) {
      isValidForRole = !!(roles.projectAdministrator
        || roles.portfolioAdministrator || isSysAdmin);
    }
    if (lastDropDate && isActive) {
      if (isValidForRole) {
        if (values.isDropRecurring) {
          this.props.checkIfDropShouldRunImmediately(values).then((res) => {
            if (!isDrafted && isDropRecurring) {
              if (res) {
                this.setState({
                  immediateDropTriggerConfirmationMessage: `These changes to the question frequency will result in an immediate question drop
              for all assigned stakeholders. Are you sure you want to continue?`,
                  immediateDropTriggerConfirmationTitle: 'Send Questions Now',
                  showImmediateDropModal: true,
                });
              } else {
                this.props.showHideOverlay(false);
                this.submitProjectDetails();
              }
            } else {
              this.props.showHideOverlay(false);
              this.submitProjectDetails();
            }
          });
        } else {
          const isExecutable = this.isAdhocDropExecutable(values);
          if (isExecutable) {
            this.setState({
              immediateDropTriggerConfirmationMessage: `These changes to the question frequency will result in an immediate question drop
          for all assigned stakeholders. Are you sure you want to continue?`,
              immediateDropTriggerConfirmationTitle: 'Send Questions Now',
              showImmediateDropModal: true,
            });
          } else {
            this.props.showHideOverlay(false);
            this.submitProjectDetails();
          }
        }
      } else {
        this.props.showHideOverlay(false);
        this.submitProjectDetails();
      }
    } else {
      this.props.showHideOverlay(false);
      this.submitProjectDetails();
    }
  }

  async changePortfolio(selectedPortfolio, setFieldValue) {
    const userInfo = authService.getUserInfo();
    const self = this;
    self.setState({
      isPortfolioChanged: false,
    });

    if (!this.state.isSubmitted) {
      this.toggleUnsavedChanges();
    }
    setFieldValue('selectedQuestionSet', '');
    setFieldValue('lifecycleStage', '');
    if (!this.state.isTopicSubmitted) {
      await this.props.getPortfolioQuestionModelList(selectedPortfolio).then(
        async (questionModels) => {
          self.setState({
            isPortfolioChanged: true,
          });
          if (questionModels.data.length === 1) {
            self.props.setSelectedQuestionModelId(questionModels.data[0].id);
            const { projectRightPanel, selectedProjectId } = this.props.portfolioData;
            const id = projectRightPanel.isInEditMode ? selectedProjectId : 0;
            const selectedQuestionSetId = questionModels.data[0].id;
            await this.props.getProjectDetailsAction(
              id,
              projectRightPanel.isInEditMode,
              selectedQuestionSetId,
            ).then((res) => {
              setFieldValue('selectedQuestionSet', selectedQuestionSetId);
              this.props.hideLoadingSpinner();
              this.setState({
                projectAdmins: res.projectAdmins,
                isLoading: false,
                isQuestionSetChanged: true,
                projectViewers: res.projectViewers,
              });
            });
          }
          await this.props.getSelectedPortfolioAdmins(selectedPortfolio).then((response) => {
            let selectedAdmins = [];
            const newObj = {
              canDelete: false,
              email_address: userInfo.email,
              first_name: userInfo.firstName,
              id: userInfo.user_id,
              isAdmin: true,
              is_user_registered: true,
              last_name: userInfo.lastName,
            };
            selectedAdmins = response.data.portfolioAdmins;
            const isDuplicateAdmin = selectedAdmins.some(
              admin => admin.email_address === newObj.email_address,
            );
            if (!isDuplicateAdmin) {
              selectedAdmins.push(newObj);
            }
            self.setState({
              projectAdmins: selectedAdmins,
              isRestrictedUser: response.data.portfolioData.isRestrictedUser,
            });
            setFieldValue('selectedPortfolio', selectedPortfolio);
            setFieldValue('projectAdmins', response.data.portfolioAdmins);
          });
        },
      );
    }
  }

  changeQuestionModel(selectedQuestionModel, setFieldValue) {
    const self = this;
    self.setState({ isQuestionSetChanged: false });

    if (!this.state.isSubmitted) {
      this.toggleUnsavedChanges();
    }
    setFieldValue('lifecycleStage', '');
    self.props.setSelectedQuestionModelId(selectedQuestionModel);
    const { projectRightPanel, selectedProjectId } = this.props.portfolioData;
    const id = projectRightPanel.isInEditMode ? selectedProjectId : 0;
    this.props.getProjectDetailsAction(
      id,
      projectRightPanel.isInEditMode,
      selectedQuestionModel,
    ).then(() => {
      this.props.hideLoadingSpinner();
      this.setState({
        isLoading: false,
        isQuestionSetChanged: true,
      });
    });
  }


  deleteModal() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.props.disableUnsavedChanges();
    this.props.disableUnsavedChangesModal();
    this.setState({ showDeleteModal: true });
  }

  cancelDelete() {
    this.setState({ showDeleteModal: false });
  }

  deleteProject = async () => {
    this.setState({ isSubmitted: true });
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.props.showHideOverlay(false);
    const { portfolioData } = this.props;
    const { selectedProjectId, selectedPortfolio } = portfolioData;
    return new Promise((resolve) => {
      this.props.deleteProject(selectedProjectId).then((resp) => {
        if (resp) {
          // eslint-disable-next-line max-len
          this.props.updatePortfolioData({ portfolioId: selectedPortfolio, projectId: selectedProjectId });
          resolve(resp);
          this.getPortfolioDataAndHideRightPanel();
          this.props.changeSelectedPortfolioAndProject(portfolioData.selectedPortfolio, null);
          this.props.push('/');
        }
      });
    });
  }

  getPortfolioDataAndHideRightPanel() {
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.hideProjectRightPanel();
    }
  }

  hideProjectRightPanel() {
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.hideProjectRightPanel();
    }
    this.props.showHideOverlay(false);
  }

  showPortfolioRightPanel() {
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.hideProjectRightPanel();
      this.props.showPortfolioRightPanel();
    }
  }

  checkUncheckDomain(selectedDomain) {
    this.toggleUnsavedChanges();
    this.props.checkUncheckDomain(selectedDomain);
  }

  changeStakeHoldersCanView(values, setFieldValue) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.toggleUnsavedChanges();
    const formData = { ...values };
    formData.projectOpenTextQuestions = this.props.projectDetails.projectOpenTextQuestions;
    formData.stakeHoldersCanView = !values.stakeHoldersCanView;
    setFieldValue('stakeHoldersCanView', formData.stakeHoldersCanView);
  }

  changeStakeHoldersCanViewMobileDashboard(values, setFieldValue) {
    this.toggleUnsavedChanges();
    const formData = { ...values };
    formData.projectOpenTextQuestions = this.props.projectDetails.projectOpenTextQuestions;
    formData.stakeHoldersCanViewMobile = !values.stakeHoldersCanViewMobile;
    this.props.updateFormState(formData);
    setFieldValue('stakeHoldersCanViewMobile', formData.stakeHoldersCanViewMobile);
  }

  changeProjectIsActive(values, setFieldValue) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.toggleUnsavedChanges();
    const formData = { ...values };
    formData.projectOpenTextQuestions = this.props.projectDetails.projectOpenTextQuestions;
    formData.isActive = !values.isActive;
    this.props.updateFormState(formData);
    setFieldValue('isActive', formData.isActive);
  }

  openRegisterProjectAdministratorModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showRegisterProjectAdministratorModel: true });
  }

  openRegisterProjectViewerModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showRegisterProjectViewerModel: true });
  }

  async registerProjectAdministrator(emails) {
    this.toggleUnsavedChanges();
    const formattedEmails = emails.map(email => email.toLowerCase());
    const r = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewAdminToList(item);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          return result;
        });
      });
    const response = [].concat(...r);
    return response;
  }


  async registerProjectViewers(emails) {
    this.toggleUnsavedChanges();
    const formattedEmails = emails.map(email => email.toLowerCase());
    const r = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewViewerToList(item);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          return result;
        });
      });
    const response = [].concat(...r);
    return response;
  }

  pushNewAdminToList(userData) {
    const {
      id, FirstName, LastName, emailId, isRegistered,
    } = userData;
    const { admins, adminsToBeRemoved, projectAdmins } = this.state;
    const user = {
      id,
      first_name: FirstName,
      last_name: LastName,
      email_address: emailId,
      is_user_registered: isRegistered,
      canDelete: true,
      isAdmin: true,
    };
    admins.push(user);
    projectAdmins.push(user);
    const filteredAdmins = adminsToBeRemoved.filter(i => i.id !== user.id);
    this.setState({ admins, adminsToBeRemoved: filteredAdmins, projectAdmins });
    this.props.updateProjectAdmins(projectAdmins);
  }

  pushNewViewerToList(userData) {
    const {
      id, FirstName, LastName, emailId, isRegistered,
    } = userData;
    const { viewers, viewersToBeRemoved, projectViewers } = this.state;
    const user = {
      id,
      first_name: FirstName,
      last_name: LastName,
      email_address: emailId,
      is_user_registered: isRegistered,
      canDelete: true,
      isAdmin: false,
    };
    viewers.push(user);
    projectViewers.push(user);
    const filteredViewers = viewersToBeRemoved.filter(i => i.id !== user.id);
    this.setState({
      viewers, viewersToBeRemoved: filteredViewers, projectViewers,
    });
    this.props.updateProjectViewers(projectViewers);
  }

  registerProjectAdministratorStep2(formData) {
    const userModel = {
      email: this.state.RegisteringUserEmail,
      firstName: formData.firstName,
      lastName: formData.lastName,
      projectId: this.props.projectDetails.id,
      organisation: 'external', // This was required field So adding data as mentioned in wiki
      territory: 'AU', // This was required field So adding data as mentioned in wiki
      assuranceLevel: '', // This was required field So adding data as mentioned in wiki
    };


    return this.props.createNewUser(userModel).then((response) => {
      const { status, userData } = response.data;
      if (status === constant.Success || status === constant.UserAlreadyOnboarded) {
        this.pushNewAdminToList(userData);
      }
      return response;
    });
  }

  // Confirm Delete
  confirmAdministratorDelete(emailId) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({
      selectedAdministratorEmailIdForDelete: emailId,
      showAdministratorDelete: true,
    });
  }

  cancelAdministratorDelete() {
    this.setState({
      selectedAdministratorEmailIdForDelete: '',
      showAdministratorDelete: false,
    });
  }

  // Confirm Viewer Delete
  confirmViewerDelete(emailId) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({
      selectedViewerEmailIdForDelete: emailId,
      showViewerDelete: true,
    });
  }

  cancelViewerDelete() {
    this.setState({
      selectedViewerEmailIdForDelete: '',
      showViewerDelete: false,
    });
  }

  setFieldValueOnBlur(field, e, setFieldValue, timeZone, values) {
    const { disableAnalysisPeriodIncrement } = this.state;
    if (field === 'analysisPeriod' && disableAnalysisPeriodIncrement) {
      return;
    }
    this.toggleUnsavedChanges();
    setFieldValue(field, e.target.value);
    if (field === 'analysisPeriod' && e.target.value && timeZone) {
      const { projectRightPanel } = this.props.portfolioData;
      let nextDropFullDate = '';
      let currentDate = '';
      if (projectRightPanel.isInEditMode) {
        currentDate = moment(this.props.projectDetails.lastDropDate).tz(timeZone);
      } else {
        currentDate = moment().tz(timeZone);
      }
      const dropDate = this.getFutureDropDates(currentDate, e.target.value);
      nextDropFullDate = moment(dropDate).tz(timeZone);
      const nextDropDate = moment(nextDropFullDate).tz(timeZone).format('dddd, D MMMM YYYY');
      if (projectRightPanel.isInEditMode) {
        values.analysisPeriod = e.target.value;
        values.projectId = values.id;
        values.portfolioId = values.selectedPortfolio;
        this.props.checkIfDropShouldRunImmediately(values).then((res) => {
          if (res) {
            this.setState({
              isDropRunningImmediate: true,
            });
          } else {
            this.setState({
              nextDropDate,
              isDropRunningImmediate: false,
            });
          }
        });
      } else {
        this.setState({ nextDropDate });
      }
    }
    if (field === 'analysisPeriod' && !e.target.value) {
      this.setState({ isDropRunningImmediate: false, nextDropDate: '' });
    }
  }

  getFutureDropDates(lastDropDate, analysisPeriod) {
    let currentDate = lastDropDate;
    let remainingDays = analysisPeriod;
    const SUNDAY = 0;
    const SATURDAY = 6;

    const isWeekend = (day) => {
      const dayIndex = moment(day).weekday();
      if (dayIndex === SUNDAY || dayIndex === SATURDAY) {
        return true;
      }
      return false;
    };

    while (remainingDays) {
      if (!isWeekend(currentDate)) {
        remainingDays -= 1;
      }
      currentDate = moment(currentDate).add(1, 'days');
    }

    const finalIndex = moment(currentDate).weekday();
    if (finalIndex === SUNDAY) {
      currentDate = moment(currentDate).add(1, 'days');
    }
    if (finalIndex === SATURDAY) {
      currentDate = moment(currentDate).add(2, 'days');
    }

    return moment(currentDate);
  }

  // Delete Project Life Cycle Stages
  removeProjectAdministrator() {
    this.toggleUnsavedChanges();
    const that = this;
    return new Promise(((resolve) => {
      let index = 0;
      let adminTobeAddedIndex = 0;
      that.state.projectAdmins.map((item, idx) => {
        if (item.email_address === that.state.selectedAdministratorEmailIdForDelete) {
          index = idx;
        }
        return item;
      });
      const { projectAdmins } = that.state;
      const { adminsToBeRemoved, admins } = this.state;
      if (admins.length > 0) {
        admins.map((item, idx) => {
          if (item.email_address === that.state.selectedAdministratorEmailIdForDelete) {
            adminTobeAddedIndex = idx;
          }
          return item;
        });
        admins.splice(adminTobeAddedIndex, 1);
      }
      adminsToBeRemoved.push(projectAdmins[index]);
      projectAdmins.splice(index, 1);
      that.props.updateProjectAdmins(projectAdmins);
      resolve(true);
    }));
  }

  // Delete Project Viewers
  removeProjectViewers() {
    this.toggleUnsavedChanges();
    const that = this;
    return new Promise(((resolve) => {
      let index = 0;
      let viewerTobeAddedIndex = 0;
      that.props.projectDetails.projectViewers.map((item, idx) => {
        if (item.email_address === that.state.selectedViewerEmailIdForDelete) {
          index = idx;
        }
        return item;
      });
      const { projectViewers } = that.props.projectDetails;
      const { viewersToBeRemoved, viewers } = this.state;
      if (viewers.length > 0) {
        viewers.map((item, idx) => {
          if (item.email_address === that.state.selectedViewerEmailIdForDelete) {
            viewerTobeAddedIndex = idx;
          }
          return item;
        });
        viewers.splice(viewerTobeAddedIndex, 1);
      }
      viewersToBeRemoved.push(projectViewers[index]);
      projectViewers.splice(index, 1);
      that.props.updateProjectViewers(projectViewers);
      resolve(true);
    }));
  }

  getApplicableRole() {
    const { roles } = this.props;
    let role = '';
    if (roles) {
      if (roles.portfolioAdministrator) role = 'portfolioAdministrator';
      else if (roles.projectAdministrator) role = 'projectAdministrator';
    }
    return role;
  }

  emptyPreSelectedNewPortfolioId() {
    this.props.emptyPreSelectedNewPortfolioId();
  }

  previewWelcomeMessage(values) {
    this.setState({
      projectTitle: values.name,
      welcomeMessage: values.welcomeMessage,
      showPreviewWelcomeMessageModel: true,
    });
  }

  cancelPreviewWelcomeMessage() {
    this.setState({ showPreviewWelcomeMessageModel: false });
  }

  selectDrop(e, setFieldValue, value) {
    setFieldValue('isDropRecurring', value);
  }

  changeSelectedDropDate(values, date) {
    if (date) {
      const formatedDate = moment(date).tz(values.selectedDropTimezone, true).format();
      values.adHocDropDate = formatedDate;
      this.setState({ adHocDate: date });
      this.isAdhocDropExecutable(values);
    }
  }

  isAdhocDropExecutable(values) {
    // in time format
    const currentDate = moment.tz(values.selectedDropTimezone).format('X');
    const dateNew = moment(values.adHocDropDate)
      .tz(values.selectedDropTimezone)
      .hours(values.selectedDropHour)
      .minute(0)
      .second(0)
      .format('X');
    if (currentDate > dateNew) {
      this.setState({
        isDropRunningImmediate: true,
      });
      return true;
    }
    this.setState({
      isDropRunningImmediate: false,
    });
    return false;
  }


  projectLastDropIsSameTodayDate(lastDropDate) {
    let calanderDate = '';
    const isSameDay = moment().isSame(lastDropDate, 'date');
    if (isSameDay) {
      const todayDate = new Date();
      calanderDate = todayDate.setDate(todayDate.getDate() + 1);
    } else {
      calanderDate = new Date();
    }
    return calanderDate;
  }

  handleFormOnChange = () => {
    this.props.updateUnsavedChanges(true);
  };

  setDefaultValueForRestrictedOrg(value, isRestrictedOrg) {
    if (isRestrictedOrg) {
      return false;
    }
    return value;
  }

  render() {
    const {
      projectAdmins,
      isLoading,
      isSubmitting,
      showDeleteModal,
      showRegisterProjectAdministratorModel,
      showAdministratorDelete,
      deleteAdministratorConfirmationMessage,
      deleteAdministratorTitle,
      showUnsavedChangesModal,
      isPortfolioChanged,
      isQuestionSetChanged,
      immediateDropTriggerConfirmationMessage,
      immediateDropTriggerConfirmationTitle,
      showImmediateDropModal,
      isTopicSubmitted,
      showRegisterProjectViewerModel,
      projectViewers,
      showViewerDelete,
      deleteViewerTitle,
      deleteViewerConfirmationMessage,
      nextDropDate,
      isDropRunningImmediate,
      showPreviewWelcomeMessageModel,
      projectTitle,
      welcomeMessage,
      totalEmails,
      isRestrictedUser,
    } = this.state;

    const {
      isSysAdmin,
      portfolioData,
      portfolios,
      lifeCycleStageList,
      projectDetails,
      isSpinnerLoading,
      systemTerminology,
      questionSets,
      showProcessingRecords,
      isProcessingRecords, passedRecords, failedRecords,
    } = this.props;
    const portfolioReplace = levelOneOrganizations.find(
      o => o.value === systemTerminology.level_one,
    );
    const portfolioReplaceText = portfolioReplace && portfolioReplace.text;

    const projectReplace = levelTwoOrganizations.find(
      o => o.value === systemTerminology.level_two,
    );
    const projectReplaceText = projectReplace && projectReplace.text;

    const newDeleteAdministratorTitle = deleteAdministratorTitle
      .replace(
        /#projectReplaceText#/g, projectReplaceText,
      );

    const newDeleteProjectViewerTitle = deleteViewerTitle
      .replace(
        /#projectReplaceText#/g, projectReplaceText,
      );

    const newDeleteAdministratorConfirmationMessage = deleteAdministratorConfirmationMessage
      .replace(
        /#projectReplaceText#/g, projectReplaceText,
      );

    const newDeleteViewerConfirmationMessage = deleteViewerConfirmationMessage
      .replace(
        /#projectReplaceText#/g, projectReplaceText,
      );

    const newProjectDeleteTitle = constant.deleteModel.title.replace(
      /#projectReplaceText#/g, projectReplaceText,
    );
    const newProjectDeleteMessage = constant.deleteModel.message.replace(
      /#projectReplaceText#/g, projectReplaceText,
    );

    const { projectRightPanel } = portfolioData;
    const associatedPlaceHolder = `Select ${'aeiou'.indexOf(portfolioReplaceText[0].toLowerCase()) !== -1 ? 'an ' : 'a '
      }${portfolioReplaceText}`;
    const stagePlaceHolder = `Select ${projectReplaceText} stage`;
    const { title, message, placeholder } = constant.registerEmailAdministratorModel;
    const {
      viewerModelTitle, viewerModelMessage,
      viewerModelPlaceholder,
    } = constant.registerEmailViewerModel;
    const replaceWithPrefix = text => text.replace(/#projectReplaceTextWithPreFix#/g,
      ('aeiou'.indexOf(projectReplaceText[0].toLowerCase()) !== -1 ? 'an ' : 'a ')
      + projectReplaceText);
    let newRegisterMailMessage = replaceWithPrefix(message);
    newRegisterMailMessage = newRegisterMailMessage.replace(/#projectReplaceText#/g, projectReplaceText);
    newRegisterMailMessage = newRegisterMailMessage.replace(/#projectNameReplaceText#/g, projectDetails.name);
    newRegisterMailMessage = `${newRegisterMailMessage[0].toUpperCase()} ${newRegisterMailMessage.slice(1)}`;
    const newTitle = title.replace(/#projectReplaceText#/g,
      ('aeiou'.indexOf(projectReplaceText[0].toLowerCase()) !== -1 ? 'an ' : 'a ')
      + projectReplaceText);
    const newViewerModelTitle = viewerModelTitle.replace(/#projectReplaceText#/g,
      projectReplaceText);
    const newVieweModelMessage = viewerModelMessage.replace(/#projectReplaceText#/g, projectReplaceText);

    return (
      <React.Fragment>
        {/* Unsaved changes confirmation Modal */}
        <RenderIf showComponent={showUnsavedChangesModal}>
          <UnsavedChangesConfirmation
            confirmationClick={this.cancelUnsavedChanges.bind(this)}
            cancelClick={this.retainUnsavedChanges.bind(this)}
          />
        </RenderIf>
        {/* Immediate Drop run confirmation Modal */}
        <RenderIf showComponent={showImmediateDropModal && !projectDetails.isDrafted}>
          <UnsavedChangesConfirmation
            confirmationClick={this.submitProjectDetails.bind(this)}
            cancelClick={this.retainProjectDetails.bind(this)}
            modalMessage={immediateDropTriggerConfirmationMessage}
            modalTitle={immediateDropTriggerConfirmationTitle}
          />
        </RenderIf>
        <RenderIf showComponent={showDeleteModal}>
          <DeleteConfirmation
            title={newProjectDeleteTitle.toUpperCase()}
            message={newProjectDeleteMessage}
            confirmationClick={this.deleteProject.bind(this)}
            cancelClick={this.cancelDelete.bind(this)}
            archiveclick={true}
          />
        </RenderIf>
        {showAdministratorDelete && (
          <div>
            <DeleteConfirmation
              title={newDeleteAdministratorTitle}
              message={newDeleteAdministratorConfirmationMessage}
              hideActions={false}
              confirmationClick={this.removeProjectAdministrator.bind(this)}
              cancelClick={this.cancelAdministratorDelete.bind(this)}
            />
          </div>
        )}
        {showViewerDelete && (
          <div>
            <DeleteConfirmation
              title={newDeleteProjectViewerTitle}
              message={newDeleteViewerConfirmationMessage}
              hideActions={false}
              confirmationClick={this.removeProjectViewers.bind(this)}
              cancelClick={this.cancelViewerDelete.bind(this)}
            />
          </div>
        )}
        {showPreviewWelcomeMessageModel && (
          <div>
            <PreviewWelcomeMessage
              title={projectTitle}
              message={welcomeMessage}
              hideActions={false}
              confirmationClick={this.removeProjectViewers.bind(this)}
              cancelClick={this.cancelPreviewWelcomeMessage.bind(this)}
            />
          </div>
        )}

        <RenderIf showComponent={showRegisterProjectAdministratorModel}>
          <RegisterEmail
            title={newTitle}
            message={newRegisterMailMessage}
            placeholder={placeholder}
            availableEmails={projectAdmins.map(item => item.email_address)}
            availableViewersEmails={projectViewers.map(item => item.email_address)}
            onContinueButtonClick={this.registerProjectAdministrator.bind(this)}
            cancelClick={() => this.setState({ showRegisterProjectAdministratorModel: false })}
            isRestrictedUser={isRestrictedUser}
            isStakeholders={false}
            isTestEmail={false}
          />
        </RenderIf>

        <RenderIf showComponent={showRegisterProjectViewerModel}>
          <RegisterEmail
            title={newViewerModelTitle}
            message={newVieweModelMessage}
            placeholder={viewerModelPlaceholder}
            availableEmails={projectViewers.map(item => item.email_address)}
            availableAdmins={projectAdmins.map(item => item.email_address)}
            onContinueButtonClick={this.registerProjectViewers.bind(this)}
            cancelClick={() => this.setState({ showRegisterProjectViewerModel: false })}
            isRestrictedUser={isRestrictedUser}
            isStakeholders={false}
            isTestEmail={false}
          />
        </RenderIf>

        <div className="right-sidebar add-project-sidebar" ref={this.setWrapperRef}>
          <Spinner isLoading={isSpinnerLoading} />
          <RenderIf showComponent={!isSpinnerLoading}>
            <div className="r-s-header">
              <div className="r-s-title">
                <span>
                  {!projectRightPanel.isInEditMode ? `Create a new  ${projectReplaceText}` : `${projectReplaceText} Settings`}
                  <a>
                    <i
                      className="appkiticon icon-close-outline"
                      onClick={this.hideProjectRightPanel}
                    />
                  </a>{' '}
                </span>
              </div>
              <div className="indicate_val">
                <span className="mandatory-icon">* </span>
                <span className="in_text preview-pane-font"> indicates required field</span>
              </div>
            </div>
            <div className="app">
              {!isLoading
                && <Formik
                  initialValues={projectDetails}
                  validateOnBlur={true}
                  onSubmit={(values, actions) => {
                    const formData = { ...values };
                    formData.projectOpenTextQuestions = projectDetails.projectOpenTextQuestions;
                    formData.projectViewers = projectDetails.projectViewers;
                    formData.stakeHoldersCanViewMobile = isRestrictedUser
                      ? false
                      : projectDetails.stakeHoldersCanViewMobile;
                    this.validateAndSaveProject(formData, actions);
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .trim()
                      .required(`${projectReplaceText} Name is required.`)
                      .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
                    selectedPortfolio: Yup.string().required(
                      `At least one ${portfolioReplaceText} needs to be selected.`,
                    ),
                    selectedQuestionSet: Yup.string().required('At least one question set needs to be selected.'),
                    lifecycleStage: Yup.string().required('At least one stage needs to be selected.'),
                    analysisPeriod: Yup.number()
                      .min(constant.MINIMUM_NEW_QUESTIONS_DROP_FREQUENCY, `Minimum new question frequency Length should be ${constant.MINIMUM_NEW_QUESTIONS_DROP_FREQUENCY} days`)
                      .max(2147483647, 'Maximum new question frequency should be 2147483647 days')
                      .integer('New question frequency can only been whole number')
                      .required('New question frequency is required.'),
                    completionMessage: Yup.string().max(700, 'You have exceeded the maximum character limit')
                      .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
                  })}
                >
                  {({
                    errors, touched, setFieldValue, values, setFieldTouched, setFieldError,
                  }) => {
                    let preSelectedPortfolio = 0;
                    if (portfolioData.newPortfolioId && this.state.preSelectNewPortfolio
                      && !isTopicSubmitted) {
                      preSelectedPortfolio = portfolioData.newPortfolioId;
                      this.changePortfolio(portfolioData.newPortfolioId, setFieldValue);
                      this.emptyPreSelectedNewPortfolioId();
                      this.setState({ preSelectNewPortfolio: false });
                    }
                    if (!projectRightPanel.isInEditMode && portfolios && projectRightPanel.showPanel
                      && portfolios.length === 1 && !this.state.singlePortfolioSelected
                      && !isTopicSubmitted) {
                      preSelectedPortfolio = portfolios[0].value;
                      this.changePortfolio(portfolios[0].value, setFieldValue);
                      this.setState({ singlePortfolioSelected: true });
                    }
                    // eslint-disable-next-line
                    return <Form onChange={this.handleFormOnChange}>
                      <div className="r-s-body mt-3">
                        <RenderIf showComponent={!projectRightPanel.isInEditMode}>
                          <div className="form-group">
                            <div className="a-form-label">Associated {portfolioReplaceText}<span className="mandatory-icon">*</span></div>
                            <div className="row">
                              <div className="col-md-6">
                                <SumoSelect
                                  disabled={showProcessingRecords}
                                  name="selectedPortfolio"
                                  placeholder={associatedPlaceHolder}
                                  selectedValues={preSelectedPortfolio !== 0
                                    ? preSelectedPortfolio : values.selectedPortfolio}
                                  options={portfolios}
                                  setFieldValue={setFieldValue}
                                  onSumoSelectChange={() => this.changePortfolio(
                                    values.selectedPortfolio,
                                    setFieldValue,
                                  )}
                                />
                              </div>
                              <div className="col-md-6 text-right">
                                <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                                  perform="portfolio:create"
                                  permit={() => (
                                    <a
                                      href="javascript:void(0)"
                                      onClick={this.showPortfolioRightPanel}
                                      className="a-btn a-btn-secondary a-btn-lg"
                                    >
                                      New {portfolioReplaceText}
                                    </a>
                                  )} />
                              </div>
                            </div>
                            <div className="error">
                              {' '}
                              <ErrorMessage name="selectedPortfolio" />
                            </div>
                          </div>
                        </RenderIf>

                        <RenderIf showComponent={projectRightPanel.isInEditMode}>
                          <div className="form-group">
                            <div className="a-form-label">ASSOCIATED {portfolioReplaceText.toUpperCase()}<span className="mandatory-icon">*</span></div>
                            <div className=" text-icon icon-right">
                              <MaterialIcon icon="lock" />
                              <Field
                                disabled={showProcessingRecords}
                                name="selectedPortfolioName"
                                autoComplete="off"
                                className="a-text-input"
                                onBlur={e => this.setFieldValueOnBlur('selectedPortfolioName', e, setFieldValue)}
                              />
                            </div>
                          </div>
                        </RenderIf>

                        <div className="form-group">
                          <div className="a-form-label">{projectReplaceText} Name<span className="mandatory-icon">*</span></div>
                          <Field
                            disabled={showProcessingRecords}
                            name="name"
                            autoFocus={!projectRightPanel.isInEditMode}
                            autoComplete="off"
                            className={
                              errors.name && touched.name ? 'error a-text-input' : 'a-text-input'
                            }
                            onChange={e => this.setFieldValueOnBlur('name', e, setFieldValue)}
                          />
                          <div className="error">
                            {' '}
                            <ErrorMessage name="name" />
                          </div>
                        </div>
                        <RenderIf showComponent={!projectRightPanel.isInEditMode}>
                          {(values.selectedPortfolio && questionSets)
                            && questionSets.length > 0
                            && isPortfolioChanged && <div className="form-group">
                              <div className="a-form-label">Question Set<span className="mandatory-icon">*</span></div>
                              <SumoSelect
                                name="selectedQuestionSet"
                                placeholder='Select question set'
                                selectedValues={questionSets.length === 1
                                  ? questionSets[0].value : values.selectedQuestionSet}
                                options={questionSets}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.changeQuestionModel(
                                  values.selectedQuestionSet,
                                  setFieldValue,
                                )}
                                disabled={questionSets.length === 1 || showProcessingRecords}
                              />
                              <div className="error">
                                {' '}
                                <ErrorMessage name="selectedQuestionSet" />
                              </div>
                            </div>}
                        </RenderIf>
                        <RenderIf showComponent={projectRightPanel.isInEditMode}>
                          <div className="form-group">
                            <div className="a-form-label">Question Set<span className="mandatory-icon">*</span></div>
                            <div className=" text-icon icon-right">
                              <MaterialIcon icon="lock" />
                              <Field
                                disabled={showProcessingRecords}
                                name="selectedQuestionModelName"
                                autoComplete="off"
                                className="a-text-input"
                                onBlur={e => this.setFieldValueOnBlur('selectedQuestionModelName', e, setFieldValue)}
                              />
                            </div>
                          </div>
                        </RenderIf>
                        {values.selectedQuestionSet && <div className="form-group">
                          <div className="a-form-label">Reference Code</div>
                          <Field name="refCode" autoComplete="off" className="a-text-input"
                            onChange={e => this.setFieldValueOnBlur('refCode', e, setFieldValue)}
                            disabled={showProcessingRecords}
                          />
                        </div>}
                        {values.selectedQuestionSet
                          && <div className="form-group">
                            <div className="a-form-label">
                              <div class="welcome-message-wrapper">
                                <span className='preview-pane-font'>Welcome Message
                                  <span class="pop-out-help ">
                                    <MaterialIcon id='welcomeMessage' data-for='welcomeMessage' data-tip icon="help" />
                                  </span>
                                </span>
                                <span class="welcome" onClick={() => this.previewWelcomeMessage(values)}>
                                  <span class="appkiticon icon-view-fill"></span>
                                  <text>Preview</text>
                                </span>
                              </div>
                            </div>
                            <ReactTooltip id='welcomeMessage' className='tooltip' place='top'
                              effect='solid'>
                              This message will be sent to all new stakeholders added to this topic,
                              both at launch and those added in future.
                              A Welcome Message is a one-time-only event and stakeholders will not
                              receive this in subsequent drops or reminder emails.
                            </ReactTooltip>
                            <Field
                              disabled={showProcessingRecords}
                              component='textarea'
                              name="welcomeMessage"
                              placeholder="Enter message"
                              autoComplete="off"
                              className='a-text-input'
                              onChange={e => this.setFieldValueOnBlur('welcomeMessage', e, setFieldValue)}
                            />
                          </div>}
                        {values.selectedQuestionSet
                          && <div className="form-group">
                            <div className="a-form-label">
                              {values.completionMessageCharacter > 0
                                && <span className={values.completionMessageCharacter > 700
                                  ? 'pull-right red lower-case preview-pane-font' : 'pull-right lower-case preview-pane-font'}>
                                  {values.completionMessageCharacter}/700 characters</span>
                              }
                              <div className="d-flex"><span className='preview-pane-font'>Completion Message </span>
                                <span class="pop-out-help ">
                                  <MaterialIcon id='completionMessage' data-for='completionMessage' data-tip='completion_tooltip' icon="help"
                                  />
                                </span></div>
                            </div>
                            <ReactTooltip id='completionMessage' className='completion_tooltip' place='top'
                              effect='solid'>
                              This message will be displayed to users when they have finished
                              responding to all of the questions associated with this topic.
                            </ReactTooltip>
                            <Field
                              disabled={showProcessingRecords}
                              component='textarea'
                              name="completionMessage"
                              placeholder="Enter message"
                              autoComplete="off"
                              className='a-text-input'
                              onChange={
                                value => checkCompletionMessageLength(
                                  value, setFieldTouched, setFieldError, setFieldValue,
                                )}
                            />
                            <div className="error">
                              <ErrorMessage name="completionMessage" />
                            </div>
                          </div>}
                        {values.selectedQuestionSet && <div className="form-group">
                          <div className="a-form-label">Budget</div>
                          <SumoSelect
                            disabled={showProcessingRecords}
                            name="selectedBudget"
                            selectedValues={values.selectedBudget = values.selectedBudget
                              ? values.selectedBudget : constant.projectBudget[0].value}
                            options={constant.projectBudget}
                            setFieldValue={setFieldValue}
                            onSumoSelectChange={() => this.toggleUnsavedChanges()}
                          />
                        </div>}
                        {(values.selectedQuestionSet && isQuestionSetChanged) && <div className="form-group">
                          <div className="a-form-label">Current Lifecycle stage<span className="mandatory-icon">*</span></div>
                          <SumoSelect
                            disabled={showProcessingRecords}
                            name="lifecycleStage"
                            placeholder={stagePlaceHolder}
                            selectedValues={values.lifecycleStage}
                            options={lifeCycleStageList}
                            setFieldValue={setFieldValue}
                            onSumoSelectChange={() => this.toggleUnsavedChanges()}
                          />
                          <div className="error">
                            {' '}
                            <ErrorMessage name="lifecycleStage" />
                          </div>
                        </div>}
                        {values.selectedQuestionSet && <div className="form-group mb-0">
                          <div className="a-form-label">NEW QUESTIONS FREQUENCY(WEEKDAYS)<span className="mandatory-icon">*</span></div>

                          <div className="form-group">
                            <label class="a-radio a-mx-10">
                              <input
                                name="isDropRecurring"
                                type="radio"
                                value="true"
                                checked={values.isDropRecurring === true}
                                onClick={e => this.selectDrop(e, setFieldValue, true)}
                              />
                              <span class="a-radio-mark">
                                <span class="a-radio-inner" />
                              </span>
                              <span class="a-radio-text preview-pane-font">Recurring</span>
                            </label>
                            <label class="a-radio a-mx-10">
                              <input
                                name="isDropRecurring"
                                type="radio"
                                value="false"
                                checked={values.isDropRecurring === false}
                                onClick={e => this.selectDrop(e, setFieldValue, false)}
                              />
                              <span class="a-radio-mark">
                                <span class="a-radio-inner" />
                              </span>
                              <span class="a-radio-text preview-pane-font">Ad-hoc</span>
                            </label>
                          </div>
                          {values.selectedQuestionSet
                            && !projectDetails.isDrafted
                            && !values.isDropRecurring && projectRightPanel.isInEditMode && <div className="form-group">
                              <p className="adhoc"> Send questions on</p>
                              <div className="row">
                                <div className="col-md-6">
                                  {!values.isDropRecurring && projectRightPanel.isInEditMode
                                    && <DatePicker
                                      selected={values.isNextAdhocDropScheduled
                                        ? moment(values.adHocDropDate).toDate()
                                        : this.state.adHocDate
                                      }
                                      onChange={date => this.changeSelectedDropDate(values, date)}
                                      minDate={
                                        this.projectLastDropIsSameTodayDate(
                                          this.props.projectDetails.lastDropDate,
                                        )}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                      className='a-text-input' placeholderText={'dd/mm/yyyy'} dateFormat="dd/MM/yyyy" />}
                                </div>
                                <div className="col-md-6">
                                  <div className="cs-timepicker position-relative">
                                    <div className="sumo-icon-container">
                                      <span class="material-icons">
                                        access_time
                                      </span>
                                      <SumoSelect
                                        disabled={showProcessingRecords}
                                        name="selectedDropHour"
                                        placeholder='Select time'
                                        selectedValues={
                                          values.selectedDropHour = values.selectedDropHour
                                            ? values.selectedDropHour
                                            : constant.timeConstant[9].value}
                                        options={constant.timeConstant}
                                        setFieldValue={setFieldValue}
                                        onSumoSelectChange={() => this.toggleUnsavedChanges(values)}
                                      />

                                    </div>
                                    <div className="error">
                                      {' '}
                                      <ErrorMessage name="selectedDropHour" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </div>}
                        {values.isDropRecurring && values.selectedQuestionSet && <p className="freq"> Send question every<span>
                          <Field
                            disabled={showProcessingRecords}
                            name="analysisPeriod"
                            autoComplete="off"
                            type="number"
                            className={
                              errors.analysisPeriod && touched.analysisPeriod
                                ? 'error a-text-input'
                                : 'a-text-input'
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value && (+value < 1 || +value > 999)) {
                                return 'error';
                              }
                              return this.setFieldValueOnBlur('analysisPeriod', e, setFieldValue, values.selectedDropTimezone, values);
                            }}
                            onBlur={() => this.resetDisableAnalysisPeriodIncrement}
                          />
                        </span>weekday(s) at.</p>}
                        {values.selectedQuestionSet
                          && values.isDropRecurring && <div className="form-group">
                            <div className="sumo-icon-container">
                              <span class="material-icons">
                                access_time
                              </span>
                              <SumoSelect
                                disabled={showProcessingRecords}
                                name="selectedDropHour"
                                placeholder='Select time'
                                selectedValues={values.selectedDropHour = values.selectedDropHour
                                  ? values.selectedDropHour : constant.timeConstant[9].value}
                                options={constant.timeConstant}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.toggleUnsavedChanges(values)}
                              />

                            </div>
                            <div className="error">
                              {' '}
                              <ErrorMessage name="selectedDropHour" />
                            </div>
                          </div>}
                        {values.selectedQuestionSet && this.timeZones && values.isDropRecurring && <div className="form-group">
                          <div className="sumo-icon-container">
                            <span class="material-icons">
                              public
                            </span>
                            <SumoSelect
                              disabled={showProcessingRecords}
                              name="selectedDropTimezone"
                              placeholder='Select timezone'
                              selectedValues={values.selectedDropTimezone || this.defaultTimeZone}
                              options={this.timeZones}
                              setFieldValue={setFieldValue}
                              onSumoSelectChange={() => this.toggleUnsavedChanges(values)}
                              searchfield='true'
                            />

                          </div>
                          <div className="error">
                            {' '}
                            <ErrorMessage name="selectedDropTimezone" />
                            <ErrorMessage name="analysisPeriod" />
                          </div>
                          {(nextDropDate || isDropRunningImmediate) && !projectDetails.isDrafted && values.isDropRecurring && values.selectedQuestionSet && <div className="a-tag a-tag-fff3d1 alert-tag">
                            {' '}
                            {isDropRunningImmediate
                              && <p>The next question drop will be triggered when you click on the{' '}
                                'Create'/'Save' button.
                              </p>
                            }
                            {(nextDropDate && !isDropRunningImmediate)
                              && <p>The next question drop will be on{' '}
                                <span>{nextDropDate}</span>.</p>
                            }
                          </div>}
                        </div>}
                        {values.selectedQuestionSet
                          && !projectDetails.isDrafted
                          && !values.isDropRecurring && projectRightPanel.isInEditMode && <div className="form-group">
                            <div className="sumo-icon-container">
                              <span class="material-icons">
                                public
                              </span>
                              <SumoSelect
                                disabled={showProcessingRecords}
                                name="selectedDropTimezone"
                                placeholder='Select timezone'
                                selectedValues={values.selectedDropTimezone || this.defaultTimeZone}
                                options={this.timeZones}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.toggleUnsavedChanges(values)}
                                searchfield='true'
                              />

                            </div>
                            <div className="error">
                              {' '}
                              <ErrorMessage name="selectedDropTimezone" />
                              <ErrorMessage name="analysisPeriod" />
                            </div>
                            {(nextDropDate || isDropRunningImmediate) && !projectDetails.isDrafted && !values.isDropRecurring && values.selectedQuestionSet && <div className="a-tag a-tag-fff3d1 alert-tag">
                              {' '}
                              {isDropRunningImmediate
                                && <p>The next question drop will be triggered when you click on the{' '}
                                  'Create'/'Save' button.
                                </p>
                              }
                              {(nextDropDate && !isDropRunningImmediate)
                                && <p>The next question drop will be on{' '}
                                  <span>{nextDropDate}</span>.</p>
                              }
                            </div>}
                          </div>}
                        {values.selectedQuestionSet && <div className="portfolio-admins mt-4">
                          <p className="section-title m-0 mb-1">
                            {projectReplaceText} Administrators
                          </p>
                          <p className="a-font-sm m-0">
                            Specify the users who will have full, unrestricted access
                            to this {projectReplaceText}, including the ability
                            to view the {projectReplaceText} {' '}
                            dashboard. There must be at least
                            one {projectReplaceText} Administrator.
                          </p>
                          <div className="mt-3 mb-4">
                            <ul className="a-list cross-list project-stakeholder">
                              {projectAdmins && projectAdmins.map((item, index) => (
                                <li className="a-list-item d-flex align-items-center" key={index}>
                                  {item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.first_name} {item.last_name}</div>}
                                  {!item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.email_address}</div>}
                                  {projectAdmins.length !== 1 && item.canDelete ? (
                                    <i className="appkiticon icon-close-fill a-font-xs" onClick={() => this.confirmAdministratorDelete(item.email_address)} />
                                  ) : ''}
                                  {item.id > 0 && item.is_user_registered !== undefined
                                    && !item.is_user_registered
                                    && <div className={'a-badge a-badge-secondary'}>Pending Registration</div>}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <a href="javascript:void(0)" className="add-admin-button a-btn a-btn-transparent a-btn-lg a-btn-gray mt-2"
                            onClick={this.openRegisterProjectAdministratorModel.bind(this)}>
                            <i className="appkiticon icon-plus-fill mr-2"> </i> Add {'aeiou'.indexOf(projectReplaceText[0].toLowerCase()) !== -1 ? 'an' : 'a'} {projectReplaceText} {' '}
                            Administrator{' '}
                          </a>
                        </div>}
                        {values.selectedQuestionSet && <div className="portfolio-admins mt-4">
                          <p className="section-title m-0 mb-1">
                            {projectReplaceText} Viewers
                          </p>
                          <p className="a-font-sm m-0">
                            Specify the users who will have access to view the{' '}
                            {projectReplaceText} dashboard.
                            The {projectReplaceText} Viewer will not have access to edit
                            the topic settings or ask follow-up questions.
                          </p>
                          <div className="mt-3 mb-4">
                            <ul className="a-list cross-list project-stakeholder">
                              {projectViewers && projectViewers.map((item, index) => (
                                <li className="a-list-item d-flex align-items-center" key={index}>
                                  {item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.first_name} {item.last_name}</div>}
                                  {!item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.email_address}</div>}
                                  {item.canDelete ? (
                                    <i className="appkiticon icon-close-fill a-font-xs" onClick={() => this.confirmViewerDelete(item.email_address)} />
                                  ) : ''}
                                  {item.id > 0 && item.is_user_registered !== undefined
                                    && !item.is_user_registered
                                    && <div className={'a-badge a-badge-secondary'}>Pending Registration</div>}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <a href="javascript:void(0)" className="add-admin-button a-btn a-btn-transparent a-btn-lg a-btn-gray mt-2"
                            onClick={this.openRegisterProjectViewerModel.bind(this)}>
                            <i className="appkiticon icon-plus-fill mr-2"> </i> Add {'aeiou'.indexOf(projectReplaceText[0].toLowerCase()) !== -1 ? 'an' : 'a'} {projectReplaceText} {' '}
                            Viewer{' '}
                          </a>
                        </div>}
                        {values.selectedQuestionSet && <div className="form-group">
                          <div className="a-form-label">Permissions for {projectReplaceText} Stakeholders</div>
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              disabled={showProcessingRecords || isRestrictedUser}
                              type="checkbox"
                              name="toggle"
                              checked={
                                this.setDefaultValueForRestrictedOrg(
                                  values.stakeHoldersCanViewMobile,
                                  isRestrictedUser,
                                )
                              }
                              onChange={this.changeStakeHoldersCanViewMobileDashboard
                                .bind(this, values, setFieldValue)}
                            />
                            <div className="a-toggle-mark">
                              <span className="on preview-pane-font" >Yes</span>
                              <span className="off preview-pane-font" >No</span>
                              <span className="switch preview-pane-font" />
                            </div>
                            <span className="a-toggle-text preview-pane-font">
                              View insights on mobile app.
                            </span>
                            <span class="pop-out-help mt-2">
                              <MaterialIcon id='stakeHoldersCanViewMobile' data-for='stakeHoldersCanViewMobile' data-tip='mobile_tooltip' icon="help"
                              />
                              <ReactTooltip id='stakeHoldersCanViewMobile' className='mobile_tooltip' place='top'
                                effect='solid'>
                                Once stakeholders finish responding to all questions associated with
                                this {projectReplaceText} they can view
                                their results and compare it with others.
                              </ReactTooltip>
                            </span>
                          </label>
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              disabled={showProcessingRecords || isRestrictedUser}
                              type="checkbox"
                              name="toggle"
                              checked={values.stakeHoldersCanView}
                              onChange={this.changeStakeHoldersCanView
                                .bind(this, values, setFieldValue)}
                            />
                            <div className="a-toggle-mark">
                              <span className="on preview-pane-font">Yes</span>
                              <span className="off preview-pane-font">No</span>
                              <span className="switch preview-pane-font" />
                            </div>
                            <span className="a-toggle-text preview-pane-font">
                              View insights on web dashboard.
                            </span>
                            <span class="pop-out-help mt-2">
                              <MaterialIcon id='stakeHoldersCanView' data-for='stakeHoldersCanView' data-tip='dash_tooltip' icon="help"
                              />
                              <ReactTooltip id='stakeHoldersCanView' className='dash_tooltip' place='top'
                                effect='solid'>
                                Stakeholders can view overall insights of
                                this {projectReplaceText} on the web dashboard.
                              </ReactTooltip>
                            </span>
                          </label>
                        </div>}
                        <RenderIf showComponent={projectRightPanel.isInEditMode}>
                          {values.selectedQuestionSet && <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                            perform="project:modify"
                            permit={() => (
                              <div className="form-group mt-3">
                                <div className="a-form-label">{projectReplaceText} status</div>
                                <label className="a-toggle a-m-5 font-normal">
                                  <input
                                    disabled={showProcessingRecords}
                                    type="checkbox"
                                    name="toggle1"
                                    checked={values.isActive}
                                    onChange={this.changeProjectIsActive
                                      .bind(this, values, setFieldValue)}
                                  />
                                  <div className="a-toggle-mark">
                                    <span className="on preview-pane-font">Yes</span>
                                    <span className="off preview-pane-font">No</span>
                                    <span className="switch preview-pane-font" />
                                  </div>
                                  <span className="a-toggle-text preview-pane-font">Active</span>
                                </label>
                              </div>
                            )} />}
                        </RenderIf>
                      </div>
                      {values.selectedQuestionSet && <div className="r-s-actions mt-4">
                        <div className="mt-3 mb-4">
                          {isProcessingRecords && isTopicSubmitted && (
                            <span style={{
                              position: 'relative',
                              float: 'right',
                              fontSize: 10,
                              top: 10,
                              color: failedRecords > 0 ? 'red' : 'rgba(0, 0, 0, 0.6)',
                            }}>{passedRecords} of {totalEmails} {' '}
                              users assigned successfully</span>)}
                          <SpinnerButton
                            isLoading={isSubmitting}
                            label={projectRightPanel.isInEditMode ? 'SAVE CHANGES' : 'CREATE'}
                          />
                        </div>
                        <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                          perform="project:delete"
                          permit={() => (<a href="javascript:void(0)">
                            <RenderIf showComponent={projectRightPanel.isInEditMode}>
                              <a onClick={this.deleteModal.bind(this)} href="javascript:void(0)" className="a-btn a-btn-transparent a-btn-lg a-btn-gray">
                                ARCHIVE {projectReplaceText.toUpperCase()}
                              </a>
                            </RenderIf>
                          </a>
                          )} />
                      </div>}
                    </Form>;
                  }}
                </Formik>
              }</div>
          </RenderIf>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  projectData: getProject(state),
  portfolios: getPortfolios(state),
  selectedPortfolio: getSelectedPortfolio(state),
  questionSets: state.portfolio.portfolioData.questionModels,
  lifeCycleStageList: getStages(state),
  projectDetails: state.project.projectDetails,
  isSpinnerLoading: state.project.isSpinnerLoading,
  isSysAdmin: state.login.isSysAdmin,
  roles: state.portfolio.portfolioData.roles,
  systemTerminology: getSystemTerminologySelector(state),
  unsavedChangesModal: state.general.unsavedChangesModal,
  unsavedChanges: state.general.unsavedChanges,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
});

const mapDispatchToProps = {
  getProjectDetailsAction,
  getPortfoliosData,
  getSelectedPortfolioAdmins,
  saveProjectAction,
  checkProjectTitleAction,
  hideProjectRightPanel,
  showPortfolioRightPanel,
  updateProjectAction,
  deleteProject,
  changeSelectedPortfolioAndProject,
  push,
  checkUncheckDomain,
  showLoadingSpinner,
  hideLoadingSpinner,
  validateUserByEmail,
  createNewUser,
  updateFormState,
  updateProjectAdmins,
  updateProjectDetails,
  emptyPreSelectedNewPortfolioId,
  getSystemTerminology,
  disableUnsavedChangesModal,
  disableUnsavedChanges,
  enableUnsavedChanges,
  enableUnsavedChangesModal,
  getPortfolioQuestionModelList,
  setSelectedQuestionModelId,
  checkIfDropShouldRunImmediately,
  showRecordProcessing,
  hideRecordProcessing,
  updateProjectViewers,
  projectActiveState,
  updatePortfolioData,
  projectAdhocDropDate,
  projectUpdatedData,
  showHideOverlay,
  updateUnsavedChanges,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddProject);
