import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import InformationOutline from '../../../../ui-components/InformationOutline/InformationOutline';
import archiveLockSvg from '../../../../../assets/images/component/svg/archive-lock-icon-2.svg';

export default class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleEvent = this.handleEvent.bind(this);
    this.truncate = this.truncate.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() { }

  // Prototype methods, Bind in Constructor (ES2015)
  handleEvent() { }

  // Class Properties (Stage 3 Proposal)
  handler = () => {
    this.setState();
  };

  truncate(s) {
    const n = s?.length;
    if (n === 0 || s == null || s.trim() === 'null') {
      return '';
    }
    if (n > 70) {
      const trimmedString = s.substring(0, 70);
      return `${trimmedString}...`;
    }

    return s;
  }

  render() {
    const { questionSetList } = this.props;
    return (
      <>
        <div className="card_bg" style={{ height: questionSetList.length === 0 ? '100%' : 'auto' }}>
          <span className="d-block mb-3 table_title preview-pane-font">Relevant Questions <InformationOutline /></span>
          {questionSetList.length === 0 && (
            <div className='message-0-stakeholder'>
              <div className='message-0-stakeholder-wrap'>
                Based on the current topic settings (focus areas and lifecycle stage)
                there are no questions available for the next drop. Please liaise with your
                Topic Administrator if you believe this to be in error.
              </div>
            </div>
          )}
          {questionSetList.length > 0
            && (<div className="preview-table-scroller question-table a-mb-10">
              <table className="preview-a-table data-table a-lg min-w-1024 sortable selectable setting-table preview-table w-100">
                <thead className="a-thead">
                  <tr>
                    <th className="q-question-cell">Focus Area</th>
                    <th className="q-question-cell">Sub-Category</th>
                    <th className="q-polarity-cell">Question</th>
                    <th className="q-state-cell">Type</th>
                  </tr>
                </thead>
                <tbody className="a-tbody">
                  {questionSetList.map((question) => {
                    const domainTitle = `${question.domain_title}`;
                    return (
                      <tr>
                        <td className="q-polarity-cell cursor-pointer" style={{ width: '24%' }}>
                          <span className="question-cell-conatiner custom-width-cell preview-pane-font"
                            title={domainTitle || ''}
                          >{domainTitle ? this.truncate(domainTitle) : ''}</span>
                        </td>
                        <td className="q-polarity-cell cursor-pointer" style={{ width: '24%' }}>
                          <span className="question-cell-conatiner custom-width-cell preview-pane-font"
                            title={question.att_title || ''}
                          >{domainTitle ? this.truncate(question.att_title) : ''}</span>
                        </td>
                        <td className="q-state-cell cursor-pointer" style={{ width: '62%' }}>
                          {question.isArchived && <ReactSVG id='stakeHoldersCanView' data-for='stakeHoldersCanView' data-tip='dash_tooltip' wrapper="svg" width="12px" height="12px" src={archiveLockSvg} />}
                          <ReactTooltip id='stakeHoldersCanView' className='archive_lock_pre_tooltip' place='top'
                            effect='solid'>
                            This question is locked and cannot be edited as it relates
                            to prior topics/organisations. If you would like to re-use this question
                            but make changes, please create  a new question instead.
                          </ReactTooltip>
                          <span className="ml-2 preview-pane-font" title={question.title}>
                            {this.truncate(question.title)}
                          </span>
                        </td>
                        <td className="q-attribute-cell cursor-pointer" style={{ width: '14%' }}>
                          <span className="attribute-cell-container preview-pane-font">{question.type === 1 ? 'Yes/No' : 'Open text'}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>)
          }
        </div>
      </>
    );
  }
}
