import moment from 'moment-timezone';
import { actionType } from '../../../redux/modules/project';
import portfolioActionType from '../../../redux/modules/portfolio/actionType';
import recordProcessingActionType from '../../../redux/modules/common/processing/actionType';
import projectService from '../../../redux/services/projectService';
import settingsService from '../../../redux/services/settingsService';
import { actionType as notificationActionType } from '../../../redux/modules/common/notification';
import { sortArrayValues } from '../../../utils/helper';
import {
  SORT_SEGMENT_ARRAY,
} from '../../../Constants';

// Creating thunk action creaters which will be access from component

export function getUserFilterGroupsForProject(projectId) {
  const data = {
    projectId,
  };
  return dispatch => projectService
    .getUserFilterGroupsForProject(data)
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.USER_FILTERS_FOR_PROJECT_AND_PORTFOLIO, data: res.data });
      }
    })
    .catch({});
}


export const getUserTagFiltersData = (
  projectId,
  portfolioId,
  selectedUserFilterValues,
  dates,
) => () => projectService
  .getUserTagFiltersData(projectId, portfolioId, selectedUserFilterValues, dates)
  .then(res => res.data)
  .catch({});

// save project action
export function saveProjectAction(projectData) {
  return dispatch => projectService
    .saveProject(projectData)
    .then((res) => {
      if (res.data !== undefined) {
        const { portfolioId } = res.data;
        dispatch({ type: actionType.SAVE_SUCCESS, data: portfolioId });
        return res.data;
      }
      return res;
    })
    .catch({});
}

export function saveFilterGroups(filterData) {
  return () => projectService
    .saveFilterGroups(filterData)
    .then(res => res)
    .catch({});
}

// save project domains
export function submitProjectDomains(domainsData) {
  return () => projectService
    .saveProjectDomains(domainsData)
    .then(res => res)
    .catch({});
}

// update project action
export function updateProjectAction(projectData) {
  return dispatch => projectService
    .updateProject(projectData)
    .then((res) => {
      if (res.data !== undefined) {
        const { failedRecords, isNextAdhocDropScheduled } = res.data;
        dispatch({ type: actionType.SAVE_SUCCESS, data: '' });
        return { failedRecords, isNextAdhocDropScheduled };
      }
      return res;
    })
    .catch({});
}

// action method for title exist in project
export const checkProjectTitleAction = (projectName, projectId, portfolioId) => projectService
  .checkTitleExistence({ title: projectName, id: projectId, portfolioId })
  .then(res => res.data)
  .catch({});

// get open text responses
export const getOpenTextResponses = openTextRightPanel => () => projectService
  .getOpenTexts(openTextRightPanel)
  .then(res => res.data)
  .catch({});

// get open texts drop responses
export const getOpenTextDropResponses = openTextDropQuesRightPanel => () => projectService
  .getOpenTextsDropResp(openTextDropQuesRightPanel)
  .then(res => res.data)
  .catch({});

export const getOptionalCommentResponses = optionalCommentRightPanel => () => projectService
  .getOptionalCommentTexts(optionalCommentRightPanel)
  .then(res => res.data)
  .catch({});

export const getCustomQuestionResponses = optionalCommentRightPanel => () => projectService
  .getCustomQuestionsData(optionalCommentRightPanel)
  .then(res => res.data)
  .catch({});

// get open text non match responses
export const getOpenTextNonMatchResponses = openTextRightPanel => () => projectService
  .getNonMatchOpenTexts(openTextRightPanel)
  .then(res => res.data)
  .catch({});

// Update project details to reducer
export function updateProjectDetails(data) {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_DETAILS, data });
}

// Show project loading spinner
export function showLoadingSpinner() {
  return dispatch => dispatch({ type: actionType.SHOW_PROJECT_LOADING_SPINNER });
}

// Hide project loading spinner
export function hideLoadingSpinner() {
  return dispatch => dispatch({ type: actionType.HIDE_PROJECT_LOADING_SPINNER });
}

// change project save state
export function updateProjectSaveState() {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_SAVE_STATE });
}

// action method to get project dashboard details
// this method is deprecated and will be removed by sprint 70
export const getProjectDashboardDetails = projectId => () => projectService
  .getProjectDashboardDetails(projectId)
  .then(res => res.data)
  .catch({});

// save project stage
export const updateProjectStage = projectModel => () => projectService
  .saveProjectStage(projectModel)
  .then(res => res.data)
  .catch({});

// action method for get project details
export function getProjectDetailsAction(projectId, isInEditMode, questionModelId) {
  const projectdata = { projectId, questionModelId };
  return (dispatch, getState) => {
    const { portfolio } = getState();
    const { projectRightPanel } = portfolio.portfolioData;
    return projectService
      .getProjectDetails(projectdata)
      .then((res) => {
        dispatch({
          type: actionType.GET_PROJECT_DETAILS,
          data: res.data,
          isInEditMode,
          projectRightPanel,
        });
        return res.data;
      })
      .catch(error => error);
  };
}

// action method for get project details
export function getProjectDomainsAction(projectId, questionModelId) {
  return dispatch => projectService
    .getProjectDomains(projectId, questionModelId)
    .then((res) => {
      dispatch({
        type: actionType.GET_PROJECT_DOMAINS,
        data: res.data,
      });
      return res.data;
    })
    .catch(error => error);
}

// Hide project loading spinner
export function checkUncheckDomain(domain) {
  return dispatch => dispatch({ type: actionType.CHECK_UNCHECK_DOMAIN, domain });
}

export function updateFormState(values) {
  return dispatch => dispatch({ type: actionType.UPDATE_FORM_STATE, values });
}

export function updateProjectAdmins(values) {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_ADMINS, values });
}

export function updateProjectViewers(values) {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_VIEWERS, values });
}

export function projectAssociatedStakeholdersCount(data) {
  return dispatch => dispatch({ type: actionType.ASSOCIATED_PROJECT_STAKEHOLDERS, data });
}

export function projectActiveState(data) {
  return dispatch => dispatch({ type: actionType.PROJECT_STATE, data });
}

export function emptyPreSelectedNewPortfolioId() {
  return dispatch => dispatch({ type: portfolioActionType.EMPTY_PRE_SELECTED_NEW_PORTFOLIO_ID });
}

// delete Project
export const deleteProject = projectId => () => projectService
  .deleteProject(projectId)
  .then(res => res.data)
  .catch({});

// Get Portfolio Admins When user changes selected portfolio
export const getSelectedPortfolioAdmins = selectedPortfolioId => dispatch => projectService
  .getSelectedPortfolioAdmins(selectedPortfolioId)
  .then((res) => {
    dispatch({
      type: actionType.ADD_PORTFOLIO_ADMINS_AS_PROJECT_ADMINS,
      data: res.data.portfolioAdmins,
    });
    dispatch({
      type: actionType.UPDATE_NEW_SELECTED_PORTFOLIO_ID,
      data: selectedPortfolioId,
    });
    return res;
  })
  .catch({});


// Stakeholders
export const getProjectStakeholders = projectData => projectService
  .getProjectStakeholders(projectData)
  .then(res => (res.data ? res.data : {}))
  .catch({});

export const getFilterGroupStakeholderCounts = projectData => projectService
  .getFilterStakeholdersCount(projectData)
  .then(res => (res.data ? res.data : {}))
  .catch({});

// Stakeholders

export function addProjectStakeholder(projectData) {
  return dispatch => projectService
    .addProjectStakeholder(projectData)
    .then((res) => {
      if (res.data !== undefined) {
        const id = res.data;
        dispatch({ type: actionType.SAVE_SUCCESS, data: id });
      }
      return res.data;
    })
    .catch({});
}

export function pauseOrResumeStakeholder(projectData) {
  return () => projectService
    .pauseOrResumeStakeholder(projectData)
    .then(res => res.data)
    .catch({});
}

export function resendQuesitonsEmailToStakeholder(projectData) {
  let timer;
  return dispatch => projectService
    .resendQuestionsEmailToStakeholder(projectData)
    .then((res) => {
      if (res.data !== undefined) {
        clearTimeout(timer);
        dispatch({
          type: actionType.RESENT_QUESTIONS_EMAIL_SUCCESS,
          data: {
            projectData,
          },
        });
        timer = setTimeout(() => {
          dispatch({ type: actionType.RESENT_QUESTIONS_EMAIL_TIMEOUT, data: null });
        }, 5000);
      }
      return res.data;
    })
    .catch({});
}
export function closeResentQuestionsAlert() {
  return dispatch => dispatch({ type: actionType.RESENT_QUESTIONS_EMAIL_TIMEOUT, data: null });
}
export function launchProjectToFirstQuestionCycle(projectData) {
  return () => projectService
    .launchProjectToFirstQuestionCycle(projectData)
    .then(result => result)
    .catch({});
}

export function testedQuestionUserFirstCycle(projectData) {
  return () => projectService
    .testedQuestionUserCycle(projectData)
    .then(result => result)
    .catch({});
}

export function cancelNextAdhocDrop(projectData) {
  return () => projectService
    .cancelNextAdhocDrop(projectData)
    .then(result => result)
    .catch({});
}

export function sendAgedQuestionReminderNotification(projectData) {
  return () => projectService
    .sendAgedQuestionReminderNotification(projectData)
    .then(result => result)
    .catch({});
}

export function getOpenTextQuestions(data) {
  return dispatch => projectService
    .getOpenTextQuestionsList(data)
    .then((res) => {
      const questions = {
        questionsList: [],
        count: 0,
        selectQuestionCount: 0,
      };
      questions.count = res.data.count;
      questions.selectQuestionCount = res.data.count;
      res.data.questions.map((item) => {
        const questionList = item;
        questionList.isChecked = false;
        questions.questionsList.push(questionList);
        return item;
      });
      dispatch({ type: actionType.GET_OPEN_TEXT_QUESTIONS_LIST, questions });
      dispatch({ type: actionType.OPEN_TEXT_QUESTIONS_FOR_FILTER, questions });
      return res;
    })
    .catch(error => error);
}

export const getOpenTextQuestionsInEditMode = openTextQuestions => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_OPEN_TYPE_QUESTIONS_EDIT_MODE, openTextQuestions });
};

export const toggleAllCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_OPEN_TYPE_QUESTIONS_CHECKBOXES });
};

export const toggleCheckbox = question => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_OPEN_TYPE_CHECKBOX, question });
};
export function updateProjectOpenTextQuestions(values) {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_OPEN_TEXT_QUESTIONS, values });
}

export function searchQuestions(searchQuery) {
  return dispatch => new Promise((resolve) => {
    dispatch({ type: actionType.SEARCH_OPEN_TEXT_QUESTION, searchQuery });
    resolve(true);
  });
}

export const deleteOpenTextQuestion = id => (dispatch) => {
  dispatch({ type: actionType.DELETE_OPEN_TEXT_QUESTION, id });
};

export function addCustomQuestion(customQuestionModel) {
  return dispatch => dispatch({ type: actionType.ADD_CUSTOM_QUESTION, customQuestionModel });
}

export function updateCustomQuestion(customQuestionModel) {
  return dispatch => dispatch({ type: actionType.UPDATE_CUSTOM_QUESTION, customQuestionModel });
}

export function updatePendingCustomQuestion(customQuestionModel) {
  return dispatch => dispatch({
    type: actionType.UPDATE_PENDING_CUSTOM_QUESTION,
    data: customQuestionModel,
  });
}

export function deleteCustomQuestion(index, filterGroupId) {
  return dispatch => dispatch({
    type: actionType.DELETE_CUSTOM_QUESTION,
    data: {
      index, filterGroupId,
    },
  });
}

export function emptyCustomQuestionBasket() {
  return dispatch => dispatch({ type: actionType.EMPTY_CUSTOM_QUESTION_BASKET });
}

export function showCustomQuestionRightPanel(data, disableSendBtn) {
  return dispatch => dispatch(
    { type: actionType.SHOW_CUSTOM_QUESTION_RIGHTPANEL, data: { data, disableSendBtn } },
  );
}
export function hideCustomQuestionRightPanel() {
  return dispatch => dispatch({ type: actionType.HIDE_CUSTOM_QUESTION_RIGHTPANEL });
}

// action method to get automated Questions
export function getAutomatedQuestions(projectId, data) {
  return dispatch => projectService
    .getAutomatedQuestions(projectId)
    .then((res) => {
      const questionsData = res.data;
      if (questionsData.questionsData.length > 0) {
        dispatch({ type: actionType.AUTOMATED_QUESTION_DATA, questionsData, data });
      } else {
        const domainattribute = `${projectId.domainId},${projectId.attributeId}`;
        dispatch({ type: actionType.DISABLE_DOMAIN_ATTRIBUTE, domainattribute });
      }
    })
    .catch({});
}

export function swapAutomatedCustomQuestion(value) {
  return dispatch => projectService
    .swapAutomatedCustomQuestion(value)
    .then((res) => {
      const questionData = res.data;
      dispatch({ type: actionType.SWAP_AUTOMATED_QUESTION_DATA, questionData, value });
    })
    .catch({});
}

// save custom Questions action
export function sendCustomQuestionsData(customQuestionsData, emptyBasket) {
  return dispatch => projectService
    .sendCustomQuestionData(customQuestionsData)
    .then((result) => {
      if (emptyBasket) {
        dispatch({ type: actionType.EMPTY_CUSTOM_QUESTION_BASKET });
      }
      return result;
    })
    .catch({});
}
export function deleteAutoGenratedQuestion(questionId, filterGroupId) {
  return dispatch => dispatch({
    type: actionType.DELETE_AUTOMATED_QUESTION_DATA,
    data: {
      questionId, filterGroupId,
    },
  });
}

// save custom Questions action
export function saveOpenTextTypeQuestionsForTopic(projectOpenTextQuestions, ProjectId) {
  return () => projectService
    .saveOpenTextTypeQuestionsForTopic({ projectOpenTextQuestions, ProjectId })
    .then(res => res.data)
    .catch({});
}

export function getFutureDropDate(lastDropDate, analysisPeriod) {
  let currentDate = lastDropDate;
  let remainingDays = analysisPeriod;
  const SUNDAY = 0;
  const SATURDAY = 6;

  const isWeekend = (day) => {
    const dayIndex = moment(day).weekday();
    if (dayIndex === SUNDAY || dayIndex === SATURDAY) {
      return true;
    }
    return false;
  };

  while (remainingDays) {
    if (!isWeekend(currentDate)) {
      remainingDays -= 1;
    }
    currentDate = moment(currentDate).add(1, 'days');
  }

  const finalIndex = moment(currentDate).weekday();
  if (finalIndex === SUNDAY) {
    currentDate = moment(currentDate).add(1, 'days');
  }
  if (finalIndex === SATURDAY) {
    currentDate = moment(currentDate).add(2, 'days');
  }

  return moment(currentDate);
}

// open domain focus panel
export function openDomainFocusPanel(data) {
  return dispatch => dispatch({ type: actionType.OPEN_DOMAIN_FOCUS_PANEL, data });
}

// show open text panel
export function showOpenTextPanel(data) {
  return dispatch => dispatch({ type: actionType.OPEN_TEXT_PANEL, data });
}

// Show Unsaved Changes Modal
export function enableUnsavedChangesModalFocusDomain() {
  return dispatch => dispatch({
    type: actionType.ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN,
  });
}
// Hide Unsaved Changes Modal
export function disableUnsavedChangesModalFocusDomain() {
  return dispatch => dispatch({
    type: actionType.DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN,
  });
}

// Enable Unsaved Changes
export function enableUnsavedChangesFocusDomain() {
  return dispatch => dispatch({ type: actionType.ENABLE_UNSAVED_CHANGES_FOCUS_DOMAIN });
}
// Disable Unsaved Changes
export function disableUnsavedChangesFocusDomain() {
  return dispatch => dispatch({ type: actionType.DISABLE_UNSAVED_CHANGES_FOCUS_DOMAIN });
}
// Show Unsaved Changes custom domain Modal
export function enableUnsavedChangesModalForCustomDomain() {
  return dispatch => dispatch({
    type: actionType.ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN,
  });
}
// Hide Unsaved Changes Modal
export function disableUnsavedChangesModalForCustomDomain() {
  return dispatch => dispatch({
    type: actionType.DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN,
  });
}

// Enable Unsaved Changes
export function enableUnsavedChangesForCustomDomain() {
  return dispatch => dispatch({ type: actionType.ENABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN });
}
// Disable Unsaved Changes
export function disableUnsavedChangesForCustomDomain() {
  return dispatch => dispatch({ type: actionType.DISABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN });
}
// add new custom domain to topic
export function addNewCustomDomainToTopic() {
  return (dispatch) => {
    dispatch({ type: actionType.ADD_CUSTOM_DOMAINS_TO_TOPIC });
    this.enableUnsavedChangesForCustomDomain();
  };
}
// add new custom domain to topic
export function updateDomainsToTopic(domainData) {
  return dispatch => dispatch({ type: actionType.UPDATE_DOMAINS_TO_TOPIC, data: domainData });
}
// save topic domain
export function saveTopicDomainAction(projectData) {
  return () => settingsService.insertCustomDomain(projectData)
    .then()
    .catch({});
}
// update custom question data
export function updateCustomQuestionsData(customQuestionsData) {
  return () => projectService
    .updateCustomQuestionData(customQuestionsData)
    .then(result => result)
    .catch({});
}
// soft delete custom question from table
export function deleteCustomQuestionsData(customQuestionsData) {
  return () => projectService
    .deleteCustomQuestionData(customQuestionsData)
    .then(result => result)
    .catch({});
}
// hard delete segement from table
export function deleteSegementsData(deleteSegements) {
  return () => projectService
    .deleteSegementData(deleteSegements)
    .then(result => result)
    .catch({});
}
// get open text non match responses
export const checkIfDropShouldRunImmediately = projectData => () => projectService
  .checkIfDropShouldRunImmediately(projectData)
  .then(res => res.data)
  .catch({});

// delete domain for previous project
export function deleteRelevantDomains() {
  return dispatch => dispatch({ type: actionType.DELETE_RELEVANT_DOMAINS });
}

export const deleteCustomDomain = data => () => projectService
  .deleteCustomDomain(data)
  .then(res => res.data)
  .catch({});
export function showRecordProcessing(passedRecords, failedRecords) {
  const data = {
    passedRecords,
    failedRecords,
  };
  return dispatch => dispatch({ type: recordProcessingActionType.SHOW_PROCESSING_RECORDS, data });
}
export function hideRecordProcessing() {
  return dispatch => dispatch({ type: recordProcessingActionType.HIDE_PROCESSING_RECORDS });
}
export function showProcessing() {
  return dispatch => dispatch({ type: recordProcessingActionType.SHOW_PROCESSING });
}
export function hideProcessing() {
  return dispatch => dispatch({ type: recordProcessingActionType.HIDE_PROCESSING });
}

export function updateLaterQuestionSaveMessage(message) {
  return dispatch => dispatch({ type: actionType.UPDATE_LATER_QUESTION_SAVE_MESSAGE, message });
}

export function isShowingFilterSuccessMesage(isSave) {
  return dispatch => dispatch({ type: actionType.FILTER_GROUP_SUCCESS_MESSAGE, data: isSave });
}

export const createNewSegment = data => () => projectService
  .createNewSegment(data)
  .then(res => res.data)
  .catch({});

export const checkFiltersExistsInAlreadyCreatedSegment = data => () => projectService
  .checkFiltersExistsInAlreadyCreatedSegment(data)
  .then(res => res.data)
  .catch({});

export const generateReporting = projectData => projectService
  .generateReportingPdf(projectData)
  .then(res => (res.data ? res.data : {}))
  .catch({});

export const generateExcelReport = projectData => projectService
  .generateExcelReport(projectData)
  .then(res => (res.data ? res.data : {}))
  .catch({});

export const getUserSegmentFiltersData = (
  projectId,
  portfolioId,
  selectedSegmentValue,
  dates,
) => () => projectService
  .getUserSegmentFiltersData(projectId, portfolioId, selectedSegmentValue, dates)
  .then(res => res.data)
  .catch({});

export function getProjectSegments(
  projectId,
  portfolioId,
  customDatesData,
) {
  return dispatch => projectService
    .getProjectSegments(projectId, portfolioId, customDatesData)
    .then((res) => {
      dispatch({
        type: actionType.ADD_PROJECT_SEGMENTS,
        data: { segments: sortArrayValues(res.data, SORT_SEGMENT_ARRAY), projectId },
      });
      return res;
    })
    .catch({});
}

export function toggleProjectSegmentDrowdown(data) {
  return dispatch => dispatch({ type: actionType.TOGGLE_PROJECT_SEGMENT_DROPDOWN, data });
}

export const assignSegmentsToDomain = (
  projectId,
  domainId,
  segments,
) => () => projectService
  .assignSegmentsToDomain(projectId, domainId, segments)
  .then(res => res.data)
  .catch({});

// Update project stakeholder state to launch project
export function updateProjectStakeholderState(data) {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_STAKEHOLDERS_STATE, data });
}

// Update project id to disable project crash due to segment dropdown on topic change
export function updateCurrentlySelectedProjectId(data) {
  return dispatch => dispatch({ type: actionType.UPDATE_CURRENTLY_SELECTED_PROJECT_ID, data });
}

export const getProjectDashboardToolbarDetails = projectId => () => projectService
  .getProjectDashboardToolbarDetails(projectId)
  .then(res => res.data)
  .catch({});

export const setCustomQuestionsBasket = (customQuestionsBasket) => {
  localStorage.setItem('setPendingCustomQuestions', JSON.stringify(customQuestionsBasket));
};

export const getCustomQuestionsBasket = () => localStorage.getItem('setPendingCustomQuestions');

export const removePendingCustomQuestionData = () => localStorage.removeItem('setPendingCustomQuestions');
export function updateProjectDraftedStatus(data) {
  return dispatch => dispatch({ type: actionType.UPDATE_PROJECT_DRAFTED_STATUS, data });
}

export const getSegmentGroupStakeholderCounts = projectData => () => projectService
  .getSegmentsStakeholderCounts(projectData)
  .then(res => res.data)
  .catch({});

export const getCustomQuestionSegments = projectData => () => projectService
  .getCustomQuestionSegments(projectData)
  .then(res => res.data)
  .catch({});

export function updateQuestionSentToFutureDrop(data) {
  return dispatch => dispatch({ type: actionType.UPDATE_QUESTION_SENT_TO_FUTURE_DROP, data });
}

export function saveProjectOverAllWidget(data) {
  return dispatch => dispatch({ type: actionType.SAVE_OVER_ALL_WIDGET, data });
}

export function saveSegmentGroupDashboard(data) {
  return dispatch => dispatch({ type: actionType.SAVE_SEGMENT_GROUP_DASHBOARD, data });
}

export function saveSegmentGroupFormValues(data) {
  return dispatch => dispatch({ type: actionType.SAVE_SEGMENT_GROUP_VALUE, data });
}


export function saveSegmentOverall(data) {
  return dispatch => dispatch({ type: actionType.SAVE_SEGMENT_OVERALL, data });
}

export function saveSegmentAlreadyCreated(data) {
  return dispatch => dispatch({ type: actionType.SAVE_SEGMENT_ALREADY_CREATED, data });
}

export function saveSelectedSegmentValue(data) {
  return dispatch => dispatch({ type: actionType.SELECTED_SEGMENT, data });
}

export function userSelectedfilterGroupValuesUpdate(data) {
  return dispatch => dispatch({ type: actionType.UPDATE_USER_SELECTED_FILTERGROUPVALUES, data });
}

export function userSelectedfilterGroupValuesClear(data) {
  return dispatch => dispatch({ type: actionType.CLEAR_USER_SELECTED_FILTERGROUPVALUES, data });
}

export function showRedBanner() {
  return dispatch => dispatch({ type: notificationActionType.SHOW_NOTIFICATION });
}

export function hideRedBanner() {
  return dispatch => dispatch({ type: notificationActionType.HIDE_NOTIFICATION });
}

export function openCloseQuestionUrlLinkAlert(projectData) {
  return (dispatch) => {
    let timer;
    clearTimeout(timer);
    dispatch({ type: actionType.QUESTION_URL_COPIED, data: { projectData } });
    timer = setTimeout(() => {
      dispatch({ type: actionType.QUESTION_URL_COPIED_TIMEOUT, data: null });
    }, 5000);
  };
}

export function closeQuestionUrlLinkAlert() {
  return dispatch => dispatch({ type: actionType.QUESTION_URL_COPIED_TIMEOUT, data: null });
}
// Update Stackholder List
export function updateProjectStackHoldersList(stakeholders) {
  return dispatch => dispatch({
    type: actionType.UPDATE_PROJECT_STAKEHOLDERS_LIST,
    stakeholders,
  });
}

// Update Error Stackholder List
export function uploadImportStackholderErrorRecords(stakeholders) {
  return dispatch => dispatch({
    type: actionType.UPLOAD_IMPORT_STACKHOLDER_ERROR_RECORDS,
    stakeholders,
  });
}

export function updateImportStackHolderFlag(importStackholdersFlag) {
  return dispatch => dispatch({
    type: actionType.UPDATE_IMPORT_STACKHOLDER_FLAG,
    importStackholdersFlag,
  });
}

export function projectAdhocDropDate(data) {
  return dispatch => dispatch({ type: actionType.PROJECT_ADHOC_DATE, data });
}
export function isChecking(data) {
  return dispatch => dispatch({ type: actionType.SEND_VALUE_DATA, data });
}

export function projectUpdatedData(data) {
  return dispatch => dispatch({ type: actionType.PROJECT_UPDATED_DATA, data });
}

export const submitUserSuggestionFeedback = formData => projectService
  .submitUserSuggestionFeedback(formData)
  .then(res => (res.data ? res.data : {}));

export const getProjectPreviewPaneDetails = (portfolioId, projectId) => () => projectService
  .getProjectPreviewPaneDetails(portfolioId, projectId)
  .then(res => res.data)
  .catch({});

  export const submitUserRequestSupportFeedback = formData => projectService
    .submitUserRequestSupportFeedback(formData)
    .then(res => (res.data ? res.data : {}));
