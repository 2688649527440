import React, { Component } from 'react';
import 'appkit/sass/appkit.scss';
import $ from 'jquery';
import moment from 'moment';
import constant from '../../modules/project/actions/constant';

function getPastDate(date, analysisPeriod) {
  let currentDate = date;
  let remainingDays = analysisPeriod;
  const SUNDAY = 0;
  const SATURDAY = 6;

  const isWeekend = (day) => {
    const dayIndex = moment(day).weekday();
    if (dayIndex === SUNDAY || dayIndex === SATURDAY) {
      return true;
    }
    return false;
  };

  while (remainingDays) {
    if (!isWeekend(currentDate)) {
      remainingDays -= 1;
    }
    currentDate = moment(currentDate).subtract(1, 'days');
  }

  const finalIndex = moment(currentDate).weekday();
  if (finalIndex === SUNDAY) {
    currentDate = moment(currentDate).subtract(2, 'days');
  }
  if (finalIndex === SATURDAY) {
    currentDate = moment(currentDate).subtract(1, 'days');
  }

  return moment(currentDate).format();
}
function datesFromData(data, unit) {
  const dates = [];
  const today = new Date();

  if (unit === 'd') {
    data.forEach((item) => {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + parseInt(item, 10),
      );
      dates.push(date);
    });
  } else if (unit === 'm') {
    data.forEach((item) => {
      let date = new Date(
        today.getFullYear(),
        today.getMonth() + parseInt(item, 10),
        1,
      );
      dates.push(date);
      date = new Date(
        today.getFullYear(),
        today.getMonth() + parseInt(item, 10) + 1,
        0,
      );
      dates.push(date);
      dates.sort((a, b) => a - b);
      dates.splice(1, dates.length - 2);
    });
  }
  return dates;
}

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: '',
      fromCustomRange: false,
      isProjectLoading: false,
      initialRendering: true,
    };
  }

  componentWillUnmount() {
  }

  componentWillMount() {
    this.setState({
      isProjectLoading: true,
    });
  }

  componentDidMount() {
    const cd = this.customDatePicker();
    if (this.props.isProjectLoading) {
      const customDates = [this.currentPeriodDates(), '0'];
      const dates = datesFromData(customDates, 'd');
      cd.clear();
      cd.selectDate(dates);
    }

    $('.custom-demo').val('Current Period');

    $('#custom-toolbar li').on('click', async (e) => {
      const startDate = this.currentPeriodDates();
      const target = $(e.target);
      this.setState({ fromCustomRange: true });
      const data = target
        .attr('data-select')
        .replace(/\s/g, '')
        .split(',');
      const unit = data.pop();
      if (data.length === 1) {
        data.push(data[0]);
      }
      let customDateRange = [];
      if (data[0] === '-1' && data[1] === '-1' && unit === 'd') {
        customDateRange = await this.lastPeriodDates();
        this.setState({ range: constant.LastPeriodRange });
      } else if (parseInt(data[0], 10) === startDate && data[1] === '0') {
        customDateRange = [startDate, 0];
        this.setState({ range: constant.CurrentPeriodRange });
      } else if (data[0] === '-6' && data[1] === '0') {
        this.setState({ range: constant.Last7Days });
      } else if (data[0] === '-13' && data[1] === '0') {
        this.setState({ range: constant.Last14Days });
      } else if (data[0] === '-30' && data[1] === '0' && unit === 'd') {
        this.setState({ range: constant.LastMonth });
      } else if (data[0] === '-90' && data[1] === '0') {
        this.setState({ range: constant.Last3Month });
      } else {
        this.setState({ range: '' });
      }
      const newDates = customDateRange.length > 0 ? customDateRange : data;
      const dates = await datesFromData(newDates, unit);
      cd.clear();
      cd.selectDate(dates);
      if (target.hasClass('active')) {
        return;
      }
      target.siblings('li').removeClass('active');
      target.addClass('active');
    });
  }

  currentPeriodDates() {
    const startDate = moment();
    const endDate = moment(this.props.currentPeriodDate);
    const days = endDate.diff(startDate, 'days');
    return days;
  }

  async lastPeriodDates() {
    const { perioLength } = this.props;
    const currentDate = moment();
    const endDate = moment(this.props.currentPeriodDate).subtract(1, 'days');
    const startDate = await getPastDate(this.props.currentPeriodDate, (perioLength / 2));
    const endDateCount = endDate.diff(currentDate, 'days');
    const startDateCount = moment(startDate).diff(currentDate, 'days');
    return [startDateCount, endDateCount];
  }

  componentDidUpdate() {
    if (this.props.isResetToCurrentPeriod) {
      const datePickerData = this.customDatePicker();
      const customDates = [this.currentPeriodDates(), '0'];
      const dates = datesFromData(customDates, 'd');
      datePickerData.clear();
      datePickerData.selectDate(dates);
      $('.custom-demo').val('Current Period');
    }
  }

  customDatePicker() {
    const that = this;
    const cd = $('.custom-demo')
      .datepicker({
        type: 'single',
        range: true,
        navTitles: {
          days: 'MM yyyy',
        },
        firstDay: 1,
        titleLeft: true,
        classes: 'w-606 a-datepicker a-title-left a-single a-lg',
        view: 'days',
        panelTemplate: $('#custom-toolbar'),
        dateFormat: 'dd M',
        onSelect(formattedDate, selectedDates, inst) {
          const customDates = [];
          const lis = inst.$datepicker
            .find('.menu li')
            .removeClass('active');
          if (selectedDates.length < 1 || (selectedDates.length < 2 && inst.opts.range)) {
            // eslint-disable-next-line no-undef
            return;
          }
          lis.each((ix, el) => {
            el = $(el);
            const data = el
              .attr('data-select')
              .replace(/\s/g, '')
              .split(',');
            const unit = data.pop();
            const dates = datesFromData(data, unit);
            let identical = true;
            if (Object.prototype.toString.call(selectedDates) === '[object Date]' && dates[0] - selectedDates === 0) {
              el.addClass('active');
              return;
            }
            if (dates.length !== selectedDates.length) {
              return;
            }

            dates.forEach(
              (item, index) => {
                if (item.getFullYear() - selectedDates[index].getFullYear() !== 0
                  || item.getMonth() - selectedDates[index].getMonth() !== 0
                  || item.getDate() - selectedDates[index].getDate() !== 0) {
                  identical = false;
                }
              },
            );
            if (identical) {
              el.addClass('active');
            }

            selectedDates.forEach((item) => {
              item = moment(item).format('YYYY-MM-DD HH:mm:ss');
              customDates.push(item);
              return item;
            });
          });
          if (!that.props.isResetToCurrentPeriod) {
            if (!that.state.fromCustomRange) {
              that.setState({ range: '' });
            }
            if (that.state.range === constant.CurrentPeriodRange) {
              $('.custom-demo').val(constant.CurrentPeriodRange);
            }
            if (that.state.range === constant.LastPeriodRange) {
              $('.custom-demo').val(constant.LastPeriodRange);
            }
            if (that.state.range === constant.Last7Days) {
              $('.custom-demo').val(constant.Last7Days);
            }
            if (that.state.range === constant.Last14Days) {
              $('.custom-demo').val(constant.Last14Days);
            }
            if (that.state.range === constant.LastMonth) {
              $('.custom-demo').val(constant.LastMonth);
            }
            if (that.state.range === constant.Last3Month) {
              $('.custom-demo').val(constant.Last3Month);
            }
            if (!that.state.isProjectLoading) {
              that.onCustomDatePicked(customDates, that.state.range);
            }
            if (that.props.isProjectLoading) {
              that.setState({ isProjectLoading: false });
            }
          }
        },
        multipleDatesSeparator: ' to ',
      })
      .data('datepicker');

    return cd;
  }

  onCustomDatePicked(dates, range) {
    this.setState({ fromCustomRange: false });
    this.props.onCustomDatePicked(dates, range);
  }

  toggleDatePicker = () => {
    if (this.state.initialRendering) {
      this.setState({ initialRendering: false });
      return;
    }
    if ($('.datepicker').hasClass('active')) {
      $('.datepicker').toggleClass('active');
    } else {
      $('.datepicker').toggleClass('active');
    }
  }

  render() {
    return (
      <div class="custom-datepicker-wrapper">
        <div class="menu-box text-left float-left" id="custom-toolbar">
          <div class="menu-header">
            <span class="title text-uppercase">date range</span>
          </div>
          <div class="menu-body">
            <ul class="menu">
              <li data-select={`${this.currentPeriodDates()},0,d`}>Current Period</li>
              <li data-select="-1, -1, d">Last Period</li>
              <li data-select="-6, 0, d">Last 7 days</li>
              <li data-select="-13, 0, d">Last 14 days</li>
              <li data-select="-30,0, d">Last Month</li>
              <li data-select="-90,0, d">Last 3 Months</li>
            </ul>
          </div>
        </div>
        <div class="  a-input-with-icon a-datepicker-input a-rt mt-3 mb-2">
          <input class="a-text-input d-inline-block custom-demo" data-classes="w-606 a-datepicker a-title-left a-single air-picker a-lg" readonly="" tabindex="0"
          onClick={() => this.toggleDatePicker()}
          />
          <div class="a-icon" data-bs-toggle="datepicker">
            <span class="appkiticon icon-calendar-outline d-inline-flex"></span>
          </div>
        </div>
      </div>

    );
  }
}


export default CustomDatePicker;
