/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import '../styles/projects.scss';
import ReactSvgLineChart from 'react-svg-line-chart';
import MaterialIcon from 'material-icons-react';
import $ from 'jquery';
import '../../../../assets/js/sidebar';
import Cryptr from 'cryptr';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import 'appkit/js/Datepicker';
import {
  Formik, Field, ErrorMessage, Form, FieldArray,
} from 'formik';
import Authorise from '../../../Authorise';
import SumoSelect from '../../../ui-components/SumoSelect/SumoSelect';
import CustomSelectDropDown from '../../../ui-components/CustomSelectDropDown/CustomSelectDropDown';
import SuccessAlertWithTimer from '../../../ui-components/SuccessAlertWithTimer';
import ReportSuccess from '../../../ui-components/ReportSuccess';
import DeleteConfirmation from '../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import ProjectLaunchConfirmation from '../../../ui-components/ProjectLaunchConfirmation/ProjectLaunchConfirmation.jsx';
import AgedQuestionReminderConfirmation from '../../../ui-components/AgedQuestionReminder/AgedQuestionReminderConfirmation.jsx';
import SkeltonLoadingDropDown from '../../../ui-components/CustomSelectDropDown/SkeltonLoadingDropDown';
import 'moment-business-days';
import { levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import {
  showProjectRightPanel, hideProjectRightPanel,
  showProjectStakeholderRightPanel,
  showOpenTextRightPanel,
  changeSelectedPortfolioAndProject, showOpenTextNonMatchRightPanel,
  getPortfolioQuestionModelList,
  hideOptionalCommentRightPanel,
  showOptionalCommentRightPanel,
  showCustomQuestionsDetailRightPanel,
  getPortfoliosData,
  showOpenTextDropQuesRightPanel,
} from '../../portfolio/actions/portfolio';
import {
  isAnyPortfolioExist,
  getSelectedPortfolio,
  getSelectedProject,
} from '../../portfolio/selectors/portfolio';
import {
  getProjectDashboardToolbarDetails,
  updateProjectStage,
  updateProjectSaveState,
  getUserFilterGroupsForProject,
  getUserTagFiltersData,
  showCustomQuestionRightPanel,
  getAutomatedQuestions,
  emptyCustomQuestionBasket,
  sendCustomQuestionsData,
  saveOpenTextTypeQuestionsForTopic,
  getProjectDomainsAction,
  submitProjectDomains,
  checkUncheckDomain,
  openDomainFocusPanel,
  showOpenTextPanel,
  disableUnsavedChangesModalFocusDomain,
  disableUnsavedChangesFocusDomain,
  enableUnsavedChangesFocusDomain,
  enableUnsavedChangesModalFocusDomain,
  enableUnsavedChangesForCustomDomain,
  enableUnsavedChangesModalForCustomDomain,
  disableUnsavedChangesForCustomDomain,
  disableUnsavedChangesModalForCustomDomain,
  addNewCustomDomainToTopic,
  updateDomainsToTopic,
  saveTopicDomainAction,
  deleteRelevantDomains,
  deleteCustomDomain,
  getProjectStakeholders,
  projectAssociatedStakeholdersCount,
  getFilterGroupStakeholderCounts,
  createNewSegment,
  saveSegmentAlreadyCreated,
  getProjectSegments,
  getUserSegmentFiltersData,
  assignSegmentsToDomain,
  toggleProjectSegmentDrowdown,
  launchProjectToFirstQuestionCycle,
  cancelNextAdhocDrop,
  updateProjectStakeholderState,
  updateCurrentlySelectedProjectId,
  getCustomQuestionsBasket,
  removePendingCustomQuestionData,
  setCustomQuestionsBasket,
  updatePendingCustomQuestion,
  updateProjectDraftedStatus,
  getSegmentGroupStakeholderCounts,
  updateQuestionSentToFutureDrop,
  saveProjectOverAllWidget,
  saveSegmentOverall,
  saveSelectedSegmentValue,
  saveSegmentGroupDashboard,
  saveSegmentGroupFormValues,
  sendAgedQuestionReminderNotification,
  closeResentQuestionsAlert,
  getProjectDetailsAction,
  userSelectedfilterGroupValuesClear,
  userSelectedfilterGroupValuesUpdate,
  showRedBanner,
  hideRedBanner,
  closeQuestionUrlLinkAlert,
  projectActiveState,
  generateReporting,
  projectAdhocDropDate,
  projectUpdatedData,
  deleteSegementsData,
  isChecking,
  generateExcelReport,
} from '../actions/project';
import { SpinnerButton, Spinner } from '../../../ui-components/Loader';
import AddCustomQuestion from '../../../ui-components/CustomQuestion/AddCustomQuestion.jsx';
import RenderIf from '../../../ui-components/Common';

import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import constant from '../actions/constant';
import '../../../../assets/js/delta-scroll';
import ProjectOpenTextQuestionsPanel from '../components/ProjectOpenTextQuestionsPanel.jsx';
import UnsavedChangesConfirmation from '../../../ui-components/UnsavedChangesConfirmation/UnsavedChangesConfirmation.jsx';
import CustomDatePicker from '../../../ui-components/Datepicker/CustomDatePicker.jsx';
import FailureAlertWithTimer from '../../../ui-components/FailureAlertWithTimer';
import SegmentDropDown from '../../../ui-components/Segment/SegmentDropDown';
import SegmentsGroupToolbar from '../components/SegmentsGroupToolbar.jsx';
import SegmentsGroupResult from '../components/SegmentsGroupResult.jsx';
import {
  checkArrayContainsSomeValues, convertStringLowerCase, debounce,
} from '../../../utils/helper';
import customDomainSvg from '../../../../assets/images/component/svg/custom-domain-edit.svg';
import nextCycleSvg from '../../../../assets/images/component/svg/next-cycle.svg';
import settingIconSvg from '../../../../assets/images/component/svg/setting-icon.svg';
import expandCardsSvg from '../../../../assets/images/component/svg/expand-cards.svg';
import collapseCardsSvg from '../../../../assets/images/component/svg/collapse-cards.svg';
import bellIconSvg from '../../../../assets/images/component/svg/bell-icon.svg';
import customQuestionsIconSvg from '../../../../assets/images/component/svg/custom-questions-icon.svg';
import askCustomQuestionSvg from '../../../../assets/images/component/svg/ask-custom-question.svg';
import InsufficientResultInfoTooltip from '../../../ui-components/InsufficientResultInfo/InsufficientResultInfoTooltip';
import ExcelSvg from './ExcelSvg.jsx';
import PdfSvg from './PdfSvg.jsx';
/*
* Do not remove this import.
* As we are temporary disabling this for
* #Product Backlog Item 10897: Hide the option for autogenerated questions
import askAutoQuestionSvg from '../../../../assets/images/component/svg/ask-auto-question.svg';
*/
import './Projects.css';
import { showHideOverlay } from '../../common/overlay/actions/overlay';
import Feedback from '../../../ui-components/Feedback/Feedback.jsx';
import { TEMPORARY_CONSTANT } from '../../../Constants';
import DeleteAlertWithTimer from '../../../ui-components/DeleteAlertWithTimer';
import ArchiveNotificationBanner from './QuestionPaneComp/ArchiveNotificationBanner';
import projectService from '../../../redux/services/projectService';

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

// dummy blur graph data
const trendBlurData = [
  { x: 1, y: 58 },
  { x: 2, y: 58 },
  { x: 3, y: 73 },
  { x: 4, y: 64 },
  { x: 5, y: 13 },
  { x: 6, y: 24 },
  { x: 7, y: 92 },
];
Yup.addMethod(Yup.array, 'unique', function (message) {
  return this.test('unique', message, function (attributes) {
    attributes = attributes.filter(a => a.title !== '');
    const mapper = x => x.title && x.title;
    const set = [...new Set(attributes.map(mapper))];
    const isUnique = attributes.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = attributes.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `attributes[${idx}].title`,
      message,
    });
  });
});
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStage: '',
      selectedStageId: 0,
      stages: [],
      overallWidget: {},
      isLoaded: false,
      activePoint: [],
      activePosition: null,
      tooltipPosition: 'right',
      showFilters: false,
      isFilteredDataLoaded: false,
      isInsufficientForAllDomains: false,
      isInsufficientForSomeDomains: false,
      numberOfFiltersSelected: 0,
      userSelectedFilterGroupValues: {
        selectedGroupValue1: [],
        selectedGroupValue2: [],
        selectedGroupValue3: [],
        selectedGroupValue4: [],
      },
      insufficientData: false,
      insufficientDataForSomeTopics: false,
      isUserTagsFiltersApplied: false,
      isDateFiltersApplied: false,
      isFilteringProjectByUserTags: false,
      resetingProjectFiltersByUserTags: false,
      nextDropdate: '',
      showAddCustomQuestionModel: false,
      dSegments: [],
      currentAttributeId: 0,
      currentAttributeName: '',
      currentDomainId: 0,
      currentDomainName: '',
      showDeleteModal: false,
      questionModelId: null,
      projectOpenTextQuestions: [],
      openTextTypeQuestionsAddedToTopic: false,
      openTextTypeQuestionsSuccessMessage: null,
      showUnsavedChangesModal: false,
      showCustomDomainUnsavedChangesModal: false,
      customQuestionData: [],
      isDomainLoaded: true,
      isCustomDomainSubmitting: false,
      customAttributes: [],
      selectedid: '',
      slectedtext: '',
      customDomainTitleCharCount: 0,
      customQuestionsAddedToTopic: false,
      customQuestionsSuccessMessage: null,
      customDomainAddedToTopic: false,
      customDomainSuccessMessage: null,
      showCustomAttributeDeleteModal: false,
      showCustomDomainDeleteModal: false,
      isCustomDomain: false,
      setFieldValueRefForCustomDomain: null,
      attributesToBeRemoved: [],
      customDatesData: {
        fromDate: '',
        toDate: '',
        range: '',
      },
      isResetToCurrentPeriod: false,
      currentDropDate: '',
      showDateFilter: false,
      isProjectLoading: true,
      allUserFilterGroupValues: [],
      associatedStakeholders: [],
      associatedStakeholdersWithFilterValue: 0,
      noOfDynamicallySelectedOpenTextQuestions: 0,
      isOpenTextQuestionsDynamicallySelected: false,
      isRemovingFilterGroupsExternally: false,
      reportUrl: null,
      isSegmentSavedSuccessfully: false,
      selectedSegmentValue: '0',
      isSegmentApplied: false,
      isSegmentDropDownLoaded: false,
      areSegmentsAssignedSuccessfully: false,
      segmentsAssigningMessage: null,
      isSentCustomQuestions: false,
      insufficientDataForSomeSegments: false,
      isExpandWidget: false,
      domainWidgetsData: [],
      isShowProjectLaunchModel: false,
      isProjectConfigured: false,
      isProjectLaunchSuccessfully: false,
      currentProjectId: 0,
      nextFullDropDate: '',

      // segment groups related database
      isSegmentGroupApplied: false,
      segmentGroups: {
        segmentGroup1: [],
        segmentGroup2: [],
        segmentGroup3: [],
        segmentGroup4: [],
      },
      segmentGroupDashboardData: {
        segmentGroupData1: null,
        segmentGroupData2: null,
        segmentGroupData3: null,
        segmentGroupData4: null,
      },
      defaultSegmentGroup1: '0',
      segmentGroupDataContainer: {},
      initialSegmentGroupFormValues: {
        segmentGroup1: 0,
        segmentGroup2: -1,
        segmentGroup3: -1,
        segmentGroup4: -1,
      },

      isAutomatedQuestion: false,
      isSendAgedReminderEmailModel: false,
      nextAgedQuestionScheduleDate: '',
      projectDropTimezone: '',
      isSendAgedQuestionMailSuccessfully: false,
      isDropCancelledSuccessfully: false,
      nextAdhocDropDate: '',
      isProjectAdhoc: false,
      AdhocDropOffset: '',
      manualAgedQuestionDate: '',
      isProjectHavingNextAdhocDrop: false,
      isDrafted: false,
      futureDropCustomQuestions: [],
      isReportLoading: false,
      isShowCancelButton: false,
      isShowFeedbackButton: true,
      isShowFeedBackOptions: false,
      isShowRequestSupportForm: false,
      isComingFromBanner: false,
    };
    this.cancelNextDrop = this.cancelNextDrop.bind(this);
    this.showProjectRightPanel = this.showProjectRightPanel.bind(this);
    this.showDomainFocusPanel = this.showDomainFocusPanel.bind(this);
    this.hideDomainFocusPanel = this.hideDomainFocusPanel.bind(this);
    this.showHideUserTagsFilters = this.showHideUserTagsFilters.bind(this);
    this.updateUserTagFilters = this.updateUserTagFilters.bind(this);
    this.updateUserTagFiltersWithDebounce = this.updateUserTagFiltersWithDebounce.bind(this);
    this.resetUserTagsFilters = this.resetUserTagsFilters.bind(this);
    this.showProjectStakeholderRightPanel = this.showProjectStakeholderRightPanel.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.getKeyPhrases = this.getKeyPhrases.bind(this);
    this.setTooltipPosition = this.setTooltipPosition.bind(this);
    this.showCustomQuestionRightPanel = this.showCustomQuestionRightPanel.bind(this);
    this.saveFocusDomains = this.saveFocusDomains.bind(this);
    this.toggleUnsavedChanges = this.toggleUnsavedChanges.bind(this);
    this.createCustomDomain = this.createCustomDomain.bind(this);
    this.validateAndSaveDomain = this.validateAndSaveDomain.bind(this);
    this.enableCustomDomainUnsavedChanges = this.enableCustomDomainUnsavedChanges.bind(this);
    this.renderAttributes = this.renderAttributes.bind(this);
    this.discardUnsavedChanges = this.discardUnsavedChanges.bind(this);
    this.editCustomDomain = this.editCustomDomain.bind(this);
    this.deleteCustomAttribute = this.deleteCustomAttribute.bind(this);
    this.deleteCustomDomain = this.deleteCustomDomain.bind(this);
    this.showNoOfOpenTextQuestions = this.showNoOfOpenTextQuestions.bind(this);
    this.createNewSegment = this.createNewSegment.bind(this);
    this.applySegment = this.applySegment.bind(this);
    this.assignSegmentsToDomain = this.assignSegmentsToDomain.bind(this);
    this.showProjectLaunchModel = this.showProjectLaunchModel.bind(this);
    this.applySegmentGroup = this.applySegmentGroup.bind(this);
    this.exitComparisionView = this.exitComparisionView.bind(this);
    this.applySegmentFilter = this.applySegmentFilter.bind(this);
    this.updateShowUnsavedChangesModal = this.updateShowUnsavedChangesModal.bind(this);
    this.showAgedQuestionReminderEmailModel = this.showAgedQuestionReminderEmailModel.bind(this);
    this.updateStateAfterFilter = this.updateStateAfterFilter.bind(this);
    this.fetchDashBoardProjectData = this.fetchDashBoardProjectData.bind(this);
    this.disableUnableQuestion = this.disableUnableQuestion.bind(this);
    this.showOpenTextDropQuesRightPanel = this.showOpenTextDropQuesRightPanel.bind(this);
  }

  componentWillMount() {
    const { selectedProject } = this.props;
    if (!selectedProject.ProjectId) {
      this.props.push('/');
    }
  }

  componentDidMount() {
    this.fetchDashBoardProjectData();
  }

  fetchDashBoardProjectData() {
    this.props.getPortfoliosData();
    this.props.emptyCustomQuestionBasket();
    this.props.getSystemTerminology();
    this.updateDashBoard();

    const { portfolioId, projectId } = this.props.match.params;

    if (portfolioId && projectId) {
      const decryptedPortfolioString = cryptr.decrypt(portfolioId);
      const encryptedPortfolioId = cryptr.encrypt(decryptedPortfolioString);

      const decryptedProjectString = cryptr.decrypt(projectId);
      const encryptedProjectId = cryptr.encrypt(decryptedProjectString);

      this.props.changeSelectedPortfolioAndProject(
        parseInt(decryptedPortfolioString, 10),
        parseInt(decryptedProjectString, 10),
      );
      this.props.getPortfolioQuestionModelList(parseInt(decryptedPortfolioString, 10));
      const that = this;
      that.navigate(`/project-dashboard/${encryptedPortfolioId}/${encryptedProjectId}`);
      this.getProjectAssociatedStakeHolders(parseInt(decryptedProjectString, 10));
      this.props.getProjectDetailsAction(decryptedProjectString, true);
    }

    $('body').on('mousewheel', '.data-scroller-container', function (event, delta) {
      if (delta === -1) {
        if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
          this.scrollLeft -= (delta * 30);
        }
      } else if (delta === 1) {
        if ($(this).scrollTop() === 0) {
          this.scrollLeft -= (delta * 30);
        }
      }
    });
  }

  navigate(path) {
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  updateDashBoard(isfromReset) {
    const customDatesData = {
      fromDate: '',
      toDate: '',
      range: '',
    };
    const pendingQuestions = JSON.parse(getCustomQuestionsBasket());
    if (pendingQuestions && this.props.selectedProject.ProjectId === pendingQuestions.projectId) {
      const pendingCustomQuestions = {
        customQuestionBasket: pendingQuestions.customQuestionBasket,
        customQuestionModel: null,
      };
      this.props.updatePendingCustomQuestion(pendingCustomQuestions);
    }
    this.props.toggleProjectSegmentDrowdown(false);
    this.setState({
      insufficientData: false,
      isInsufficientForSomeDomains: false,
      isLoaded: !!isfromReset,
      isFilteredDataLoaded: !isfromReset,
      customDomainTitleCharCount: 0,
      showCustomDomainDeleteModal: false,
      isSegmentDropDownLoaded: false,
      selectedSegmentValue: '0',
      customDatesData,
      isUserTagsFiltersApplied: false,
      isExpandWidget: false,

      showFilters: false,
      userSelectedFilterGroupValues: {
        selectedGroupValue1: [],
        selectedGroupValue2: [],
        selectedGroupValue3: [],
        selectedGroupValue4: [],
      },
      numberOfFiltersSelected: 0,
      allUserFilterGroupValues: [],
      currentProjectId: this.props.selectedProject.ProjectId,
      isSegmentGroupApplied: false,
      segmentGroups: {
        segmentGroup1: [],
        segmentGroup2: [],
        segmentGroup3: [],
        segmentGroup4: [],
      },
      segmentGroupDashboardData: {
        segmentGroupData1: null,
        segmentGroupData2: null,
        segmentGroupData3: null,
        segmentGroupData4: null,
      },
      defaultSegmentGroup1: '0',
      segmentGroupDataContainer: {},
    });
    const {
      ProjectId: projectId, PortfolioId: portfolioId,
    } = this.props.selectedProject;
    this.props.deleteRelevantDomains();
    if (projectId && portfolioId) {
      this.props.updateCurrentlySelectedProjectId(projectId);
      this.props.getProjectDashboardToolbarDetails(projectId).then((resp) => {
        const {
          SelectedStageId, Stages, dropDate, projectDropTimezone,
          questionModelId, projectOpenTextQuestions,
          currentDropDate, reportUrl, agedQuestionScheduledDate,
          nextAdhocDropDate, isProjectAdhoc, dropHours, showDateFilter,
          isProjectHavingNextAdhocDrop, isDrafted,
        } = resp;
        if (resp.projectId === this.state.currentProjectId) {
          this.props.toggleProjectSegmentDrowdown(false);
          this.props.getProjectSegments(projectId, portfolioId, customDatesData).then((res) => {
            if (res) this.props.toggleProjectSegmentDrowdown(true);
          });
          this.applySegment('0');
          let nextDropFullDate = '';
          let nextScheduledDate = '';
          let nextFullDropDate = '';
          let nextFullAdhocDropDate = '';
          let AdhocDropOffset = '';
          this.setState({
            questionModelId,
            projectOpenTextQuestions,
            currentDropDate,
            showDateFilter,
            reportUrl,
            isSegmentDropDownLoaded: true,
          });
          if (dropDate !== '') {
            nextDropFullDate = moment(dropDate).tz(projectDropTimezone);
            const nextDropDate = nextDropFullDate.date();
            const nextDropMonth = nextDropFullDate.format('MMMM');
            const nextShortMonthYear = nextDropFullDate.format('MMM YYYY');
            nextFullDropDate = `${nextDropDate} ${nextShortMonthYear}`;
            nextDropFullDate = `${nextDropDate} ${nextDropMonth}`;
          }
          if (agedQuestionScheduledDate !== '') {
            nextScheduledDate = moment(agedQuestionScheduledDate).tz(projectDropTimezone);
            const nextAgedDate = nextScheduledDate.date();
            const nextAgedMonth = nextScheduledDate.format('MMM YYYY');
            nextScheduledDate = `${nextAgedDate} ${nextAgedMonth}`;
          }
          if (nextAdhocDropDate !== '') {
            nextFullAdhocDropDate = moment(nextAdhocDropDate).tz(projectDropTimezone);
            const nextAdhocDate = nextFullAdhocDropDate.date();
            const nextAdhocMonth = nextFullAdhocDropDate.format('MMM YYYY');
            const timeZoneOffset = moment().tz(projectDropTimezone).format('Z');
            nextFullAdhocDropDate = `${nextAdhocDate} ${nextAdhocMonth}`;
            AdhocDropOffset = `${constant.timeConstant[dropHours].text} (GMT ${timeZoneOffset})`;
            this.props.projectAdhocDropDate({
              nextFullAdhocDropDate,
              AdhocDropOffset,
              projectDropTimezone,
              isNextAdhocDropScheduled: isProjectHavingNextAdhocDrop,
              isProjectAdhoc,
            });
          } else {
            const adhocData = {
              nextFullAdhocDropDate: '',
              AdhocDropOffset: '',
              projectDropTimezone: '',
              isNextAdhocDropScheduled: false,
              isProjectAdhoc,
            };
            this.props.projectAdhocDropDate(adhocData);
          }
          const updatedData = {
            selectedStage: Stages.find(x => x.id === SelectedStageId).title,
            nextDropDate: nextDropFullDate,
            nextFullDropDate,
          };
          this.props.projectUpdatedData(updatedData);
          const selectedStage = Stages.find(x => x.id === SelectedStageId);
          if (!selectedStage || !SelectedStageId) {
            this.props.showRedBanner();
          } else {
            this.props.hideRedBanner();
          }
          if (SelectedStageId) {
            this.setState({
              selectedStage: Stages.find(x => x.id === SelectedStageId).title,
              selectedStageId: SelectedStageId,
              stages: Stages,
              isLoaded: true,
              nextDropdate: nextDropFullDate,
              isResetToCurrentPeriod: false,
              nextAgedQuestionScheduleDate: nextScheduledDate,
              projectDropTimezone,
              nextFullDropDate,
              nextAdhocDropDate: nextFullAdhocDropDate,
              isProjectAdhoc,
              AdhocDropOffset,
              isProjectHavingNextAdhocDrop,
              isDrafted,
            });
            if (isfromReset) {
              this.setState({ isFilteredDataLoaded: true });
            }
          }
        }
      });
      this.props.getUserFilterGroupsForProject(
        projectId,
      );
    }
  }

  changeProjectStage(stage) {
    if (this.props.showProcessingRecords) {
      return;
    }
    // update when change occur in project stage
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      if (this.state.selectedStageId !== stage.id) {
        const projectModel = {
          projectId: this.props.selectedProject.ProjectId,
          stageId: stage.id,
        };
        this.props.updateProjectStage(projectModel).then((resp) => {
          if (resp) {
            this.setState({
              selectedStage: stage.title,
              selectedStageId: stage.id,
            });
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProject.ProjectId
      && (this.props.selectedProject.ProjectId !== prevProps.selectedProject.ProjectId
        || (this.props.projectDetails.isSaved
          && this.props.projectDetails.lifecycleStage
          !== prevProps.projectDetails.lifecycleStage
        ) || (this.props.projectDetails.isSaved
          && this.props.selectedProject.ProjectId === prevProps.selectedProject.ProjectId)
        || this.props.isProjectStakeholderAssociated || this.props.valchek
      )) {
      this.applySegmentFilter(0);
      this.props.updateProjectSaveState();
      this.resetUserTagsFilters();
      this.props.openDomainFocusPanel(false);
      this.getProjectAssociatedStakeHolders(this.props.selectedProject.ProjectId);
      this.props.updateProjectStakeholderState(false);
      if (this.props.selectedProject.ProjectId === undefined) {
        const encryptedPortfolioId = cryptr.encrypt(this.props.selectedPortfolio.PortfolioId);
        this.navigate(`/portfolio-dashboard/${encryptedPortfolioId}`);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      noOfDynamicallySelectedOpenTextQuestions: 0,
      isOpenTextQuestionsDynamicallySelected: false,
      customQuestionsAddedToTopic: this.props.questionSentToFutureDrop,
      customQuestionsSuccessMessage: this.props.questionSentToFutureDropMessage,
      // isSentCustomQuestions: this.props.questionSentToFutureDrop
      //   && this.props.customQuestionBasket.customQuestions.length === 0,
    });
    if (nextProps.domainWidgets) {
      const customWidget = nextProps.domainWidgets.find(widget => widget.isInEditMode);
      if (customWidget) {
        const { domainName, attributes } = customWidget;
        this.setState({
          customDomainTitleCharCount: domainName ? domainName.length : 0,
          customAttributes: attributes,
        });
      }
    }
    if (nextProps.unsavedChangesFocusDomain && nextProps.unsavedChangesModalFocusDomain) {
      this.setState({ showUnsavedChangesModal: true });
    }
    if (nextProps.unsavedChangesCustomDomain && nextProps.unsavedChangesModalCustomDomain) {
      this.setState({ showCustomDomainUnsavedChangesModal: true });
    }
  }

  getProjectAssociatedStakeHolders(projectId) {
    getProjectStakeholders({ id: projectId }).then((res) => {
      this.setState({ associatedStakeholders: res.stakeholders });
      this.props.projectAssociatedStakeholdersCount(res.stakeholders.length);
    });
  }


  showCustomQuestionDeleteModel(data) {
    this.setState({ showDeleteModal: true, selectedid: data.value, slectedtext: data.text });
  }

  showProjectRightPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.showProjectRightPanel(true);
      this.props.showHideOverlay(true);
    }
  }

  showDomainFocusPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    $('.custom-btn:not(.square-btn)').addClass('active');
    const projectId = this.props.selectedProject.ProjectId;
    this.props.openDomainFocusPanel(true);
    this.setState({ isDomainLoaded: true });
    this.props.getProjectDomainsAction(
      projectId,
      this.state.questionModelId,
    ).then((res) => {
      if (res.domains.length > 0) {
        this.setState({ isDomainLoaded: false });
      }
    });
  }


  hideDomainFocusPanel() {
    $('.custom-btn').removeClass('active');
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.openDomainFocusPanel(false);
    }
  }

  cancelUnsavedChanges() {
    $('.custom-btn').removeClass('active');
    this.props.disableUnsavedChangesModalFocusDomain();
    this.props.disableUnsavedChangesFocusDomain();
    this.setState({
      showUnsavedChangesModal: false,
    });
    this.props.openDomainFocusPanel(false);
  }

  retainUnsavedChanges() {
    this.props.disableUnsavedChangesModalFocusDomain();
    this.setState({ showUnsavedChangesModal: false });
  }

  saveFocusDomains(values) {
    const projectId = this.props.selectedProject.ProjectId;
    const domainModel = {
      projectId,
      relevantDomains: values,
    };
    $('.custom-btn').removeClass('active');
    this.props.submitProjectDomains(domainModel).then(() => {
      this.setState({
        showUnsavedChangesModal: false,
      });
      this.props.openDomainFocusPanel(false);
      this.props.disableUnsavedChangesModalFocusDomain();
      this.props.disableUnsavedChangesFocusDomain();
      this.updateDashBoard();
    });
  }

  toggleUnsavedChanges() {
    if (!this.props.unsavedChanges) {
      this.props.enableUnsavedChangesFocusDomain();
    }
  }

  checkUncheckDomain(selectedDomain) {
    this.toggleUnsavedChanges();
    this.props.checkUncheckDomain(selectedDomain);
  }


  showProjectStakeholderRightPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.setState({ showUnsavedChangesModal: false });
      this.props.showProjectStakeholderRightPanel(true, this.props.selectedProject);
      this.props.showHideOverlay(true);
    }
  }

  downloadExcelData = () => {
    try {
      const projectData = this.state.overallWidget;
      const projectId = this.props.selectedProject.ProjectId;
      const portfolioId = this.props.selectedProject.PortfolioId;
      generateExcelReport({
        projectId,
        portfolioId,
        isProjectAnonymityMet: projectData.isOptionalCommentAnonymity,
        // selectedSegmentValue: this.state.selectedSegmentValue,
        // customDatesData: this.state.customDatesData,
      }).then((response) => {
        const blob = new Blob([response], { type: 'application/xlsx' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Report.xlsx';
        link.click();
        this.setState({ isReportLoading: false });
      });
    } catch (errr) {
      console.log('error', errr);
    }
  }

  openSupportLink = () => {
    try {
      this.showCloseButton();
    } catch (errr) {
      console.log('error', errr);
    }
  }

  showCloseButton = () => {
    this.setState({
      isShowCancelButton: true,
      isShowFeedbackButton: false,
      isShowFeedBackOptions: false,
      isShowRequestSupportForm: true,
      isComingFromBanner: true,
    });
  }

  closeForm = () => {
    this.setState({
      isShowCancelButton: false,
      isShowFeedbackButton: true,
      isShowFeedBackOptions: false,
      isShowRequestSupportForm: false,
      isComingFromBanner: false,
    });
  }

  closeRequestSupportForm = () => {
    this.setState({ isShowRequestSupportForm: false });
  }

  showOpenTextDropQuesRightPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.setState({ showUnsavedChangesModal: false });
      this.props.showOpenTextDropQuesRightPanel(this.props.overAllWidget.totalRespondedOTQuestions,
        this.props.selectedProject.ProjectId, this.state.customDatesData);
      this.props.showHideOverlay(true);
    }
  }

  async generateReportingPdf(e) {
    this.setState({ isReportLoading: true });
    e.preventDefault();
    try {
      const projectId = this.props.selectedProject.ProjectId;
      const portfolioId = this.props.selectedProject.PortfolioId;
      const isAdhocDropScheduled = !!this.props.nextAdhocDropDate;
      generateReporting({
        projectId,
        portfolioId,
        selectedSegmentValue: this.state.selectedSegmentValue,
        customDatesData: this.state.customDatesData,
        isAdhocDropScheduled,
      }).then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Report.pdf';
        link.click();
        this.setState({ isReportLoading: false });
      });
    } catch (errr) {
      console.log('error', errr);
    }
  }

  showHideUserTagsFilters() {
    if (this.props.showProcessingRecords || this.state.isSegmentGroupApplied) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      const { showFilters } = this.state;
      this.setState({
        showFilters: !showFilters,
      });
    }
  }

  updateStateAfterFilter() {
    const {
      fromDate, toDate, range,
    } = this.state.customDatesData;
    const data = {
      projectId: this.props.selectedProject.ProjectId,
      filterValues: this.state.userSelectedFilterGroupValues,
    };
    const noOfSelectedFilters = this.getNumberOfFiltersSelected();
    this.props.toggleProjectSegmentDrowdown(true);
    if (noOfSelectedFilters > 0) {
      getFilterGroupStakeholderCounts(data).then((res) => {
        this.setState({ associatedStakeholdersWithFilterValue: res, isFilteredDataLoaded: false });
        setTimeout(() => {
          this.applyUserTagsFilters();
        }, 5000);
      });
    } else {
      this.setState({
        associatedStakeholdersWithFilterValue: 0,
        numberOfFiltersSelected: 0,
      });
      if (this.state.customDatesData && fromDate
        && toDate
        && range !== constant.CurrentPeriodRange) {
        this.setState({ isFilteredDataLoaded: false });
        this.applyUserTagsFilters(true);
      } else {
        this.updateDashBoard();
      }
    }
  }

  displayCreateSegment(showFilterButton, roles, isSysAdmin, isProjectSegmentsLoaded,
    isSegmentGroupApplied) {
    const showBtn = !isSegmentGroupApplied ? <button onClick={this.showHideUserTagsFilters} className={'a-btn a-btn-secondary a-btn-lg mt-2 create-seg-btn'} style={{ padding: '0.34375rem 0.7rem' }}>
      Create a segment
    </button> : null;
    if (showFilterButton && (roles.projectAdministrator
      || roles.portfolioAdministrator || isSysAdmin)) {
      return <div className="d-inline-block g-timeing-wrap">
        {!isProjectSegmentsLoaded ? <SkeltonLoadingDropDown /> : showBtn}
      </div>;
    }
    if (showFilterButton && (roles.projectStakeholder
      || roles.projectViewer || roles.portfolioStakeholder)) {
      return <div className="d-inline-block g-timeing-wrap">
        {!isProjectSegmentsLoaded ? <SkeltonLoadingDropDown /> : null}
      </div>;
    }
    return null;
  }

  updateUserTagFilters(values, isRemove) {
    this.props.toggleProjectSegmentDrowdown(false);
    let externalRemove = false;
    this.setState({
      isSegmentApplied: false,
      selectedSegmentValue: '-1',
      isSegmentDropDownLoaded: false,
    });
    setTimeout(() => {
      this.setState({ isSegmentDropDownLoaded: true });
    }, 1);
    if (!isRemove) {
      const filtersValue = [];
      values.selectedGroupValue1.map((item) => {
        filtersValue.push(item);
      });
      values.selectedGroupValue2.map((item) => {
        filtersValue.push(item);
      });
      values.selectedGroupValue3.map((item) => {
        filtersValue.push(item);
      });
      values.selectedGroupValue4.map((item) => {
        filtersValue.push(item);
      });
      this.setState({
        allUserFilterGroupValues: filtersValue,
      });
      this.setState({ userSelectedFilterGroupValues: values });
      this.props.userSelectedfilterGroupValuesUpdate(values);
      if (filtersValue.length === 0) {
        this.setState({ selectedSegmentValue: '0' });
      }
    } else {
      const newFilterGroupsArray = {
        selectedGroupValue1: [],
        selectedGroupValue2: [],
        selectedGroupValue3: [],
        selectedGroupValue4: [],
      };
      const { userSelectedFilterGroupValues } = this.state;
      const userSelectedFilterGroupValuesFromState = Object.assign({},
        userSelectedFilterGroupValues);
      this.setState({
        allUserFilterGroupValues: [],
        isRemovingFilterGroupsExternally: true,
      });
      const filtersValue = [];
      const indexFilter1value = userSelectedFilterGroupValuesFromState
        .selectedGroupValue1.indexOf(values);
      const indexFilter2value = userSelectedFilterGroupValuesFromState
        .selectedGroupValue2.indexOf(values);
      const indexFilter3value = userSelectedFilterGroupValuesFromState
        .selectedGroupValue3.indexOf(values);
      const indexFilter4value = userSelectedFilterGroupValuesFromState
        .selectedGroupValue4.indexOf(values);
      if (indexFilter1value > -1) {
        const filterGroupValue1 = userSelectedFilterGroupValuesFromState.selectedGroupValue1
          .filter(val => val !== values);
        newFilterGroupsArray.selectedGroupValue1 = filterGroupValue1;
        newFilterGroupsArray.selectedGroupValue2 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue2;
        newFilterGroupsArray.selectedGroupValue3 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue3;
        newFilterGroupsArray.selectedGroupValue4 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue4;
      }
      if (indexFilter2value > -1) {
        const filterGroupValue2 = userSelectedFilterGroupValuesFromState.selectedGroupValue2
          .filter(val => val !== values);
        newFilterGroupsArray.selectedGroupValue1 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue1;
        newFilterGroupsArray.selectedGroupValue2 = filterGroupValue2;
        newFilterGroupsArray.selectedGroupValue3 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue3;
        newFilterGroupsArray.selectedGroupValue4 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue4;
      }
      if (indexFilter3value > -1) {
        const filterGroupValue3 = userSelectedFilterGroupValuesFromState.selectedGroupValue3
          .filter(val => val !== values);
        newFilterGroupsArray.selectedGroupValue1 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue1;
        newFilterGroupsArray.selectedGroupValue2 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue2;
        newFilterGroupsArray.selectedGroupValue3 = filterGroupValue3;
        newFilterGroupsArray.selectedGroupValue4 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue4;
      }
      if (indexFilter4value > -1) {
        const filterGroupValue4 = userSelectedFilterGroupValuesFromState.selectedGroupValue4
          .filter(val => val !== values);
        newFilterGroupsArray.selectedGroupValue1 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue1;
        newFilterGroupsArray.selectedGroupValue2 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue2;
        newFilterGroupsArray.selectedGroupValue3 = userSelectedFilterGroupValuesFromState
          .selectedGroupValue3;
        newFilterGroupsArray.selectedGroupValue4 = filterGroupValue4;
      }
      newFilterGroupsArray.selectedGroupValue1.map((item) => {
        filtersValue.push(item);
      });
      newFilterGroupsArray.selectedGroupValue2.map((item) => {
        filtersValue.push(item);
      });
      newFilterGroupsArray.selectedGroupValue3.map((item) => {
        filtersValue.push(item);
      });
      newFilterGroupsArray.selectedGroupValue4.map((item) => {
        filtersValue.push(item);
      });

      this.setState({
        allUserFilterGroupValues: filtersValue,
      });
      externalRemove = true;
      this.setState({ userSelectedFilterGroupValues: newFilterGroupsArray }, () => {
        this.updateStateAfterFilter();
      });
      this.props.userSelectedfilterGroupValuesUpdate(newFilterGroupsArray);
      setTimeout(() => {
        this.setState({ isRemovingFilterGroupsExternally: false });
      }, 1);
    }
    this.props.saveSegmentGroupFormValues({
      segmentGroup1: 0,
      segmentGroup2: -1,
      segmentGroup3: -1,
      segmentGroup4: -1,
    });
    if (!externalRemove) {
      this.updateStateAfterFilter();
    }
  }

  updateUserTagFiltersWithDebounce = debounce(this.updateUserTagFilters, 500);

  createNewSegment() {
    const { userSelectedFilterGroupValues, customDatesData } = this.state;
    const { segmentGroupValue } = this.props;
    const projectId = this.props.selectedProject.ProjectId;
    const {
      selectedGroupValue1, selectedGroupValue2, selectedGroupValue3, selectedGroupValue4,
    } = userSelectedFilterGroupValues;
    if (selectedGroupValue1.length > 0 || selectedGroupValue2.length > 0
      || selectedGroupValue3.length > 0 || selectedGroupValue4.length > 0) {
      this.setState({ isSegmentDropDownLoaded: false });
      this.props.createNewSegment({
        projectId,
        filterGroupValues: userSelectedFilterGroupValues,
        checkingExistingSegment: false,
      })
        .then((res) => {
          if (res) {
            this.props.saveSegmentGroupFormValues({
              ...segmentGroupValue,
              segmentGroup1: res,
            });
            this.setState({
              isSegmentSavedSuccessfully: true,
              isSegmentDropDownLoaded: true,
            });
            setTimeout(() => this.setState({ isSegmentSavedSuccessfully: false }), 5000);
            this.resetUserTagsFilters(true);
            const {
              PortfolioId: portfolioId,
            } = this.props.selectedProject;
            this.applySegment(res);
            this.props.toggleProjectSegmentDrowdown(false);
            this.props.getProjectSegments(projectId, portfolioId, customDatesData).then((resp) => {
              if (resp) this.props.toggleProjectSegmentDrowdown(true);
            });
            this.setState({ isSegmentApplied: true });
          } else {
            this.setState({ isSegmentDropDownLoaded: true });
            this.props.saveSegmentAlreadyCreated({
              isSegmentAlreadyCreated: true,
              duplicateMessage: 'Duplicate segment cannot be created. please remove one or more filters and try again.',
            });
            setTimeout(() => this.props.saveSegmentAlreadyCreated({
              isSegmentAlreadyCreated: false,
              duplicateMessage: '',
            }), 5000);
          }
        });
    }
  }

  applySegment(selectedSegmentValue) {
    this.props.saveSelectedSegmentValue(selectedSegmentValue);
    this.setState({
      selectedSegmentValue,
    });
    this.fetchDashboardResultsFromSegment();
  }

  fetchDashboardResultsFromSegment() {
    const projectId = this.props.selectedProject.ProjectId;
    const portfolioId = this.props.selectedProject.PortfolioId;
    this.setState({
      isFilteredDataLoaded: false,
      isSegmentApplied: this.state.selectedSegmentValue !== '0',
      isExpandWidget: true,
    });
    this.resetUserTagsFilters(true, true);
    this.props.getUserSegmentFiltersData(
      projectId,
      portfolioId,
      this.state.selectedSegmentValue,
      this.state.customDatesData,
    ).then((resp) => {
      const {
        noRecordsFound, SelectedStageId, Stages, DomainWidgets, OverallWidget,
        customQuestionData, futureDropCustomQuestions,
      } = resp;
      if (OverallWidget.projectId === this.state.currentProjectId) {
        this.setState({ isSegmentDropDownLoaded: true });
        if (noRecordsFound) {
          this.setState({
            insufficientDataForSomeSegments: false,
            isFilteredDataLoaded: true,
            insufficientData: false,
          });
        } else if (SelectedStageId) {
          OverallWidget.isExpanded = true;
          const domainLength = DomainWidgets.length;
          let passingDomainsLength = 0;
          DomainWidgets.map((widget) => {
            // eslint-disable-next-line
            if (widget.overallScore !== null) passingDomainsLength += 1;
            return widget;
          });
          if (passingDomainsLength === 0) {
            this.setState({
              insufficientDataForSomeSegments: true,
              isInsufficientForAllDomains: true,
              isInsufficientForSomeDomains: false,
              insufficientData: false,
            });
          } if (passingDomainsLength < domainLength) {
            this.setState({
              insufficientDataForSomeSegments: true,
              isInsufficientForAllDomains: false,
              isInsufficientForSomeDomains: true,
              insufficientData: false,
            });
          } else {
            this.setState({
              insufficientDataForSomeSegments: false,
              isInsufficientForAllDomains: false,
              isInsufficientForSomeDomains: false,
              insufficientData: false,
            });
          }
          DomainWidgets.forEach((pWidget, index) => {
            DomainWidgets[index].isExpanded = true;
          });
          this.props.updateDomainsToTopic(DomainWidgets);
          this.props.saveProjectOverAllWidget(OverallWidget);
          this.props.updateProjectDraftedStatus(OverallWidget.projectDraftedStatus);
          this.props.projectActiveState(OverallWidget.active);
          this.setState({
            selectedStage: Stages.find(x => x.id === SelectedStageId).title,
            selectedStageId: SelectedStageId,
            stages: Stages,
            overallWidget: OverallWidget,
            isFilteredDataLoaded: true,
            domainWidgetsData: DomainWidgets,
            customQuestionData,
            futureDropCustomQuestions,
          });
        } else {
          this.setState({ isFilteredDataLoaded: true });
        }
      }
    });
  }

  getNumberOfFiltersSelected() {
    const filters = this.state.userSelectedFilterGroupValues;
    const numberOfSelectedGroupValue1 = filters.selectedGroupValue1.length;
    const numberOfSelectedGroupValue2 = filters.selectedGroupValue2.length;
    const numberOfSelectedGroupValue3 = filters.selectedGroupValue3.length;
    const numberOfSelectedGroupValue4 = filters.selectedGroupValue4.length;

    return numberOfSelectedGroupValue1 + numberOfSelectedGroupValue2
      + numberOfSelectedGroupValue3 + numberOfSelectedGroupValue4;
  }

  applyUserTagsFilters(fetchWithCustomDates) {
    const projectId = this.props.selectedProject.ProjectId;
    const portfolioId = this.props.selectedProject.PortfolioId;
    const numberOfFiltersSelected = this.getNumberOfFiltersSelected();
    if (fetchWithCustomDates) {
      this.setState({ isDateFiltersApplied: true, isSegmentDropDownLoaded: true });
    }
    this.setState({
      isUserTagsFiltersApplied: true,
      isInsufficientForAllDomains: false,
      isFilteringProjectByUserTags: true,
      insufficientData: false,
      isInsufficientForSomeDomains: false,
      numberOfFiltersSelected,
      isExpandWidget: true,
    });
    this.props.getUserTagFiltersData(
      projectId,
      portfolioId,
      this.state.userSelectedFilterGroupValues,
      this.state.customDatesData,
    ).then((resp) => {
      const {
        noRecordsFound, SelectedStageId, Stages, DomainWidgets, OverallWidget,
        customQuestionData, futureDropCustomQuestions,
      } = resp;

      if (noRecordsFound) {
        this.setState({
          insufficientData: true,
          isInsufficientForSomeDomains: false,
        });
      } else if (SelectedStageId) {
        OverallWidget.isExpanded = true;
        const domainLength = DomainWidgets.length;
        let passingDomainsLength = 0;
        DomainWidgets.map((widget) => {
          // eslint-disable-next-line
          if (widget.overallScore !== null) passingDomainsLength += 1;
          return widget;
        });
        if (passingDomainsLength === 0) {
          this.setState({
            insufficientData: true,
            isInsufficientForAllDomains: true,
            isInsufficientForSomeDomains: false,
          });
        } else if (passingDomainsLength < domainLength) {
          this.setState({
            insufficientData: false,
            isInsufficientForAllDomains: false,
            isInsufficientForSomeDomains: true,
          });
        } else {
          this.setState({
            insufficientData: false,
            isInsufficientForAllDomains: false,
            isInsufficientForSomeDomains: false,
          });
        }
        DomainWidgets.forEach((pWidget, index) => {
          DomainWidgets[index].isExpanded = true;
        });
        this.props.updateDomainsToTopic(DomainWidgets);
        this.props.updateProjectDraftedStatus(OverallWidget.projectDraftedStatus);
        this.props.projectActiveState(OverallWidget.active);
        this.setState({
          selectedStage: Stages.find(x => x.id === SelectedStageId).title,
          selectedStageId: SelectedStageId,
          stages: Stages,
          overallWidget: OverallWidget,
          domainWidgetsData: DomainWidgets,
          customQuestionData,
          futureDropCustomQuestions,
        });
        if (fetchWithCustomDates) {
          this.setState({ isResetToCurrentPeriod: false });
        }
      }
      this.setState({ isFilteredDataLoaded: true });
    });
  }

  resetUserTagsFilters(disableFilters, resetFiltersForSegments,
    resetSegmentDropDown, resetOnButtonClick) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const {
      fromDate, toDate, range,
    } = this.state.customDatesData;
    this.setState({
      showFilters: false,
      userSelectedFilterGroupValues: {
        selectedGroupValue1: [],
        selectedGroupValue2: [],
        selectedGroupValue3: [],
        selectedGroupValue4: [],
      },
      isUserTagsFiltersApplied: false,
      numberOfFiltersSelected: 0,
      allUserFilterGroupValues: [],
    });
    this.props.userSelectedfilterGroupValuesUpdate({
      selectedGroupValue1: [],
      selectedGroupValue2: [],
      selectedGroupValue3: [],
      selectedGroupValue4: [],
    });
    if (resetSegmentDropDown) {
      this.setState({
        selectedSegmentValue: '0', isSegmentDropDownLoaded: false,
      });
      this.props.saveSegmentGroupFormValues({
        segmentGroup1: 0,
        segmentGroup2: -1,
        segmentGroup3: -1,
        segmentGroup4: -1,
      });
    }

    if (!resetFiltersForSegments) {
      if (this.state.customDatesData && fromDate
        && toDate
        && range !== constant.CurrentPeriodRange && resetOnButtonClick) {
        this.setState({ isFilteredDataLoaded: false });
        setTimeout(() => {
          this.applyUserTagsFilters(true);
        }, 1);
      } else if (!disableFilters) {
        this.updateDashBoard();
        if (!this.props.questionSentToFutureDrop) {
          setTimeout(() => {
            this.props.updateQuestionSentToFutureDrop({
              status: false,
              message: null,
            });
            this.setState({
              customQuestionsAddedToTopic: false,
              customQuestionsSuccessMessage: null,
            });
          }, 5000);
        }
      }
    }
  }

  // spark trend line
  handlePointHover = (activePoint, e) => {
    if (this.state.overallWidget.trendData && this.state.overallWidget.trendData.length > 0
      && e != null && activePoint != null) {
      this.setState({
        activePoint,
        activePosition: {
          x: e.currentTarget.getAttribute('cx'),
          y: e.currentTarget.getAttribute('cy'),
          date: activePoint.date,
          score: activePoint.y,
        },
      });
    }
  }

  hideTooltip() {
    this.setState({
      activePosition: null,
    });
  }

  setTooltipPosition(elId, element) {
    const el = document.getElementById(elId);
    const winWidth = window.innerWidth;
    if (element === constant.tooltipArea.attribute) {
      const elLeftDistance = el.getBoundingClientRect().left + 240;
      const elPosition = Math.ceil(winWidth - elLeftDistance);
      // eslint-disable-next-line
      elPosition < 300
        ? this.setState({ tooltipPosition: 'left' })
        : this.setState({ tooltipPosition: 'right' });
    }
    if (element === constant.tooltipArea.domain) {
      const elLeftDistance = el.getBoundingClientRect().left + 18;
      const elPosition = Math.ceil(winWidth - elLeftDistance);
      // eslint-disable-next-line
      elPosition < 200
        ? this.setState({ tooltipPosition: 'left' })
        : this.setState({ tooltipPosition: 'right' });
    }
  }

  showOpenTextRightPanel(keyPhrase, selectedSegment) {
    keyPhrase = keyPhrase.replace(/'/g, "''");
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      const { selectedProject, roles, isSysAdmin } = this.props;
      const { isSegmentApplied } = this.state;
      if (roles.portfolioAdministrator || roles.portfolioStakeholder || isSysAdmin
        || roles.projectAdministrator || roles.projectStakeholder || roles.projectViewer) {
        const openTextModel = {
          projectId: selectedProject.ProjectId,
          projectName: selectedProject.ProjectTitle,
          keyPhrase,
          userTagFilters: this.state.userSelectedFilterGroupValues,
          customDates: this.state.customDatesData,
        };
        if (isSegmentApplied) {
          openTextModel.selectedSegment = selectedSegment;
        }
        this.props.showHideOverlay(true);
        this.props.showOpenTextRightPanel(openTextModel);
      }
    }
  }

  showCustomQuestionRightPanel() {
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.showCustomQuestionRightPanel(this.state.userSelectedFilterGroupValues,
        this.state.isSentCustomQuestions);
    }
  }

  showOptionalCommentRightPanel(
    attributeId,
    attributeTitle,
    domainTitle,
    attributeDescription,
    isShowCommentBox,
    domainid,
    isPassed,
    showCustomQuestionOption,
    isCustomAttribute,
    isCustomDomain,
    editQuestion,
    attributeScore,
    isProjectAnonymityMet,
    associatedStakeholdersWithFilterValue,
    allUserFilterGroupValues,
    userFilterGroupValue,
    projectDraftedStatus,
    domainAssignedSegments,
    isProjectPortfolioActive,
    isDomainAnonymityOverride,
  ) {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      if (isPassed || editQuestion) {
        const { selectedProject } = this.props;
        const { selectedSegmentValue, isSegmentApplied } = this.state;
        const optionalCommentModel = {
          projectId: selectedProject.ProjectId,
          attributeId,
          attributeTitle,
          domainTitle,
          attributeDescription,
          userTagFilters: this.state.userSelectedFilterGroupValues,
          isShowCommentPanelData: isShowCommentBox,
          domainid,
          showCustomQuestionOption,
          isCustomAttribute,
          isCustomDomain,
          attributeScore,
          customDates: this.state.customDatesData,
          isProjectAnonymityMet,
          associatedStakeholdersWithFilterValue,
          allUserFilterGroupValues,
          userFilterGroupValue,
          projectDraftedStatus,
          domainAssignedSegments,
          isProjectPortfolioActive,
          isDomainAnonymityOverride,
        };
        if (isSegmentApplied) {
          optionalCommentModel.selectedSegment = selectedSegmentValue;
        }
        this.props.showOptionalCommentRightPanel(optionalCommentModel);
      }
    }
  }

  showCustomQuestionsDetailRightPanel(isProjectAnonymityMet) {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      const { selectedProject } = this.props;
      const { selectedSegmentValue, isSegmentApplied } = this.state;
      const optionalCommentModel = {
        projectId: selectedProject.ProjectId,
        userTagFilters: this.state.userSelectedFilterGroupValues,
        customDates: this.state.customDatesData,
        isProjectAnonymityMet,
      };
      if (isSegmentApplied) {
        optionalCommentModel.selectedSegment = selectedSegmentValue;
      }
      this.props.showCustomQuestionsDetailRightPanel(optionalCommentModel);
    }
  }

  showOpenTextNonMatchRightPanel(keyPhrases, openTextSegmentValue) {
    if (keyPhrases.length > 0) {
      keyPhrases.map((item, index) => {
        keyPhrases[index] = item.replace(/'/g, "''");
      });
    }
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      const { roles, isSysAdmin, selectedProject } = this.props;
      const { isSegmentApplied } = this.state;
      if (roles.portfolioAdministrator || roles.portfolioStakeholder
        || isSysAdmin || roles.projectViewer || roles.projectAdministrator
        || roles.projectStakeholder) {
        const openTextModel = {
          projectId: selectedProject.ProjectId,
          projectName: selectedProject.ProjectTitle,
          keyPhrases,
          userTagFilters: this.state.userSelectedFilterGroupValues,
          customDates: this.state.customDatesData,
        };
        if (isSegmentApplied) {
          openTextModel.selectedSegment = openTextSegmentValue;
        }
        this.props.showOpenTextNonMatchRightPanel(openTextModel);
      }
    }
  }

  getKeyPhrases(keyPhrases) {
    const phrasearray = [];
    keyPhrases.map((i) => {
      phrasearray.push(i.phrase);
    });
    return phrasearray;
  }

  addAutomatedQuestions(attributeId, attribute, dSegments) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const projectId = this.props.selectedProject.ProjectId;
    const item = {
      projectId,
      attributeId,
      domainId: attribute.domain_id,
      isSentToAllStakeHolders: true,
    };
    const {
      userSelectedFilterGroupValues, allUserFilterGroupValues,
      associatedStakeholdersWithFilterValue, selectedSegmentValue,
    } = this.state;
    const {
      projectSegments,
    } = this.props;
    const segmentFiltersGroup = [];
    dSegments.forEach((x) => {
      const selectedSegment = projectSegments
        .find(s => parseInt(s.value, 10) === parseInt(x, 10));
      segmentFiltersGroup.push(selectedSegment.text.split(','));
    });
    this.props.getSegmentGroupStakeholderCounts(
      { ProjectId: projectId, segments: dSegments },
    ).then((d) => {
      const userFilterData = {
        filterGroupValues: allUserFilterGroupValues?.length > 0
          ? allUserFilterGroupValues : segmentFiltersGroup,
        createSegment: allUserFilterGroupValues?.length > 0 ? 'yes' : 'no',
        selectedSegmentValue,
        userFilterGroupValues: userSelectedFilterGroupValues,
        associatedStakeholdersCount: allUserFilterGroupValues?.length > 0
          ? associatedStakeholdersWithFilterValue : d ?? null,
        segments: dSegments,
        dSegments,
        segmentFiltersGroup,
      };
      if (projectId) {
        this.props.getAutomatedQuestions(item, userFilterData).then(() => {
          const modifiedCustomQuestionBasket = {
            projectId,
            customQuestionBasket: this.props.customQuestionBasket,
          };
          setCustomQuestionsBasket(modifiedCustomQuestionBasket);
        });
      }
    });
  }

  sendCustomDomainQuestions(questions) {
    const { selectedProject } = this.props;
    this.setState({ isSentCustomQuestions: true });
    const customQuestionsData = {
      projectId: selectedProject.ProjectId,
      customQuestions: questions,
      automatedQuestions: [],
      filterGroupValue: this.state.userSelectedFilterGroupValues,
      isSentToFiltered: this.state.associatedStakeholdersWithFilterValue > 0,
      projectDraftedStatus: this.state.overallWidget.projectDraftedStatus,
    };
    this.props.sendCustomQuestionsData(customQuestionsData).then((result) => {
      if (result.statusText === 'OK' || result.status === 200) {
        this.props.updateQuestionSentToFutureDrop({
          status: true,
          message: `1 question(s)
          have been added to the next question drop`,
        });
      }
    });
  }

  sendCustomQuestions() {
    const { selectedProject, customQuestionBasket } = this.props;
    this.setState({ isSentCustomQuestions: true });
    const customQuestionsData = {
      projectId: selectedProject.ProjectId,
      customQuestions: customQuestionBasket.customQuestions,
      automatedQuestions: customQuestionBasket.automatedQuestions,
      filterGroupValue: this.state.userSelectedFilterGroupValues,
      isSentToAll: !this.state.associatedStakeholdersWithFilterValue > 0
        || customQuestionBasket.automatedQuestions.length > 0
        || customQuestionBasket.customQuestions.length > 0,
      isSentToFiltered: this.state.associatedStakeholdersWithFilterValue > 0,
      projectDraftedStatus: this.state.overallWidget.projectDraftedStatus,
    };

    this.props.sendCustomQuestionsData(customQuestionsData, true).then((result) => {
      if (result.statusText === 'OK' || result.status === 200) {
        removePendingCustomQuestionData();
        this.setState({
          customQuestionsAddedToTopic: true,
          customQuestionsSuccessMessage: `${customQuestionBasket.questionCount} additional question
            sent to ${selectedProject.ProjectTitle} stakeholders`,
          isSentCustomQuestions: false,
        });
        this.updateDashBoard();
        projectService.showAlert();
        setTimeout(() => {
          this.setState({
            customQuestionsAddedToTopic: false,
            customQuestionsSuccessMessage: null,
          });
          projectService.hideAlert();
        }, 5000);
        this.updateDashBoard();
      }
    });
  }

  openProjectOpenTextModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModalFocusDomain();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.showOpenTextPanel(true);
    }
  }

  submitOpenTextTypeQuestions(projectOpenTextQuestions) {
    this.setState({ projectOpenTextQuestions });
    const { ProjectId, ProjectTitle } = this.props.selectedProject;
    return this.props.saveOpenTextTypeQuestionsForTopic(projectOpenTextQuestions, ProjectId)
      .then((response) => {
        const message = `${projectOpenTextQuestions.length} open-text questions are added to the future question cycles of ${ProjectTitle}.`;
        this.setState({
          openTextTypeQuestionsAddedToTopic: true,
          showUnsavedChangesModal: false,
          openTextTypeQuestionsSuccessMessage: message,
          noOfDynamicallySelectedOpenTextQuestions: projectOpenTextQuestions.length,
          isOpenTextQuestionsDynamicallySelected: true,
        });
        if (this.props?.projectDetails?.isDrafted) {
          this.setState({
            overallWidget: {
              ...this.state.overallWidget,
              noOfOpenTextQuestionsAssociated: projectOpenTextQuestions.length,
            },
          });
        }
        this.props.disableUnsavedChangesModalFocusDomain();
        this.props.disableUnsavedChangesFocusDomain();
        return response;
      });
  }

  submitOpenTextTypeQuestionsSuccess() {
    setTimeout(() => {
      this.setState({
        openTextTypeQuestionsAddedToTopic: false,
        openTextTypeQuestionsSuccessMessage: null,
      });
    }, 5000);
  }

  truncateDomainDescription(s) {
    if (s) {
      const n = s.length;
      if (n > 100) {
        const trimmedString = s.substring(0, 100);
        return `${trimmedString}...`;
      }
    }
    return s;
  }

  createCustomDomain() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({
      customDomainTitleCharCount: 0,
    });
    this.props.openDomainFocusPanel(false);
    this.props.addNewCustomDomainToTopic();
  }

  validateAndSaveDomain(values) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const { selectedProject } = this.props;
    const { attributesToBeRemoved } = this.state;
    this.showCustomDomainSaveBtnSpinner(true);
    const { attributes } = values;
    const customAttributes = [];
    if (attributes) {
      attributes.map((attribute) => {
        if (attribute.title && attribute.title.trim().length > 0) {
          customAttributes.push(attribute);
        }
        return attribute;
      });
    }
    values.attributes = customAttributes;
    values.attributesToBeRemoved = attributesToBeRemoved;
    this.props.saveTopicDomainAction(values).then(() => {
      this.showCustomDomainSaveBtnSpinner(false);
      this.setState({
        showCustomDomainUnsavedChangesModal: false,
        customDomainAddedToTopic: true,
        attributesToBeRemoved: [],
        customDomainSuccessMessage: `Focus area ('${convertStringLowerCase(values.domainName)}') is added to ${selectedProject.ProjectTitle}.`,
      });
      setTimeout(() => {
        this.setState({
          customDomainAddedToTopic: false,
          customDomainSuccessMessage: null,
        });
      }, 5000);
      this.props.disableUnsavedChangesForCustomDomain();
      this.props.disableUnsavedChangesModalForCustomDomain();
      this.updateDashBoard();
    });
  }

  showCustomDomainSaveBtnSpinner(value) {
    this.setState({ isCustomDomainSubmitting: value });
  }

  setFieldValueOnChange(field, e, setFieldValue, index) {
    this.enableCustomDomainUnsavedChanges();
    if (field === 'domainName') {
      this.setState({ customDomainTitleCharCount: e.target.value.length });
    }
    if (index !== undefined) {
      const { customAttributes } = this.state;
      customAttributes.map((attr, i) => {
        if (index === i) {
          attr.title = e.target.value;
        }
      });
      this.setState({ customAttributes });
    }
    setFieldValue(field, e.target.value);
  }

  enableCustomDomainUnsavedChanges() {
    this.props.enableUnsavedChangesForCustomDomain();
  }

  renderAttributes(field, setFieldValue) {
    const { customAttributes } = this.state;
    let maxAttributes = 0;
    if (customAttributes.length < constant.MAX_CUSTOM_ATTRIBUTES / 2) {
      maxAttributes = constant.MAX_CUSTOM_ATTRIBUTES / 2 - customAttributes.length;
    } else if (customAttributes.length <= constant.MAX_CUSTOM_ATTRIBUTES) {
      maxAttributes = constant.MAX_CUSTOM_ATTRIBUTES - customAttributes.length;
    }
    if (maxAttributes > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < maxAttributes; i++) {
        customAttributes.push({ id: 0, title: '' });
      }
    }
    this.setState({ customAttributes });
    setFieldValue(field, customAttributes);
  }

  cancelCustomDomainUnsavedChanges() {
    this.props.disableUnsavedChangesModalForCustomDomain();
    this.props.disableUnsavedChangesForCustomDomain();
    this.setState({
      showCustomDomainUnsavedChangesModal: false,
      customDomainTitleCharCount: 0,
      customAttributes: [],
    });
    this.updateDashBoard();
  }

  retainCustomDomainUnsavedChanges() {
    this.props.disableUnsavedChangesModalForCustomDomain();
    this.setState({ showCustomDomainUnsavedChangesModal: false });
  }

  discardUnsavedChanges() {
    this.props.enableUnsavedChangesModalForCustomDomain();
  }

  editCustomDomain(id) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const { domainWidgets } = this.props;
    if (domainWidgets && domainWidgets.length > 0) {
      const updatedDomainWidgets = domainWidgets.map((widget) => {
        if (widget.domainId === id) {
          widget.isInEditMode = true;
          let totalAttributes = [];
          const { attributes } = widget;
          if (attributes.length > 0) {
            totalAttributes = attributes;
          }
          const emptyAttributes = [];
          let maxAttributes = 0;
          if (totalAttributes.length < constant.MAX_CUSTOM_ATTRIBUTES / 2) {
            maxAttributes = constant.MAX_CUSTOM_ATTRIBUTES / 2 - totalAttributes.length;
          } else if (totalAttributes.length <= constant.MAX_CUSTOM_ATTRIBUTES) {
            maxAttributes = constant.MAX_CUSTOM_ATTRIBUTES - totalAttributes.length;
          }
          if (maxAttributes > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < maxAttributes; i++) {
              emptyAttributes.push({ id: 0, title: '' });
            }
          }
          const allAttributes = [...totalAttributes, ...emptyAttributes];
          widget.attributes = allAttributes;
          this.setState({ customAttributes: allAttributes });
        }
        return widget;
      });
      this.props.updateDomainsToTopic(updatedDomainWidgets);
      this.enableCustomDomainUnsavedChanges();
    }
  }

  deleteCustomAttribute(index, setFieldValue) {
    this.setState({
      showCustomAttributeDeleteModal: true,
      customAttributeIndexTobeDeleted: index,
      setFieldValueRefForCustomDomain: setFieldValue,
    });
  }

  deleteCustomAttributeConfirmation() {
    return new Promise((resolve) => {
      const {
        customAttributeIndexTobeDeleted,
        setFieldValueRefForCustomDomain,
        customAttributes,
        attributesToBeRemoved,
      } = this.state;
      const updateCustomAttributes = customAttributes.filter(
        (attribute, i) => i !== customAttributeIndexTobeDeleted,
      );
      const salectedAttribute = customAttributes[customAttributeIndexTobeDeleted];
      if (salectedAttribute && salectedAttribute.id > 0) {
        attributesToBeRemoved.push(salectedAttribute.id);
      }

      setFieldValueRefForCustomDomain('attributes', updateCustomAttributes);

      this.setState({
        customAttributes: updateCustomAttributes,
        setFieldValueRefForCustomDomain: null,
        attributesToBeRemoved,
      });
      resolve(true);
    });
  }

  cancelDeleteCustomAttribute() {
    this.setState({ showCustomAttributeDeleteModal: false });
  }

  deleteCustomDomain(id) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showCustomDomainDeleteModal: true, customDomainIdTobeDeleted: id });
  }

  deleteCustomDomainConfirmation() {
    const { customDomainIdTobeDeleted } = this.state;
    const { ProjectId } = this.props.selectedProject;
    return new Promise(resolve => this.props
      .deleteCustomDomain({ domainId: customDomainIdTobeDeleted, projectId: ProjectId })
      .then((res) => {
        this.props.disableUnsavedChangesForCustomDomain();
        setTimeout(() => {
          this.updateDashBoard();
        }, 1);
        resolve(res);
      }));
  }

  cancelDeleteCustomDomain() {
    this.setState({ showCustomDomainDeleteModal: false });
  }

  onCustomDatePicked(dates, range) {
    const customDatesData = {
      fromDate: range === constant.CurrentPeriodRange ? '' : dates[0],
      toDate: range === constant.CurrentPeriodRange ? '' : dates[1],
      range,
    };
    this.setState({
      customDatesData,
      segmentGroupDataContainer: {},
    });
    const {
      isSegmentGroupApplied, initialSegmentGroupFormValues,
    } = this.state;
    if (isSegmentGroupApplied) {
      const {
        ProjectId: projectId, PortfolioId: portfolioId,
      } = this.props.selectedProject;
      this.setState({ isFilteredDataLoaded: false });
      const numberOfFiltersSelected = this.getNumberOfFiltersSelected();
      if (numberOfFiltersSelected <= 0 && range === constant.CurrentPeriodRange) {
        this.props.getProjectSegments(projectId, portfolioId, { fromDate: '', toDate: '', range: 'Current Period' }).then(() => {
          this.updateProjectSegementsForComparision();
          Object.entries(initialSegmentGroupFormValues).forEach(([key, value]) => {
            if (parseInt(value, 10) >= 0) {
              this.applySegmentGroup(key, value, { fromDate: '', toDate: '', range: 'Current Period' });
            }
          });
        });
      }
      this.props.getProjectSegments(projectId, portfolioId, customDatesData).then(() => {
        this.updateProjectSegementsForComparision();
        Object.entries(initialSegmentGroupFormValues).forEach(([key, value]) => {
          if (parseInt(value, 10) >= 0) {
            this.applySegmentGroup(key, value, customDatesData);
          }
        });
      });
    } else {
      const {
        ProjectId: projectId, PortfolioId: portfolioId,
      } = this.props.selectedProject;
      this.setState({
        initialSegmentGroupFormValues: {
          segmentGroup1: 0,
          segmentGroup2: -1,
          segmentGroup3: -1,
          segmentGroup4: -1,
        },
      });
      this.props.toggleProjectSegmentDrowdown(false);
      this.props.getProjectSegments(projectId, portfolioId, customDatesData).then((res) => {
        if (res) this.props.toggleProjectSegmentDrowdown(true);
      });
      const numberOfFiltersSelected = this.getNumberOfFiltersSelected();
      if (numberOfFiltersSelected <= 0 && range === constant.CurrentPeriodRange) {
        this.updateDashBoard();
        this.props.saveSegmentGroupFormValues({
          segmentGroup1: 0,
          segmentGroup2: -1,
          segmentGroup3: -1,
          segmentGroup4: -1,
        });
      } else if (numberOfFiltersSelected > 0 && range === constant.CurrentPeriodRange) {
        this.setState({ isFilteredDataLoaded: false });
        this.applyUserTagsFilters(true);
      } else {
        const { isSegmentApplied } = this.state;
        if (isSegmentApplied) {
          this.fetchDashboardResultsFromSegment();
        } else {
          this.setState({ isFilteredDataLoaded: false });
          this.applyUserTagsFilters(true);
        }
      }
    }
  }

  resetDatesFilter() {
    const isfromReset = true;
    this.setState({
      customDatesData: {
        fromDate: '',
        toDate: '',
        range: '',
      },
      isResetToCurrentPeriod: true,
    });
    this.updateDashBoard(isfromReset);
  }

  removeFilterTagValue(value) {
    this.updateUserTagFilters(value);
    const index = this.state.userSelectedFilterGroupValues.selectedGroupValue1.indexOf(value);
    if (index > -1) {
      this.state.userSelectedFilterGroupValues.selectedGroupValue1.splice(index, 1);
      this.setState({ userSelectedFilterGroupValues: this.state.userSelectedFilterGroupValues });
      this.props.userSelectedfilterGroupValuesUpdate(this.state.userSelectedFilterGroupValues);
    }
  }

  showNoOfOpenTextQuestions(openTextQuestions) {
    let response = '';
    if (this.props?.projectDetails?.isDrafted && openTextQuestions > 0) {
      response = <p style={{ fontSize: '0.9rem' }}><i className="fa fa-comments-o mr-2 mb-1"></i>Based on {openTextQuestions} open text question{openTextQuestions > 1
        ? 's' : ''}</p>;
    } else if (openTextQuestions > 0) {
      response = (<p style={{ fontSize: '0.9rem' }}><i className="fa fa-comments-o mr-2 mb-1"></i>Based on <a style={{ cursor: 'pointer', color: '#eb590b' }} onClick={this.showOpenTextDropQuesRightPanel}>{openTextQuestions} open text question{openTextQuestions > 1 ? 's' : ''}</a></p>);
    }
    return response;
  }

  assignSegmentsToDomain(s, d, domainName) {
    const projectId = this.props.selectedProject.ProjectId;
    if (s.length > 0) {
      this.props.assignSegmentsToDomain(projectId, d, s, domainName).then((res) => {
        if (res) {
          const segmentsAssigningMessage = `Relevant segments successfully saved for focus area ('${domainName}')`;
          this.setState({ areSegmentsAssignedSuccessfully: true, segmentsAssigningMessage });
          setTimeout(() => {
            this.setState({ areSegmentsAssignedSuccessfully: false });
          }, 5000);
          this.updateDashBoard();
        }
      });
    }
  }

  // widgets methods for position changing on expand/collapse
  expandWidgets(index, widget) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const { isSegmentGroupApplied, segmentGroupDashboardData } = this.state;
    if (isSegmentGroupApplied) {
      if (index !== null) {
        const { segmentGroupData1 } = segmentGroupDashboardData;
        const { DomainWidgets } = segmentGroupData1;
        const { OverallWidget } = segmentGroupData1;
        const updatedWidgets = DomainWidgets.map((item, i) => {
          if (i === index) {
            item.isExpanded = !item.isExpanded;
          }
          return item;
        });
        this.props.saveSegmentGroupDashboard({
          ...segmentGroupDashboardData,
          segmentGroupData1: {
            DomainWidgets: updatedWidgets,
            ...segmentGroupData1,
          },
        });
        this.setState({
          segmentGroupDashboardData: {
            ...segmentGroupDashboardData,
            segmentGroupData1: {
              DomainWidgets: updatedWidgets,
              ...segmentGroupData1,
            },
          },
          isExpandWidget: true,
        });
        const collapseDomainCount = DomainWidgets.filter(item => item.isExpanded === false);
        const expandedDomainCount = DomainWidgets.filter(item => item.isExpanded === true);
        // eslint-disable-next-line
        if ((collapseDomainCount.length > 0 && expandedDomainCount.length > 0)
          && OverallWidget.isExpanded) {
          this.setState({ isExpandWidget: true });
        } else if ((collapseDomainCount.length > 0 && expandedDomainCount.length === 0)
          && !OverallWidget.isExpanded) {
          this.setState({ isExpandWidget: false });
        } else if (collapseDomainCount.length === 0 && expandedDomainCount.length === 0
          && !OverallWidget.isExpanded) {
          this.setState({
            isExpandWidget: false,
          });
        } else if (collapseDomainCount.length === 0 && expandedDomainCount.length === 0
          && OverallWidget.isExpanded) {
          this.setState({
            isExpandWidget: true,
          });
        } else if (collapseDomainCount.length === 0 && expandedDomainCount.length > 0
          && OverallWidget.isExpanded) {
          this.setState({
            isExpandWidget: true,
          });
        } else {
          this.setState({ isExpandWidget: true });
        }
      } else {
        const { segmentGroupData1 } = segmentGroupDashboardData;
        const { OverallWidget } = segmentGroupData1;
        const { DomainWidgets } = segmentGroupData1;
        const updatedOverallWidget = {
          ...OverallWidget, isExpanded: !OverallWidget.isExpanded,
        };
        this.props.saveSegmentGroupDashboard({
          ...segmentGroupDashboardData,
          segmentGroupData1: {
            ...segmentGroupData1,
            OverallWidget: updatedOverallWidget,
          },
        });
        this.setState({
          segmentGroupDashboardData: {
            ...segmentGroupDashboardData,
            segmentGroupData1: {
              ...segmentGroupData1,
              OverallWidget: updatedOverallWidget,
            },
          },
          isExpandWidget: true,
        });
        const collapseDomainCount = DomainWidgets.filter(item => item.isExpanded === false);
        const expandedDomainCount = DomainWidgets.filter(item => item.isExpanded === true);
        if ((collapseDomainCount.length > 0 && expandedDomainCount.length === 0)
          && updatedOverallWidget.isExpanded) {
          this.setState({ isExpandWidget: true });
        } else if ((collapseDomainCount.length === 0 && expandedDomainCount.length > 0)
          && !updatedOverallWidget.isExpanded) {
          this.setState({ isExpandWidget: true });
        } else if (collapseDomainCount.length === 0 && expandedDomainCount.length === 0
          && !updatedOverallWidget.isExpanded) {
          this.setState({
            isExpandWidget: true,
          });
        } else if (collapseDomainCount.length === 0 && expandedDomainCount.length > 0
          && updatedOverallWidget.isExpanded) {
          this.setState({
            isExpandWidget: true,
          });
        } else if ((collapseDomainCount.length > 0 && expandedDomainCount.length > 0)
          && !updatedOverallWidget.isExpanded) {
          this.setState({ isExpandWidget: true });
        } else {
          this.setState({ isExpandWidget: false });
        }
      }
      return;
    }
    if (!widget.isExpanded) {
      this.setState({ isExpandWidget: true });
      this.onWidgetExpand(index);
    } else {
      this.onWidgetCollapsed(index);
    }
  }

  onWidgetExpand(index, isExpandAllWidgets) {
    const {
      domainWidgetsData, overallWidget,
      isSegmentGroupApplied, segmentGroupDashboardData,
    } = this.state;
    if (isSegmentGroupApplied) {
      const { segmentGroupData1 } = segmentGroupDashboardData;
      const { DomainWidgets, OverallWidget } = segmentGroupData1;
      const updatedWidgets = DomainWidgets.map((item) => {
        item.isExpanded = true;
        return item;
      });
      const updatedOverallWidget = {
        ...OverallWidget, isExpanded: true,
      };
      this.props.saveSegmentGroupDashboard({
        ...segmentGroupDashboardData,
        segmentGroupData1: {
          DomainWidgets: updatedWidgets,
          OverallWidget: updatedOverallWidget,
        },
      });
      this.setState({
        segmentGroupDashboardData: {
          ...segmentGroupDashboardData,
          segmentGroupData1: {
            DomainWidgets: updatedWidgets,
            OverallWidget: updatedOverallWidget,
          },
        },
        isExpandWidget: true,
      });
    } else if (isExpandAllWidgets) {
      this.setState({ isExpandWidget: true });
      domainWidgetsData.forEach((widget, domainIndex) => {
        domainWidgetsData[domainIndex].isExpanded = true;
      });
      overallWidget.isExpanded = true;
      this.setState({ domainWidgetsData, overallWidget });
    } else if (index !== null) {
      const collapsedWidgetCount = domainWidgetsData.filter(item => item.isExpanded === false);
      // eslint-disable-next-line
      collapsedWidgetCount.length > 0 || overallWidget.isExpanded
        ? this.setState({ isExpandWidget: true })
        : this.setState({ isExpandWidget: false });
      domainWidgetsData[index].isExpanded = true;
      this.setState({ domainWidgetsData });
    } else {
      overallWidget.isExpanded = true;
      this.setState({ overallWidget });
    }
  }

  onWidgetCollapsed(index, isCollapseAllWidgets) {
    const {
      domainWidgetsData, overallWidget,
      isSegmentGroupApplied, segmentGroupDashboardData,
    } = this.state;
    if (isSegmentGroupApplied) {
      const { segmentGroupData1 } = segmentGroupDashboardData;
      const { DomainWidgets, OverallWidget } = segmentGroupData1;
      const updatedWidgets = DomainWidgets.map((item) => {
        item.isExpanded = false;
        return item;
      });
      const updatedOverallWidget = {
        ...OverallWidget, isExpanded: false,
      };
      this.props.saveSegmentGroupDashboard({
        ...segmentGroupDashboardData,
        segmentGroupData1: {
          DomainWidgets: updatedWidgets,
          OverallWidget: updatedOverallWidget,
        },
      });
      this.setState({
        segmentGroupDashboardData: {
          ...segmentGroupDashboardData,
          segmentGroupData1: {
            DomainWidgets: updatedWidgets,
            OverallWidget: updatedOverallWidget,
          },
        },
        isExpandWidget: false,
      });
    } else if (!isCollapseAllWidgets && isCollapseAllWidgets !== undefined) {
      this.setState({ isExpandWidget: false });
      domainWidgetsData.forEach((widget, domainIndex) => {
        domainWidgetsData[domainIndex].isExpanded = false;
      });
      overallWidget.isExpanded = false;
      this.setState({ domainWidgetsData, overallWidget });
    } else {
      const collapsedWidgetCount = domainWidgetsData.filter(item => item.isExpanded === true);
      if (index !== null) {
        // eslint-disable-next-line
        collapsedWidgetCount.length > 1
          || overallWidget.isExpanded
          ? this.setState({ isExpandWidget: true })
          : this.setState({ isExpandWidget: false });
        domainWidgetsData[index].isExpanded = false;
        this.setState({ domainWidgetsData });
      } else {
        // eslint-disable-next-line
        collapsedWidgetCount.length > 0 ? this.setState({ isExpandWidget: true })
          : this.setState({ isExpandWidget: false });
        overallWidget.isExpanded = false;
        this.setState({ overallWidget });
      }
    }
  }

  showProjectLaunchModel(e) {
    e.stopPropagation();
    if (this.props.projectStakeholdersCount === 0
      || this.props.domainWidgets.length === 0) {
      return;
    }
    this.setState({
      isShowProjectLaunchModel: true,
    });
  }

  cancelNextDrop() {
    // call cancel drop api
    this.props.cancelNextAdhocDrop({ projectId: this.props.selectedProject.ProjectId })
      .then(() => {
        const adhocData = {
          nextAdhocDropDate: '',
          adhocDropOffset: '',
        };
        this.props.projectAdhocDropDate(adhocData);
        this.setState({ isDropCancelledSuccessfully: true, nextAdhocDropDate: '' });
        setTimeout(() => {
          this.updateDashBoard();
        }, 1);
        setTimeout(() => {
          this.setState({ isDropCancelledSuccessfully: false });
        }, 9000);
      });
  }

  launchProjectToFirstQuestionCycle() {
    const projectData = { projectId: this.props.selectedProject.ProjectId };
    return new Promise(resolve => this.props
      .launchProjectToFirstQuestionCycle(projectData)
      .then((res) => {
        setTimeout(() => {
          this.updateDashBoard();
        }, 1);
        this.setState({ isProjectLaunchSuccessfully: true });
        setTimeout(() => {
          this.setState({ isProjectLaunchSuccessfully: false });
        }, 5000);
        resolve(res);
        this.fetchDashBoardProjectData();
      }));
  }

  showWarningToProjectConfigure() {
    this.setState({ isProjectConfigured: true });
    setTimeout(() => {
      this.setState({ isProjectConfigured: false });
    }, 5000);
  }

  cancelProjectLaunchModel() {
    this.setState({ isShowProjectLaunchModel: false });
  }

  setSegmentGroupData(segmentGroup, receivedData, segmentValue) {
    const { segmentGroupDashboardData } = this.state;
    const updatedWidgets = receivedData.DomainWidgets.map((item) => {
      item.isExpanded = true;
      return item;
    });
    const data = {
      ...receivedData,
      DomainWidgets: updatedWidgets,
    };
    if (segmentGroup) {
      switch (segmentGroup) {
        case 'segmentGroup1':
          this.props.saveSegmentGroupDashboard({
            ...segmentGroupDashboardData,
            segmentGroupData1: data,
          });
          this.setState({
            segmentGroupDashboardData: {
              ...segmentGroupDashboardData,
              segmentGroupData1: {
                ...data,
                OverallWidget: {
                  ...data.OverallWidget, selectedSegment: segmentValue,
                },
              },
            },
          });
          break;
        case 'segmentGroup2':
          this.props.saveSegmentGroupDashboard({
            ...segmentGroupDashboardData,
            segmentGroupData2: data,
          });
          this.setState({
            segmentGroupDashboardData: {
              ...segmentGroupDashboardData,
              segmentGroupData2: {
                ...data,
                OverallWidget: {
                  ...data.OverallWidget, selectedSegment: segmentValue,
                },
              },
            },
          });
          break;
        case 'segmentGroup3':
          this.props.saveSegmentGroupDashboard({
            ...segmentGroupDashboardData,
            segmentGroupData3: data,
          });
          this.setState({
            segmentGroupDashboardData: {
              ...segmentGroupDashboardData,
              segmentGroupData3: {
                ...data,
                OverallWidget: {
                  ...data.OverallWidget, selectedSegment: segmentValue,
                },
              },
            },
          });
          break;
        case 'segmentGroup4':
          this.props.saveSegmentGroupDashboard({
            ...segmentGroupDashboardData,
            segmentGroupData4: data,
          });
          this.setState({
            segmentGroupDashboardData: {
              ...segmentGroupDashboardData,
              segmentGroupData4: {
                ...data,
                OverallWidget: {
                  ...data.OverallWidget, selectedSegment: segmentValue,
                },
              },
            },
          });
          break;
        default:
          break;
      }
    }
  }

  deleteSegement() {
    const deleteSegements = this.state.selectedid;

    return new Promise((resolve) => {
      this.props.deleteSegementsData(deleteSegements).then((result) => {
        if (result.status === 200) {
          this.setState({ showDeleteModal: false });
          this.fetchDashBoardProjectData();
        }
      });
      resolve(true);
    });
  }

  fetchSegmentGroupData(segmentValue, segmentGroup, customDatesData) {
    const parsedSegmentValue = parseInt(segmentValue, 10);
    const {
      segmentGroupDataContainer,
      segmentGroupDashboardData,
    } = this.state;
    const { segmentGroupData1 } = segmentGroupDashboardData;
    if (segmentGroupData1) {
      const { DomainWidgets } = segmentGroupData1;
      const { OverallWidget } = segmentGroupData1;
      const updatedWidgets = DomainWidgets.map((item) => {
        item.isExpanded = true;
        return item;
      });
      this.props.saveSegmentGroupDashboard({
        ...segmentGroupDashboardData,
        segmentGroupData1: {
          DomainWidgets: updatedWidgets,
          ...segmentGroupData1,
        },
      });
      this.setState({
        segmentGroupDashboardData: {
          ...segmentGroupDashboardData,
          segmentGroupData1: {
            DomainWidgets: updatedWidgets,
            ...segmentGroupData1,
          },
        },
      });
      const updatedOverallWidget = {
        ...OverallWidget, isExpanded: true,
      };
      this.props.saveSegmentGroupDashboard({
        ...segmentGroupDashboardData,
        segmentGroupData1: {
          ...segmentGroupData1,
          OverallWidget: updatedOverallWidget,
        },
      });
      this.setState({
        segmentGroupDashboardData: {
          ...segmentGroupDashboardData,
          segmentGroupData1: {
            ...segmentGroupData1,
            OverallWidget: updatedOverallWidget,
          },
        },
      });
    }
    if (parsedSegmentValue < 0) {
      return;
    }

    const dummyArray = {};
    const projectId = this.props.selectedProject.ProjectId;
    const portfolioId = this.props.selectedProject.PortfolioId;
    this.setState({ isFilteredDataLoaded: false });
    this.props.getUserSegmentFiltersData(
      projectId,
      portfolioId,
      segmentValue,
      customDatesData,
    ).then((resp) => {
      const {
        OverallWidget, DomainWidgets,
      } = resp;
      OverallWidget.isExpanded = true;
      const newDataObj = { OverallWidget, DomainWidgets };
      dummyArray[segmentValue] = newDataObj;
      const { segmentGroupOverall } = this.props;
      switch (segmentGroup) {
        case 'segmentGroup1': this.props.saveSegmentOverall({ ...segmentGroupOverall, [segmentGroup]: OverallWidget });
          break;
        case 'segmentGroup2': this.props.saveSegmentOverall({ ...segmentGroupOverall, [segmentGroup]: OverallWidget });
          break;
        case 'segmentGroup3': this.props.saveSegmentOverall({ ...segmentGroupOverall, [segmentGroup]: OverallWidget });
          break;
        case 'segmentGroup4': this.props.saveSegmentOverall({ ...segmentGroupOverall, [segmentGroup]: OverallWidget });
          break;
        // eslint-disable-next-line max-len
        default: this.props.saveSegmentOverall({ ...segmentGroupOverall, [segmentGroup]: OverallWidget });
      }
      this.props.saveProjectOverAllWidget(OverallWidget);
      this.setState({
        isFilteredDataLoaded: true,
        segmentGroupDataContainer: {
          ...segmentGroupDataContainer,
          ...dummyArray,
        },
      });
      this.setSegmentGroupData(segmentGroup, newDataObj, segmentValue);
    });
  }

  applySegmentGroup(segmentGroup, segmentValue = '-1', customDatesData = this.state.customDatesData) {
    const {
      projectSegments,
      segmentGroupValue,
    } = this.props;
    const {
      segmentGroups, selectedSegmentValue,
      initialSegmentGroupFormValues,
    } = this.state;
    if (segmentGroup) {
      switch (segmentGroup) {
        case 'segmentGroup1':
          this.setState({
            segmentGroups: {
              ...segmentGroups,
              segmentGroup1: projectSegments,
            },
            initialSegmentGroupFormValues: {
              ...initialSegmentGroupFormValues,
              segmentGroup1: segmentValue,
            },
          });
          this.props.saveSegmentGroupFormValues({
            ...segmentGroupValue,
            segmentGroup1: segmentValue,
          });
          this.fetchSegmentGroupData(segmentValue, segmentGroup, customDatesData);
          break;
        case 'segmentGroup2':
          this.setState({
            segmentGroups: {
              ...segmentGroups,
              segmentGroup2: projectSegments,
            },
            initialSegmentGroupFormValues: {
              ...initialSegmentGroupFormValues,
              segmentGroup2: segmentValue,
            },
          });
          this.props.saveSegmentGroupFormValues({
            ...segmentGroupValue,
            segmentGroup2: segmentValue,
          });
          this.fetchSegmentGroupData(segmentValue, segmentGroup, customDatesData);
          break;
        case 'segmentGroup3':
          this.setState({
            segmentGroups: {
              ...segmentGroups,
              segmentGroup3: projectSegments,
            },
            initialSegmentGroupFormValues: {
              ...initialSegmentGroupFormValues,
              segmentGroup3: segmentValue,
            },
          });
          this.props.saveSegmentGroupFormValues({
            ...segmentGroupValue,
            segmentGroup3: segmentValue,
          });
          this.fetchSegmentGroupData(segmentValue, segmentGroup, customDatesData);
          break;
        case 'segmentGroup4':
          this.setState({
            segmentGroups: {
              ...segmentGroups,
              segmentGroup4: projectSegments,
            },
            initialSegmentGroupFormValues: {
              ...initialSegmentGroupFormValues,
              segmentGroup4: segmentValue,
            },
          });
          this.props.saveSegmentGroupFormValues({
            ...segmentGroupValue,
            segmentGroup4: segmentValue,
          });
          this.fetchSegmentGroupData(segmentValue, segmentGroup, customDatesData);
          break;
        default:
          break;
      }
    } else {
      this.setState({
        isSegmentGroupApplied: true,
        segmentGroups: {
          segmentGroup1: projectSegments,
          segmentGroup2: projectSegments,
        },
      });
      if (parseInt(selectedSegmentValue, 10) !== 0) {
        this.setState({
          initialSegmentGroupFormValues: {
            ...initialSegmentGroupFormValues,
            segmentGroup1: selectedSegmentValue,
          },
        });
        this.fetchSegmentGroupData(selectedSegmentValue, 'segmentGroup1', customDatesData);
      } else {
        this.fetchSegmentGroupData(0, 'segmentGroup1', customDatesData);
      }
    }
    this.setState({ isExpandWidget: true });
  }

  updateProjectSegementsForComparision() {
    const {
      projectSegments,
    } = this.props;
    const updatedSegmentGroups = {
      segmentGroup1: projectSegments,
      segmentGroup2: projectSegments,
    };
    if (this.state.segmentGroups.segmentGroup3
      && this.state.segmentGroups.segmentGroup3.length > 0) {
      updatedSegmentGroups.segmentGroup3 = projectSegments;
    }
    if (this.state.segmentGroups.segmentGroup4
      && this.state.segmentGroups.segmentGroup4.length > 0) {
      updatedSegmentGroups.segmentGroup4 = projectSegments;
    }
    this.setState({
      segmentGroups: updatedSegmentGroups,
    });
  }

  exitComparisionView() {
    this.setState({
      initialSegmentGroupFormValues: {
        segmentGroup1: 0,
        segmentGroup2: -1,
        segmentGroup3: -1,
        segmentGroup4: -1,
      },
    });
    this.props.saveSegmentGroupFormValues({
      segmentGroup1: 0,
      segmentGroup2: -1,
      segmentGroup3: -1,
      segmentGroup4: -1,
    });
    this.props.saveSegmentGroupDashboard({
      segmentGroupData1: null,
      segmentGroupData2: null,
      segmentGroupData3: null,
      segmentGroupData4: null,
    });
    this.updateDashBoard();
  }

  applySegmentFilter(data) {
    if (data === '-1') {
      this.showHideUserTagsFilters();
      return;
    }
    this.setState({
      initialSegmentGroupFormValues: {
        segmentGroup1: data,
        segmentGroup2: -1,
        segmentGroup3: -1,
        segmentGroup4: -1,
      },
    });
    this.props.saveSegmentGroupFormValues({
      segmentGroup1: data,
      segmentGroup2: -1,
      segmentGroup3: -1,
      segmentGroup4: -1,
    });
    if (data) {
      this.applySegment(data);
    }
  }

  updateShowUnsavedChangesModal() {
    this.setState({
      showUnsavedChangesModal: true,
    });
  }

  showAgedQuestionReminderEmailModel() {
    this.setState({
      isSendAgedReminderEmailModel: true,
    });
  }

  cancelAgedQuestionReminderModel() {
    this.setState({
      isSendAgedReminderEmailModel: false,
      manualAgedQuestionDate: '',
    });
  }

  sendAgedQuestionReminderEmail() {
    const {
      isProjectHavingNextAdhocDrop, nextAdhocDropDate, isProjectAdhoc, projectUpdatedStatus,
    } = this.props;
    const {
      nextFullDropDate,
    } = projectUpdatedStatus;
    const {
      manualAgedQuestionDate,
    } = this.state;
    const projectData = {
      projectId: this.props.selectedProject.ProjectId,
      manualAgedQuestionDate: manualAgedQuestionDate
        || (!isProjectAdhoc && isProjectHavingNextAdhocDrop
          ? moment(nextAdhocDropDate).subtract(1, 'days').toDate()
          : !isProjectAdhoc ? moment().toDate()
            : moment(nextFullDropDate).subtract(1, 'days').toDate()),
    };
    return new Promise(resolve => this.props
      .sendAgedQuestionReminderNotification(projectData)
      .then((res) => {
        this.setState({
          isSendAgedQuestionMailSuccessfully: true,
          manualAgedQuestionDate: '',
        });
        setTimeout(() => {
          this.setState({ isSendAgedQuestionMailSuccessfully: false });
        }, 9000);
        resolve(res);
      }));
  }

  agedQuestionReminderDays(startDate) {
    const sDate = moment(startDate);
    let today = new Date();
    today = moment(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
    return today.businessDiff(sDate, true);
  }

  getDateDisplayString(days, date, type) {
    const sDate = moment(date);
    const now = moment();
    if (type === 'nextAgedQuestionScheduleDate' && this.props?.projectDetails?.analysisPeriod === 1) {
      return '';
    }
    if (days === 0 && new Date(date).getDate() === new Date().getDate()) {
      return constant.TODAY;
    } if (days === 0 && new Date(date).getDate() !== new Date().getDate()) {
      return constant.TOMORROW;
    } if (days === -1) {
      return constant.NEXTBUSINESSDAY;
    } if (sDate < now) {
      return `${days} business day${days === 1 ? '' : 's'} ago`;
    }
    return `in ${-1 * days} business day${days === 1 ? '' : 's'}`;
  }

  disableUnableQuestion(overallWidget, projectStakeholdersCount, widget) {
    if (!overallWidget.active || !overallWidget.isPortfolioActive) {
      return 'disable';
    }
    if ((!overallWidget.projectDraftedStatus && projectStakeholdersCount !== 0)
      || widget.isCustomDomain) {
      return 'enable';
    }
    return 'disable';
  }

  getApplicableRole() {
    const { roles } = this.props;
    let role = '';
    if (roles) {
      if (roles.portfolioAdministrator) role = 'portfolioAdministrator';
      else if (roles.projectAdministrator) role = 'projectAdministrator';
      else if (roles.projectViewer) role = 'projectViewer';
      else if (roles.portfolioStakeholder) role = 'portfolioStakeholder'; else if (roles.projectStakeholder) role = 'projectStakeholder';
    }
    return role;
  }

  AdhocDropDaysCount(startDate) {
    const sDate = moment(startDate);
    let today = new Date();
    today = moment(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
    return sDate.businessDiff(today, true);
  }

  setManualAgedQuestionDate(date) {
    this.setState({ manualAgedQuestionDate: date });
  }

  showQuestionPreviewPane = () => {
    const { portfolioId, projectId } = this.props.match.params;
    const path = `/project-question-pane/${portfolioId}/${projectId}`;
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  render() {
    const {
      selectedPortfolio, selectedProject, isSysAdmin,
      userFilterGroups, customQuestionBasket, roles,
      showOpenTextPanelBool,
      relevantDomains, isOpenDomainFocusPanel, domainWidgets,
      projectStakeholdersCount, isFilterGroupSaveSuccessfully,
      projectSegments, isProjectSegmentsLoaded, duplicateSegment,
      isProjectActive, importStackholdersFlag, nextAdhocDropDate, adhocDropOffset, dropTimeZone,
      isProjectHavingNextAdhocDrop, isProjectAdhoc, projectUpdatedStatus, systemTerminology,
    } = this.props;
    const { isSegmentAlreadyCreated, duplicateMessage } = duplicateSegment;

    const projectReplace = levelTwoOrganizations.find(
      o => o.value === systemTerminology.level_two,
    );
    const projectReplaceText = projectReplace && projectReplace.text;

    let customQuestionCount = 0;

    const {
      automatedQuestions, customQuestions,
    } = customQuestionBasket;
    if (automatedQuestions && automatedQuestions.length > 0) {
      automatedQuestions.map((r) => {
        customQuestionCount += r.questions.length;
        return r;
      });
    }
    if (customQuestions && customQuestions.length > 0) {
      customQuestions.map((r) => {
        customQuestionCount += r.questions.length;
        return r;
      });
    }

    if ((customQuestions.length > 0 || automatedQuestions.length > 0)
      && selectedProject.ProjectId) {
      const modifiedCustomQuestionBasket = {
        projectId: selectedProject.ProjectId,
        customQuestionBasket,
      };
      setCustomQuestionsBasket(modifiedCustomQuestionBasket);
    }
    const showCustomQuestionOption = !!(roles.projectAdministrator
      || roles.portfolioAdministrator || isSysAdmin || roles.projectViewer
      || roles.portfolioStakeholder || roles.projectStakeholder);
    const isUserAuthoriseToView = !!(roles.projectAdministrator
      || roles.portfolioAdministrator || isSysAdmin);
    const {
      stages, isLoaded, overallWidget, activePoint,
      activePosition, tooltipPosition, showFilters, userSelectedFilterGroupValues,
      isUserTagsFiltersApplied, insufficientData, isFilteredDataLoaded,
      numberOfFiltersSelected,
      isInsufficientForAllDomains, isInsufficientForSomeDomains, showAddCustomQuestionModel,
      questionModelId, projectOpenTextQuestions,
      showUnsavedChangesModal, showCustomDomainUnsavedChangesModal,
      openTextTypeQuestionsAddedToTopic, openTextTypeQuestionsSuccessMessage, isDomainLoaded,
      isCustomDomainSubmitting, customAttributes, customDomainTitleCharCount,
      customQuestionData, customQuestionsAddedToTopic,
      customQuestionsSuccessMessage, customDomainAddedToTopic,
      customDomainSuccessMessage, showCustomAttributeDeleteModal,
      showCustomDomainDeleteModal, customDatesData, isResetToCurrentPeriod,
      selectedStageId, currentDropDate, isProjectLoading, allUserFilterGroupValues,
      associatedStakeholdersWithFilterValue, isRemovingFilterGroupsExternally,
      isSegmentSavedSuccessfully,
      selectedSegmentValue, isSegmentDropDownLoaded, areSegmentsAssignedSuccessfully,
      segmentsAssigningMessage, isSentCustomQuestions,
      insufficientDataForSomeSegments, isSegmentApplied, isExpandOverAllWidget,
      isExpandWidget, isShowProjectLaunchModel, isProjectConfigured, isProjectLaunchSuccessfully,
      dSegments, isSegmentGroupApplied, segmentGroups, defaultSegmentGroup1,
      segmentGroupDashboardData, initialSegmentGroupFormValues,
      isAutomatedQuestion,
      isSendAgedReminderEmailModel, nextAgedQuestionScheduleDate,
      projectDropTimezone, isSendAgedQuestionMailSuccessfully,
      isDropCancelledSuccessfully, manualAgedQuestionDate, showDateFilter, isDrafted,
      futureDropCustomQuestions, isReportLoading,
    } = this.state;

    const ableToCreateSegment = (
      associatedStakeholdersWithFilterValue < constant.MINIMUMSEGMENTUSERS
    );

    const isProjectPortfolioActive = overallWidget.isPortfolioActive && isProjectActive;
    let pastDays = '';
    let previousDaysCount = '';
    if (customDatesData.fromDate && customDatesData.toDate
      && customDatesData.range !== constant.LastPeriodRange) {
      if (domainWidgets[0].periodLength
        && domainWidgets[0].periodLength > 2 && domainWidgets[0].periodLength < 30) {
        previousDaysCount = domainWidgets.length > 0
          && customDatesData.range !== constant.LastPeriodRange
          ? domainWidgets[0].periodLength : 0;
        pastDays = domainWidgets[0].periodLength > 2
          ? 'weekdays' : 'weekday';
      } else {
        pastDays = 'month';
      }
    }

    const showTrendLine = !!(overallWidget.trendData
      && overallWidget.trendData.length >= 2);
    const uf = userFilterGroups;
    const showFilterButton = (uf.filter_group_value_1 && uf.filter_group_value_1.length > 0)
      || (uf.filter_group_value_2 && uf.filter_group_value_2.length > 0)
      || (uf.filter_group_value_3 && uf.filter_group_value_3.length > 0)
      || (uf.filter_group_value_4 && uf.filter_group_value_4.length > 0);

    const {
      selectedGroupValue1, selectedGroupValue2, selectedGroupValue3, selectedGroupValue4,
    } = userSelectedFilterGroupValues;
    let enableCreateSegmentbutton = false;
    if (selectedGroupValue1.length > 0 || selectedGroupValue2.length > 0
      || selectedGroupValue3.length > 0 || selectedGroupValue4.length > 0) {
      enableCreateSegmentbutton = true;
    }
    const filterDomainSegmentsText = projectSegments
      .filter(projectSegment => dSegments
        .filter(domainSegment => projectSegment.value === domainSegment).length)
      .map(filterSegment => filterSegment.text);
    // eslint-disable-next-line max-len
    const showWarningMessage = checkArrayContainsSomeValues(filterDomainSegmentsText, allUserFilterGroupValues);
    if (this.props.valchek) {
      const dataValue = {
        value: false,
      };
      this.props.isChecking(dataValue);
    }
    return (
      <div className={overallWidget.projectDraftedStatus || nextAdhocDropDate
        ? 'projects-page drafted-projects-page' : 'projects-page'}>
        <Spinner isLoading={!isLoaded} />
        <RenderIf showComponent={showCustomAttributeDeleteModal}>
          <DeleteConfirmation
            title="Delete this attribute?"
            message={[<p>You will lose all responses associated with this attribute.</p>,
            <p>We can't recover them once you delete it.</p>,
            <p>Are you sure you want to permanently delete this attribute?</p>]}
            confirmationClick={this.deleteCustomAttributeConfirmation.bind(this)}
            cancelClick={this.cancelDeleteCustomAttribute.bind(this)}
          />
        </RenderIf>
        <RenderIf showComponent={showCustomDomainDeleteModal}>
          <DeleteConfirmation
            title="Delete this focus area?"
            onClickOutsideHide={false}
            message={[<p>You will lose all responses associated with this focus area.</p>,
            <p>We can't recover them once you delete it.</p>,
            <p>Are you sure you want to permanently delete this focus area
              ({selectedProject.ProjectTitle})?</p>]}
            confirmationClick={this.deleteCustomDomainConfirmation.bind(this)}
            cancelClick={this.cancelDeleteCustomDomain.bind(this)}
          />
        </RenderIf>
        <RenderIf showComponent={isShowProjectLaunchModel}>
          <ProjectLaunchConfirmation
            modalMessage={[<p>Before launching your topic, you should check
              that you have selected the relevant
              focus areas, open-text questions and stakeholders.
              Optionally, you can also configure stakeholder segments,
              custom questions and specify fine-grained audiences for each focus area.

              <p>Click 'Send Now' to start your first question cycle
                and send notifications to your stakeholders.</p>
            </p>]}
            confirmationClick={this.launchProjectToFirstQuestionCycle.bind(this)}
            cancelClick={this.cancelProjectLaunchModel.bind(this)}
            submitButtonLabel="SEND NOW"
            modalTitle="Launch topic"
            disabled={!selectedPortfolio.protfolioActive || !selectedProject.projectActive}
          />
        </RenderIf>
        <RenderIf showComponent={isSendAgedReminderEmailModel}>
          <AgedQuestionReminderConfirmation
            modalMessage={[<div>
              <div class="cstm-agd-info">
                <label className='a-text-label'>Responses due by</label>
                <DatePicker
                  selected={
                    !isProjectAdhoc && isProjectHavingNextAdhocDrop
                      ? manualAgedQuestionDate || moment(nextAdhocDropDate).subtract(1, 'days').toDate()
                      : !isProjectAdhoc ? manualAgedQuestionDate
                        : manualAgedQuestionDate || moment(projectUpdatedStatus.nextFullDropDate).subtract(1, 'days').toDate()
                  }
                  minDate={moment().toDate()}
                  maxDate={!isProjectAdhoc && isProjectHavingNextAdhocDrop
                    ? moment(nextAdhocDropDate).subtract(1, 'days').toDate()
                    : !isProjectAdhoc ? ''
                      : moment(projectUpdatedStatus.nextFullDropDate).subtract(1, 'days').toDate()}
                  onChange={date => this.setManualAgedQuestionDate(date)}
                  className='a-text-input responses-date-info'
                  dateFormat="dd/MM/yyyy"
                  placeholderText={'dd/mm/yyyy'}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <ul className='aged-rem-q-header'>
                  <li className='preview-pane-font'>
                    {
                      (this.getDateDisplayString(
                        this.agedQuestionReminderDays(
                          nextAgedQuestionScheduleDate, projectDropTimezone,
                        ), nextAgedQuestionScheduleDate, 'nextAgedQuestionScheduleDate',
                      ).includes('ago')) ? 'Previous reminder for unanswered questions: '
                        : 'Next scheduled reminder for unanswered questions: '
                    }
                    {
                      (this.props?.projectDetails?.analysisPeriod === 1
                        || !this.props.selectedProject.isProjectDropRecurring)
                        ? ' Not Scheduled '
                        : ` ${nextAgedQuestionScheduleDate}`
                    }
                    {
                      (this.props?.projectDetails?.analysisPeriod === 1
                        || !this.props.selectedProject.isProjectDropRecurring)
                        ? ''
                        : <>
                          ({
                            this.getDateDisplayString(
                              this.agedQuestionReminderDays(
                                nextAgedQuestionScheduleDate, projectDropTimezone,
                              ), nextAgedQuestionScheduleDate, 'nextAgedQuestionScheduleDate',
                            )
                          })
                        </>
                    }
                  </li>
                  <li className='preview-pane-font'>Next question drop:{' '}{
                    (
                      (this.props.selectedProject.projectActive === false
                        || this.props.selectedPortfolio.protfolioActive === false)
                    )
                      ? ' Not Scheduled'
                      : !this.props.selectedProject.isProjectDropRecurring
                        ? nextAdhocDropDate || 'Not Scheduled' : `${projectUpdatedStatus.nextFullDropDate}`
                  }
                    {' '}
                    {
                      ((this.props.selectedProject.projectActive === false
                        || this.props.selectedPortfolio.protfolioActive === false)
                      )
                        ? ''
                        : !this.props.selectedProject.isProjectDropRecurring ? nextAdhocDropDate ? (
                          `(${this.getDateDisplayString(
                            this.agedQuestionReminderDays(
                              nextAdhocDropDate, projectDropTimezone,
                            ), nextAdhocDropDate, 'nextAdhocDropDate',
                          )})`
                        ) : ''
                          : (
                            `(${this.getDateDisplayString(
                              this.agedQuestionReminderDays(
                                projectUpdatedStatus.nextFullDropDate, projectDropTimezone,
                              ), projectUpdatedStatus.nextFullDropDate, 'nextFullDropDate',
                            )})`
                          )

                    }
                  </li>
                </ul>
              </div>
              <p className='p-s-tag'>Are you sure you want to send out a reminder email for the current question cycle?</p>
            </div>,
            ]}
            confirmationClick={this.sendAgedQuestionReminderEmail.bind(this)}
            cancelClick={this.cancelAgedQuestionReminderModel.bind(this)}
            submitButtonLabel="SEND NOW"
            modalTitle="Send reminder for unanswered questions"
            // eslint-disable-next-line max-len
            disabled={this.props.selectedProject.projectActive === false || this.props.selectedPortfolio.protfolioActive === false || (!isProjectAdhoc ? (!isProjectHavingNextAdhocDrop && !manualAgedQuestionDate) : false)}
          />
        </RenderIf>
        <RenderIf showComponent={showUnsavedChangesModal}>
          <UnsavedChangesConfirmation
            confirmationClick={this.cancelUnsavedChanges.bind(this)}
            cancelClick={this.retainUnsavedChanges.bind(this)}
          />
        </RenderIf>
        <RenderIf showComponent={showCustomDomainUnsavedChangesModal}>
          <UnsavedChangesConfirmation
            confirmationClick={this.cancelCustomDomainUnsavedChanges.bind(this)}
            cancelClick={this.retainCustomDomainUnsavedChanges.bind(this)}
            modalMessage="Are you sure you want to discard changes you made?"
            modalTitle="Discard changes?"
            submitButtonLabel="DISCARD CHANGES"
          />
        </RenderIf>
        <RenderIf showComponent={showOpenTextPanelBool && questionModelId}>
          <ProjectOpenTextQuestionsPanel
            stageId={selectedStageId}
            isInEditMode={true}
            questionModelId={questionModelId}
            updateQuestionsList={projectOpenTextQuestions}
            title={'Choose open-text question(s) for this topic'}
            cancelClick={() => this.props.showOpenTextPanel(false)}
            confirmationClick={this.submitOpenTextTypeQuestions.bind(this)}
            confirmationSuccess={this.submitOpenTextTypeQuestionsSuccess.bind(this)}
          />
        </RenderIf>
        <RenderIf showComponent={this.props.isTopicReadyForArchive}>
          <ArchiveNotificationBanner roles={roles} isSysAdmin={isSysAdmin}
            isInternalUser={this.props.isExternalUser}
            downloadExcelData={this.downloadExcelData}
            projectDraftedStatus={overallWidget.projectDraftedStatus}
            openSupportLink={this.openSupportLink}
          />
        </RenderIf>
        <RenderIf showComponent={!isProjectAdhoc}>
          {nextAdhocDropDate && <div class="a-notification a-transition-500ms warning custom-warning-notification black-notification-bar">
            <div class="notification-content custom-top-title">
              <p class="m-0 text-lt">The next question drop will send out on {nextAdhocDropDate} {adhocDropOffset} <span className="border-left">In {this.AdhocDropDaysCount(
                nextAdhocDropDate, dropTimeZone,
              )} days</span></p>
              {(roles.projectAdministrator || roles.portfolioAdministrator || isSysAdmin)
                && <button disabled={projectStakeholdersCount === 0
                  || domainWidgets.length === 0} className="bg-transparent btn-preview custom-secondary  btn-notice a-btn ml-3 btn-preview a-btn-transparent a-btn-secondary btn-notice"
                  onClick={() => this.cancelNextDrop()}>
                  Cancel Drop
                </button>}
            </div>
          </div>}
        </RenderIf>
        <RenderIf showComponent={selectedProject.isProjectDrafted}>
          {(projectStakeholdersCount === 0
            || domainWidgets.length === 0 || overallWidget.projectDraftedStatus)
            && (roles.projectAdministrator || roles.portfolioAdministrator || isSysAdmin) && <div class="a-notification a-transition-500ms warning custom-warning-notification black-notification-bar">
              <span class="notification-content">
                <span class="text-left">
                  <strong className='preview-pane-font'>This topic is in a 'DRAFT' state.</strong>
                  <p class="m-0">After you've configured your topic,
                    click 'Launch Now' to send your first question cycle.</p>
                </span>
                <button
                  data-for={'launch-label-icon'} data-tip={'launch-label_tooltip'}
                  className={`btn-preview ${this.props.projectStakeholdersCount === 0 ? 'custom-secondary bg-transparent ' : 'custom-primary'}  btn-notice a-btn ml-3 btn-preview a-btn-transparent a-btn-secondary btn-notice`}
                  onClick={e => this.showProjectLaunchModel(e)}
                  style={{
                    opacity: this.props.projectStakeholdersCount === 0
                      || this.props.domainWidgets.length === 0 ? 0.6 : 1,
                  }}
                >
                  LAUNCH NOW
                </button>
                {/**
                  Remove !TEMPORARY_CONSTANT.hidePreviewButton
                  constant when it is ready for production
                */}
                {!TEMPORARY_CONSTANT.hidePreviewButton && (roles.projectAdministrator
                  || isSysAdmin
                  || roles.portfolioAdministrator
                  || roles.projectViewer || roles.portfolioStakeholder)
                  && this.props?.projectDetails?.isDrafted
                  && (
                    <button
                      className="bg-transparent btn-preview custom-secondary  btn-notice a-btn ml-3 btn-preview a-btn-transparent a-btn-secondary btn-notice"
                      onClick={this.showQuestionPreviewPane}
                    >
                      PREVIEW
                    </button>
                  )
                }
                {(this.props.projectStakeholdersCount === 0
                  || this.props.domainWidgets.length === 0)
                  && <ReactTooltip id={'launch-label-icon'} className={'launch-label_tooltip launch-message-tooltip'} effect='solid' place='bottom'
                    multiline={true}>
                    You must have both questions and stakeholders{''}
                    added to this topic before it can be launched.
                  </ReactTooltip>}
              </span>
            </div>}
          {(projectStakeholdersCount === 0
            || domainWidgets.length === 0 || overallWidget.projectDraftedStatus)
            && !(roles.projectAdministrator || isSysAdmin || roles.portfolioAdministrator) && <div class="a-notification a-transition-500ms warning custom-warning-notification black-notification-bar">
              <span class="notification-content">
                <span class="text-left">
                  <strong class="m-0 preview-pane-font">This topic is in a 'DRAFT' state.</strong>
                  <p class="m-0 preview-pane-font">The first question cycle will start after an administrator has launched the topic.</p>
                </span>
                {/**
                  Remove !TEMPORARY_CONSTANT.hidePreviewButton
                  constant when it is ready for production
                */}
                {!TEMPORARY_CONSTANT.hidePreviewButton && (roles.projectAdministrator
                  || isSysAdmin
                  || roles.portfolioAdministrator
                  || roles.projectViewer || roles.portfolioStakeholder)
                  && this.props?.projectDetails?.isDrafted
                  && (
                    <button
                      className="bg-transparent btn-preview custom-secondary  btn-notice a-btn ml-3 btn-preview a-btn-transparent a-btn-secondary btn-notice"
                      onClick={this.showQuestionPreviewPane}
                    >
                      PREVIEW
                    </button>
                  )
                }
              </span>
            </div>}
        </RenderIf>
        <RenderIf showComponent={isLoaded}>
          {customQuestionBasket.customQuestions !== undefined
            && (customQuestionCount > 0)
            && <div class="a-notification a-transition-500ms warning custom-warning-notification">
              <span class="notification-content">
                <span class="text-left preview-pane-font">
                  {
                    customQuestionCount
                  } pending {customQuestionCount === 1 ? 'question' : 'questions'} for
                  {` ${selectedProject.ProjectTitle}`}
                  <button className="btn btn-preview  btn-notice"
                    onClick={() => this.showCustomQuestionRightPanel()}>
                    Preview
                  </button>
                  <button onClick={() => this.sendCustomQuestions()} disabled={isSentCustomQuestions} class="btn btn-sm mx-2 btn-notice btn-notice-send">Send</button>
                </span>
              </span>
              <span data-hide="a-notification" class="close-btn">
                <span class="appkiticon icon-close-fill"></span>
              </span>
            </div>
          }
          {/* mt-4 add this class */}

          <div className={`${customQuestionCount > 0 ? 'a-page-header a-transition-500ms mt-4'
            : 'a-page-header a-transition-500ms'}`}>
            <div className="row align-items-center fixed-content" style={{ top: overallWidget.projectDraftedStatus ? '25px' : '-25px', height: overallWidget.projectDraftedStatus ? '70px' : 'auto' }}>
              <div className="col-lg-7 col-md-6 col-xl-7">


                <div className="d-inline-block page-title-section">
                  <p className="a-page-title m-0 capitalize-text text-ellipses" dangerouslySetInnerHTML={{
                    __html: selectedProject.ProjectTitle,
                  }}></p>
                  <div className="d-flex align-items-center">
                    <span className="a-page-subtitle port-text-ellipses">{selectedPortfolio.PortfolioTitle}</span>
                    {!selectedPortfolio.protfolioActive && <span className="in-active-badge iab-dark ml-2">inactive</span>}
                  </div>
                </div>
                <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                  perform="project:modify"
                  permit={() => (
                    <div className="project-state d-inline-flex align-items-center">
                      <div className="a-dropdown">
                        <div className="a-dropdown-toggle" title={projectUpdatedStatus.selectedStage}>
                          <div className="a-choosen-text">{projectUpdatedStatus.selectedStage}</div>
                          <i className="a-icon appkiticon icon-down-chevron-fill"></i>
                        </div>
                        <div class="a-dropdown-menu">
                          {stages && stages.map((stage, index) => (
                            <div key={index} onClick={this.changeProjectStage.bind(this, stage)}
                              className={`a-dropdown-item
            ${projectUpdatedStatus.selectedStage === stage.title ? ' active' : ''} `}
                            >{stage.title}</div>
                          ))
                          }
                        </div>
                      </div>
                    </div>
                  )}
                  restrict={() => (
                    <div className="project-state d-inline-block">
                      <div class="a-dropdown">
                        <div class="a-dropdown-toggle" title={projectUpdatedStatus.selectedStage}>
                          <div class="a-choosen-text">{projectUpdatedStatus.selectedStage}</div>
                          <i class="a-icon appkiticon icon-down-chevron-fill"></i>
                        </div>
                      </div>
                    </div>
                  )} />

                <div className="d-inline-block g-timeing-wrap">
                  <div className="d-inline-flex align-items-center">
                    {!selectedProject.projectActive && <span className="in-active-badge iab-dark ml-2">inactive</span>}
                    {isProjectAdhoc && !overallWidget.projectDraftedStatus
                      && isProjectPortfolioActive && <>
                        <div className="g-divider"></div>
                        <div class="g-date-icon d-flex align-items-center mr-1">
                          <ReactSVG src={nextCycleSvg} />
                        </div>
                        <div className="g-text-wrap">
                          <span className="d-block preview-pane-font">Next question cycle</span>
                          {projectUpdatedStatus.nextDropdate !== ''
                            && !overallWidget.projectDraftedStatus
                            && this.props.selectedProject.isProjectDropRecurring
                            && <h5>{projectUpdatedStatus.nextDropdate}</h5>}</div>
                      </>}
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-6  col-xl-5  justify-content-end d-flex">

                <span className="ci-access position-relative">

                  <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                    perform="domain:customise-area"
                    permit={() => (<button class="a-btn ml-2 a-btn-primary a-btn-lg custom-btn"
                      onClick={this.showDomainFocusPanel} disabled={isSegmentGroupApplied}>
                      <span class="appkiticon a-btn-icon icon-grid-view-outline"></span>
                      Customise
                    </button>)} />
                  <span class={`customise-modal-wrap ${isOpenDomainFocusPanel ? 'open' : ''} `}>
                    <div class="customise-container">
                      <div class="customise-header d-flex justify-content-between align-items-center">
                        <div class="customise-title-wrap">
                          <h3>Customise focus areas</h3>
                          <button onClick={() => this.createCustomDomain()} className={'a-btn a-btn-secondary a-btn-lg'}>
                            + Create your own
                          </button>
                        </div>
                        {/* eslint-disable */}
                        {/* <button class="a-btn a-btn-secondary a-btn-lg "><i class="appkiticon a-btn-icon icon-building-fill"></i> Create your own</button> */}
                      </div>
                      <div class="customise-body">
                        {/* eslint-disable */}
                        <p class="c-modal-description">
                          Choose the focus areas that you want to gather feedback on from your stakeholders.
                          These changes will be applied starting from your next question cycle.
                        </p>
                        <div class="ci-scroll">
                          <div class="row">
                            <Spinner isLoading={isDomainLoaded} />
                            {!isDomainLoaded && relevantDomains
                              && relevantDomains.map((domain, index) => (
                                <div key={index} class="col-md-4 px-2 mb-3">
                                  <div class={`customise-item-wrap ${domain.isSelected ? 'select-ci' : ''}`}
                                    onClick={this.checkUncheckDomain.bind(this, domain)}>
                                    {domain.isCustomDomain && <span class="ci-tag preview-pane-font">CUSTOM</span>}

                                    {domain.isCustomDomain && <div class="ci-icon ci-icon-custom">
                                      <ReactSVG wrapper="svg" src={customDomainSvg} />
                                    </div>}
                                    {!domain.isCustomDomain && <div class="ci-icon">
                                      <i
                                        className={`appkiticon
                  ${domain.iconCode} `}
                                      >
                                      </i>
                                    </div>}
                                    <h5 class="ci-title">
                                      {domain.title}
                                    </h5>
                                    <p class="ci-description">
                                      {this.truncateDomainDescription
                                        (domain.description)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div class="customise-footer d-flex justify-content-between align-items-center a-border-tp ">
                        {/* <span className="ci-footer-text">{relevantDomains.filter(d => d.isSelected === true).length}/{relevantDomains.length} Selected</span> */}
                        <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                          perform="project:modify"
                          permit={() => (<p className="m-0 ottq-selector" onClick={this.openProjectOpenTextModel.bind(this)}>Choose open-text question(s)</p>)} />
                        <div >
                          <button onClick={this.hideDomainFocusPanel} className='add-admin-button a-btn a-btn-transparent a-btn-lg a-btn-gray mr-2'>
                            Cancel
                          </button>
                          <button type='submit' disabled={relevantDomains.filter(d => d.isSelected === true).length === 0} onClick={() => this.saveFocusDomains(relevantDomains)} className={'a-btn a-btn-primary a-btn-lg'}>
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </span>


                </span>

                {/*
                <button className="a-btn a-btn-lg a-btn-primary ml-2 mt-md-2 mt-sm-2 square-btn">
                  <i className="appkiticon mr-0 a-btn-icon icon-plus-fill" />
                </button > */}


                <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                  perform="project:add-stakeholder"
                  permit={() => (<button className="a-btn a-btn-lg a-btn-primary ml-2 square-btn"
                    onClick={this.showProjectStakeholderRightPanel}>
                    <i className="appkiticon mr-0 a-btn-icon icon-audience-fill" />
                  </button>)} />
                {/* {!isDrafted && (roles.portfolioAdministrator || roles.projectAdministrator || isSysAdmin) && <button data-for='report-tooltip' data-tip={'report-btn-tooltip'} className="a-btn ml-2 a-btn-primary a-btn-lg square-btn"
                  onClick={(e) => this.generateReportingPdf(e)}>
                  <i class="appkiticon a-btn-icon icon-download-light-outline mr-0"></i>
                </button>}
                <ReactTooltip id='report-tooltip' className={'report-btn-tooltip'} place='bottom'
                  effect='solid' multiline={true}>Download PDF report</ReactTooltip> */}
                {/**
                  Remove !TEMPORARY_CONSTANT.hidePreviewButton
                  constant when it is ready for production
                */}
                {!TEMPORARY_CONSTANT.hidePreviewButton && (roles.projectAdministrator || isSysAdmin || roles.portfolioAdministrator || roles.projectViewer || roles.portfolioStakeholder) && (
                  <React.Fragment>
                    <button className="a-btn a-btn-lg a-btn-primary ml-2 square-btn"
                      data-for={'preview-label-icon'} data-tip={'preview-label_tooltip'}
                      onClick={this.showQuestionPreviewPane}>
                      <span class="Appkit4-icon icon-view-fill"></span>
                    </button>
                    <ReactTooltip id={'preview-label-icon'} className={'report-btn-tooltip'} effect='solid' place='bottom'>
                      Preview
                    </ReactTooltip>
                  </React.Fragment>
                )}
                {!isDrafted && !this.props.isExternalUser && (roles.portfolioAdministrator || roles.projectAdministrator || isSysAdmin) &&
                  <div class="excel-download">
                    <button class="a-btn a-btn-lg a-btn-primary ml-2 square-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                      data-for={'excel-download-tooltip'} data-tip={'excel-download_tooltip'}
                    >
                      <i class="appkiticon a-btn-icon icon-download-light-outline mr-0"></i>
                    </button>
                    <div class="dropdown-menu download-dropdown">
                      {!TEMPORARY_CONSTANT.hideReportOption && <a class="dropdown-item d-flex align-items-center" onClick={(e) => this.generateReportingPdf(e)} style={{ cursor: 'pointer', padding: '12px 12px' }}><PdfSvg /> PDF Report</a>}
                      <a class="dropdown-item d-flex align-items-center" style={{ cursor: 'pointer', padding: '12px 12px' }} onClick={() => this.downloadExcelData()}><ExcelSvg /> Excel Data Export</a>
                    </div>
                    <ReactTooltip id={'excel-download-tooltip'} className={'report-btn-tooltip'} effect='solid' place='bottom'>
                      Download as..
                    </ReactTooltip>
                  </div>}
                <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                  perform="project:modify"
                  permit={() => (<button
                    className="a-btn a-btn-lg a-btn-primary ml-2 square-btn"
                    onClick={this.showProjectRightPanel}
                  >
                    <ReactSVG wrapper='svg' width="13px" height="12px" src={settingIconSvg} />
                  </button>)} />
              </div >


            </div>
            <div className="row">

              <div className="col-lg-6 col-md-6 col-xl-6 d-flex align-items-center">
                {domainWidgets.length > 0 && !isDrafted && <div class="d-flex align-items-center">
                  {showDateFilter && <div className="d-inline-block g-timeing-wrap mr-3">
                    <CustomDatePicker onCustomDatePicked={this.onCustomDatePicked.bind(this)}
                      isResetToCurrentPeriod={isResetToCurrentPeriod}
                      currentPeriodDate={currentDropDate}
                      isProjectLoading={isProjectLoading}
                      perioLength={domainWidgets[0].periodLength}
                    />
                  </div>}

                  {!isSegmentGroupApplied && isProjectSegmentsLoaded && projectSegments && projectSegments.length > 1 ?
                    <React.Fragment>
                      <div className="d-inline-block g-timeing-wrap custom-select-sgmnt-dropdown mr-3" style={{ marginTop: '0.9rem' }}>
                        <div className={showDateFilter ? 'row' : ''}>
                          <div className="a-dropdown">
                            <CustomSelectDropDown
                              showCreate={roles.projectAdministrator || roles.portfolioAdministrator || isSysAdmin}
                              placeholder="Filter(s) applied"
                              name="selectedSegmentValue"
                              selectedValue={selectedSegmentValue}
                              onSelectDropDownChange={(data) => this.applySegmentFilter(data)}
                              baseClass="projects-container"
                              delteSegementvalue={(data) => this.showCustomQuestionDeleteModel(data)}
                              autorisetoview={isUserAuthoriseToView}
                            />
                          </div>
                        </div>
                      </div>
                      {!isDrafted && <button onClick={() => this.applySegmentGroup()} className="a-btn a-btn-lg a-btn-primary ml-2 square-btn primary-outline custom-btn"
                        disabled={showFilters} style={{ marginTop: '0.855rem' }}
                        data-for={'segment-dd-label-icon'} data-tip={'segment-dd-label_tooltip'}>
                        <i className="appkiticon mr-0 a-btn-icon icon-plus-outline" />
                      </button >}
                      <ReactTooltip id={'segment-dd-label-icon'} className={'segment-dd-label_tooltip'} place='right'
                        effect='solid' multiline={true} backgroundColor='#6b6b6b'>Add a segment</ReactTooltip>
                    </React.Fragment> : this.displayCreateSegment(showFilterButton, roles, isSysAdmin, isProjectSegmentsLoaded,
                      isSegmentGroupApplied)
                  }
                </div>}
              </div>
              <div className="col-lg-6 col-md-6 col-xl-6 d-flex justify-content-end">
                <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                  perform="project:filter-project-dashboard"
                  permit={() => (<>
                    {isSegmentGroupApplied &&
                      <button disabled={!isFilteredDataLoaded} onClick={() => this.exitComparisionView()}
                        className='a-btn  a-btn-lg cstm-align hover-black-btn btn-expand ml-2 new_cus_filter_btn'>
                        <MaterialIcon className="material-icons a-btn-icon md-24 mb-1 mr-1" icon="exit_to_app" />
                        <text>Exit comparison view</text>
                      </button>}

                    {!isSegmentGroupApplied && showFilters && showFilterButton && <button className='a-btn a-btn-lg cstm-align filter-btn hover-black-btn new_cus_filter_btn ml-2' disabled={isSegmentGroupApplied}
                      onClick={() => this.exitComparisionView()}>
                      <span class="Appkit4-icon icon-filter-outline"></span>
                      <text>Reset Filters</text>
                    </button>}

                    {showFilterButton && !showFilters && <button className='a-btn a-btn-lg cstm-align filter-btn hover-black-btn new_cus_filter_btn ml-2' disabled={isSegmentGroupApplied}
                      onClick={this.showHideUserTagsFilters}>
                      <span class="Appkit4-icon icon-filter-outline"></span>
                      <text>Filters</text>
                      {numberOfFiltersSelected > 0 ? ` (${numberOfFiltersSelected})` : ''}
                    </button>}

                    {!isExpandWidget && <><button data-tip="tooltip" data-for="expand-card-icon" onClick={() => this.onWidgetExpand(null, true)}
                      className='a-btn  a-btn-lg cstm-align hover-black-btn btn-expand ml-2 new_cus_filter_btn'>
                      <i class="material-icons a-btn-icon md-24 project-filter-margin mb-1"><ReactSVG wrapper="svg" width="13" height="13" src={expandCardsSvg} /></i>
                    </button>

                      <ReactTooltip id='expand-card-icon' className='tooltip' place='bottom' effect='solid'>
                        Expand Card
                      </ReactTooltip>
                    </>}
                    {isExpandWidget && <><button data-for="collapse-card-icon" data-tip="tooltip" onClick={() => this.onWidgetCollapsed(null, false)}
                      className='a-btn  a-btn-lg cstm-align hover-black-btn ml-2 new_cus_filter_btn'>
                      <i class="material-icons a-btn-icon md-24 project-filter-margin mb-1"><ReactSVG wrapper="svg" width="13" height="13" src={collapseCardsSvg} /></i>
                    </button>
                      <ReactTooltip id='collapse-card-icon' className='tooltip' place='bottom' effect='solid'>
                        Collapse Card
                      </ReactTooltip>
                    </>}
                    {!overallWidget.projectDraftedStatus
                      && isProjectPortfolioActive
                      && (roles.projectAdministrator
                        || roles.portfolioAdministrator
                        || isSysAdmin) && <>< button
                          data-for={'agedQuestionReminder'} data-tip={'tooltip'}
                          className='a-btn  a-btn-lg cstm-align hover-black-btn ml-2 new_cus_filter_btn'
                          onClick={this.showAgedQuestionReminderEmailModel}>
                          <i class="material-icons a-btn-icon md-24 project-filter-margin">
                            <ReactSVG src={bellIconSvg} />
                          </i>
                        </button>
                        <ReactTooltip id={'agedQuestionReminder'} className={'tooltip'} place='left' effect='solid'>
                          Send reminder for<br /> unanswered questions
                        </ReactTooltip>
                      </>}
                  </>)
                  } />
                <Feedback
                  showCloseButton={() => this.showCloseButton()}
                  closeForm={() => this.closeForm()}
                  isShowCancelButton={this.state.isShowCancelButton}
                  closeRequestSupportForm={() => this.closeRequestSupportForm()}
                  isShowFeedbackButton={this.state.isShowFeedbackButton}
                  isShowFeedBackOptions={this.state.isShowFeedBackOptions}
                  isShowRequestSupportForm={this.state.isShowRequestSupportForm}
                  isComingFromBanner={this.state.isComingFromBanner}
                  pageRef={`/${projectReplaceText && projectReplaceText.toLowerCase()}-dashboard`}
                  selectedProjectTitle={selectedProject.ProjectTitle} isShowSupportBtn={true} />
              </div>
              {isSegmentGroupApplied && <SegmentsGroupToolbar
                segmentGroups={segmentGroups}
                applySegmentGroup={(sg, sv) => this.applySegmentGroup(sg, sv)}
                isSegmentGroupApplied={isSegmentGroupApplied}
                defaultSegmentGroup1={defaultSegmentGroup1}
                initialSegmentGroupFormValues={initialSegmentGroupFormValues}
              />}

              {!isSegmentGroupApplied && showFilters && showFilterButton && <div className="col-12">
                <hr className="my-2"></hr>
              </div>}

              {!isSegmentGroupApplied && showFilters && showFilterButton && <div className="col-lg-12 col-md-12 ">
                {showFilters && showFilterButton && <Formik
                  enableReinitialize={true}
                  initialValues={userSelectedFilterGroupValues}>
                  {({ setFieldValue, values }) => (
                    <div className="row align-items-center seven-cols user-filters mt-2 mb-2">
                      <div className="col-8">
                        <div className=" row">
                          {userFilterGroups.filter_group_value_1.length > 0 && !isRemovingFilterGroupsExternally && (<div className="col-3 ">
                            <label>{userFilterGroups.filterGroupName1}</label>
                            <div className="a-dropdown">
                              <SumoSelect
                                multiple="multiple"
                                placeholder="-- All --"
                                name="selectedGroupValue1"
                                selectedValues={values.selectedGroupValue1}
                                options={userFilterGroups.filter_group_value_1}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.updateUserTagFiltersWithDebounce(values, false)}
                                captionFormatAllSelected="-- All --"
                              />
                            </div>
                          </div>)}
                          {userFilterGroups.filter_group_value_2.length > 0 && !isRemovingFilterGroupsExternally && (<div className="col-3">
                            <label>{userFilterGroups.filterGroupName2}</label>
                            <div className="a-dropdown">
                              <SumoSelect
                                multiple="multiple"
                                placeholder="-- All --"
                                name="selectedGroupValue2"
                                selectedValues={values.selectedGroupValue2}
                                options={userFilterGroups.filter_group_value_2}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.updateUserTagFiltersWithDebounce(values, false)}
                                captionFormatAllSelected="-- All --"
                              />
                            </div>
                          </div>)}
                          {userFilterGroups.filter_group_value_3.length > 0 && !isRemovingFilterGroupsExternally && (<div className="col-3">
                            <label>{userFilterGroups.filterGroupName3}</label>
                            <div className="a-dropdown">
                              <SumoSelect
                                multiple="multiple"
                                placeholder="-- All --"
                                name="selectedGroupValue3"
                                selectedValues={values.selectedGroupValue3}
                                options={userFilterGroups.filter_group_value_3}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.updateUserTagFiltersWithDebounce(values, false)}
                                captionFormatAllSelected="-- All --"
                              />
                            </div>
                          </div>)}
                          {userFilterGroups.filter_group_value_4.length > 0 && !isRemovingFilterGroupsExternally && (<div className="col-3">
                            <label>{userFilterGroups.filterGroupName4}</label>
                            <div className="a-dropdown">
                              <SumoSelect
                                multiple="multiple"
                                placeholder="-- All --"
                                name="selectedGroupValue4"
                                selectedValues={values.selectedGroupValue4}
                                options={userFilterGroups.filter_group_value_4}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.updateUserTagFiltersWithDebounce(values, false)}
                                captionFormatAllSelected="-- All --"
                              />
                            </div>
                          </div>)}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex align-items-center justify-content-end">
                          <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                            perform="project:modify"
                            permit={() => (
                              <span className="link-icon ml-3">
                                <button className="a-btn a-btn-secondary mr-cust1-2 q-m-button mb-1"
                                  onClick={() => this.createNewSegment()} disabled={!enableCreateSegmentbutton || ableToCreateSegment}>
                                  <span className='preview-pane-font' data-tip-disable={false} data-for='create-segment' data-tip='create-seg-tooltip'>Create Segment</span>
                                </button>
                              </span>
                            )} />
                          {ableToCreateSegment && <ReactTooltip id="create-segment" className='create-seg-tooltip' place='bottom'
                            effect='solid'>
                            The filters that you have applied would result in a segment with fewer than 3 stakeholders,
                            which is below the anonymity threshold. Please adjust your filter selections to create a segment.
                          </ReactTooltip>}
                          <div className="pro-reset-button reset-button ">
                            <a onClick={() => this.exitComparisionView()}><i class="appkiticon icon-close-fill  "> </i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>}
                {!isSegmentGroupApplied && showFilters && showFilterButton && allUserFilterGroupValues && allUserFilterGroupValues.length > 0 && <div className="selected-filter-wrap">
                  <div className="selected-filter-tags">
                    <div className="selected-filter-label">
                      <span preview-pane-font>Applied filters:</span>
                      <span>
                        {
                          !isFilteredDataLoaded &&
                          <i class="mx-5 mt-2
                           a-loading-one a-primary"></i>
                        }
                      </span>
                    </div>
                    {
                      isFilteredDataLoaded &&
                      <div className="d-flex flex-wrap align-items-center">
                        {allUserFilterGroupValues.map((value, index) => (
                          <div key={index} class="a-tag a-tag-light">
                            <span class="a-mr-10 preview-pane-font">{value}</span>
                            <button aria-label="close" class="pills-button" onClick={() => this.updateUserTagFilters(value, true)}>
                              <i class="a-icon appkiticon icon-close-fill"></i></button>
                          </div>
                        ))}

                        {/* ///-------Clear-Button----/// */}
                        <div className="selected-filter-clear">
                          <a className="fs-12" onClick={() => this.exitComparisionView()}>Clear Filter</a>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="selected-filter-result">
                    {projectStakeholdersCount && <span className="preview-pane-font">Showing results for {associatedStakeholdersWithFilterValue}/{projectStakeholdersCount} stakeholders</span>}
                  </div>
                </div>}
              </div>
              }
              {!isDrafted && !isSegmentGroupApplied &&
                !isSegmentApplied && isUserTagsFiltersApplied && isInsufficientForSomeDomains
                && !insufficientData
                && <div className="col-lg-12 col-md-12 ">
                  <div className="some-insufficient-result-container">
                    <div class="insuficient-img-sml"></div>

                    <h2>
                      <div class="ion ion-alert-circled"></div>
                      Insufficient results for some focus areas
                    </h2>
                    <p>
                      <span className='preview-pane-font'>The filters that you have applied result in too few stakeholder responses
                        for us to show you meaningful insights across 1 or more focus areas.
                        Try removing some filters and apply your changes again.
                      </span>
                      <InsufficientResultInfoTooltip />
                    </p>
                  </div>
                </div>
              }
              {!isDrafted && !isSegmentGroupApplied &&
                !isSegmentApplied && isInsufficientForAllDomains && !isUserTagsFiltersApplied && <div className="col-lg-12 col-md-12 ">
                  <div className="some-insufficient-result-container">
                    <div class="insuficient-img-sml"></div>

                    <h2>
                      <div class="ion ion-alert-circled"></div>
                      Insufficient Results
                    </h2>
                    <p>
                      <span>There are too few stakeholders for us to show you meaningful insights.
                        Please check back after more stakeholders have responded.
                      </span>
                      <InsufficientResultInfoTooltip />
                    </p>
                  </div>
                </div>
              }
              {!isDrafted && !isSegmentGroupApplied &&
                isSegmentApplied && insufficientDataForSomeSegments && <div className="col-lg-12 col-md-12 sgmnt-insufcnt-dta">
                  <div className="some-insufficient-result-container">
                    <div class="insuficient-img-sml"></div>
                    <h2>
                      <div class="ion ion-alert-circled"></div>
                      Insufficient results for some focus areas
                    </h2>
                    <p>
                      <span>The filters that you have applied result in too few stakeholder responses
                        for us to show you meaningful insights across 1 or more focus areas.
                        Try removing some filters and apply your changes again.
                      </span>
                      <InsufficientResultInfoTooltip />
                    </p>
                  </div>
                </div>
              }


              {!isDrafted && !isSegmentGroupApplied &&
                !isSegmentApplied && isInsufficientForSomeDomains && !isUserTagsFiltersApplied && <div className="col-lg-12 col-md-12 ">
                  <div className="some-insufficient-result-container">
                    <div class="insuficient-img-sml"></div>

                    <h2>
                      <div class="ion ion-alert-circled"></div>
                      Insufficient results for some domains
                    </h2>
                    <p>
                      <span class='insufficient-result-span'>Further stakeholder responses are required for 1 or more focus areas
                        before we can display all results.
                      </span>
                      <InsufficientResultInfoTooltip />
                    </p>
                  </div>
                </div>
              }
            </div >
          </div >
          <div className="filter-loading">
            <Spinner isLoading={!isFilteredDataLoaded} showLoadingMessage={true}
              loadingMessage="Loading Results..." />
          </div>
          <RenderIf showComponent={isFilteredDataLoaded}>
            {!isDrafted && !isSegmentGroupApplied && isUserTagsFiltersApplied && insufficientData
              && <div class="well">
                <h1>
                  <div class="insuficient-img"></div>
                  Insufficient Results
                </h1>
                <p>The filters that you have applied result in too few stakeholder
                  for us to display meaningful insights.</p>
                <p>
                  <span className='preview-pane-font'>Try removing some filters and apply your changes again.</span>
                  <InsufficientResultInfoTooltip />
                </p>
              </div>
            }
            {isSegmentGroupApplied && <SegmentsGroupResult
              isSegmentGroupApplied={isSegmentGroupApplied}
              segmentGroupDashboardData={segmentGroupDashboardData}
              customDatesData={customDatesData}
              isExpandOverAllWidget={isExpandOverAllWidget}
              getKeyPhrases={(phrases) => this.getKeyPhrases(phrases)}
              expandWidgets={(i, w) => this.expandWidgets(i, w)}
              showOpenTextNonMatchRightPanel={(phrases, openTextSegmentValue) => this.showOpenTextNonMatchRightPanel(phrases, openTextSegmentValue)}
              associatedStakeholdersWithFilterValue={associatedStakeholdersWithFilterValue}
              allUserFilterGroupValues={allUserFilterGroupValues}
              userSelectedFilterGroupValue={userSelectedFilterGroupValues}
              unsavedChangesModal={this.props.unsavedChangesModal}
              unsavedChanges={this.props.unsavedChangesModal}
              updateShowUnsavedChangesModal={this.updateShowUnsavedChangesModal}
              selectedSegmentValue={selectedSegmentValue}
              isSegmentApplied={isSegmentApplied}
              overallWidget={overallWidget}
              showOpenTextRightPanel={(phrases, selectedSegment) => this.showOpenTextRightPanel(phrases, selectedSegment)}
              isShowPercentageChange={showDateFilter}
              projectSegments={projectSegments}
              showHideOverlay={this.props.showHideOverlay}
              showOpenTextDropQuesRightPanel={this.showOpenTextDropQuesRightPanel}
            />}
            {!isSegmentGroupApplied && !insufficientData && overallWidget && (
              <div className="data-scroller-container" >
                <div className="data-container clearfix masonry-layout custom-data-container" >
                  {/* overall widget */}
                  <div className="col-t1">

                    <div className="data-item-box project-box">

                      <div className="a-panel a-shadow-sm text-center">
                        <div className="project-title">

                          <p className="m-0 capitalize-text">{overallWidget.title}</p>
                        </div>

                        <div className="project-percentage">
                          <h3 className={`a-dropdown-item ${overallWidget.showScore ? '' : 'no-oc-data'} `} >
                            <span style={overallWidget.scoreColor}>
                              {overallWidget.overallScore}%</span>
                          </h3>
                        </div>
                        {showDateFilter && <div className="project-progress ">
                          {customDatesData.fromDate && customDatesData.toDate &&
                            customDatesData.range !== constant.CurrentPeriodRange
                            && customDatesData.range !== constant.LastPeriodRange
                            && <p className={`m-0 ${overallWidget.showPercentageDiff
                              && overallWidget.showScore
                              ? '' : ' no-trends-data'} `}>
                              <i
                                /* eslint-disable no-nested-ternary */
                                className={`appkiticon
                        ${overallWidget.percentageChange === 0 || overallWidget.percentageChange === null ? 'grey-color no-min-width'
                                    : overallWidget.percentageChange < 0
                                      ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill'}
                               `}
                              /* eslint-disable no-nested-ternary */
                              >
                                <span>&nbsp;</span>
                                <span>{overallWidget.showPercentageDiff
                                  && overallWidget.showScore
                                  ? overallWidget.percentageChange === 0 ? 'No'
                                    : `${Math.abs(overallWidget.percentageChange)}%` : ''}</span>
                              </i>{' '}change in past {previousDaysCount} {pastDays}
                            </p>}
                          {customDatesData.fromDate && customDatesData.toDate &&
                            (customDatesData.range === constant.CurrentPeriodRange
                              || customDatesData.range === constant.LastPeriodRange)
                            && <p className={`m-0 ${overallWidget.showPercentageDiff
                              && overallWidget.showScore
                              ? '' : ' no-trends-data'} `}>
                              <i
                                /* eslint-disable no-nested-ternary */
                                className={`appkiticon
                        ${overallWidget.percentageChange === 0 || overallWidget.percentageChange === null ? 'grey-color no-min-width'
                                    : overallWidget.percentageChange < 0
                                      ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill'}
                               `}
                              /* eslint-disable no-nested-ternary */
                              >
                                <span>&nbsp;</span>
                                <span>{overallWidget.showPercentageDiff
                                  && overallWidget.showScore
                                  ? overallWidget.percentageChange === 0 ? 'No'
                                    : `${Math.abs(overallWidget.percentageChange)}%` : ''}</span>
                              </i>{' '}change in past {domainWidgets.length > 0 && customDatesData.range !== ''
                                ? domainWidgets[0].periodLength / 2 : 0} {domainWidgets.length > 0 && domainWidgets[0].periodLength > 2 && customDatesData.range !== '' ? 'weekdays' : 'weekday'}
                            </p>}
                          {customDatesData.fromDate === '' && customDatesData.toDate === '' && <p className={`m-0 ${overallWidget.showPercentageDiff
                            && overallWidget.showScore
                            ? '' : ' no-trends-data'} `}>
                            <i
                              /* eslint-disable no-nested-ternary */
                              className={`appkiticon
                        ${overallWidget.percentageChange === 0 || overallWidget.percentageChange === null ? 'grey-color no-min-width'
                                  : overallWidget.percentageChange < 0
                                    ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill'}
                               `}
                            /* eslint-disable no-nested-ternary */
                            >
                              <span>&nbsp;</span>
                              <span>{overallWidget.showPercentageDiff
                                && overallWidget.showScore
                                ? overallWidget.percentageChange === 0 ? 'No'
                                  : `${Math.abs(overallWidget.percentageChange)}%` : ''}</span>
                            </i>{' '}change in past {domainWidgets.length > 0
                              ? domainWidgets[0].periodLength / 2 : 0} {domainWidgets.length > 0 && domainWidgets[0].periodLength > 2 ? 'weekdays' : 'weekday'}
                          </p>}
                        </div>}
                        {overallWidget.showTrendLines && <div className={`graph-line
                       ${!showTrendLine ? ' blur-graph' : ''}`}
                          onMouseLeave={this.hideTooltip}
                        >
                          {activePosition && showTrendLine
                            && <span className="graph-tooltip"
                              style={{
                                left: `${activePosition.x - 20}px`,
                                top: `${parseFloat(activePosition.y) + 20}px`,
                              }}
                            >{Math.round(activePosition.score)}%  <br />{activePosition.date}
                            </span>
                          }
                          <ReactSvgLineChart
                            data={showTrendLine
                              ? overallWidget.trendData
                                .map(point => ({
                                  ...point,
                                  active: point.x === activePoint.x,
                                }))
                              : trendBlurData.map(point => ({
                                ...point,
                              }))
                            }
                            pointsOnHover={this.handlePointHover}
                            axisWidth={0}
                            gridWidth={0}
                            labelsVisible={false}
                            pathColor={!showTrendLine ? 'grey' : '#fa5928'}
                            pathVisible={true}
                            pointsColor={!showTrendLine ? 'grey' : '#fa5928'}
                            pointsRadius={3}
                            labelsCountY={20}
                            labelsStepX={1}
                            pointsStrokeColor={!showTrendLine ? 'grey' : '#fa5928'}
                            pointsStrokeWidth={2}
                            pointsVisible={true}
                            viewBoxHeight={50}
                            viewBoxWidth={250}
                          />
                        </div>}
                        <div className="a-accordion-wrapper ">
                          <div className="a-accordion">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.expandWidgets(null, overallWidget);
                              }}
                              className="a-accordion-title bg-transparent panel-expander" >
                              <i className={`appkiticon
                                    ${isExpandOverAllWidget ? ' icon-up-chevron-circle-outline'
                                  : ' icon-down-chevron-circle-outline'}
                                    `}
                              ></i></div>
                            {overallWidget.isExpanded && <div className="a-accordion-content bg-transparent show d-block">
                              {(overallWidget.noOfOpenTextQuestionsAssociated > 0) && <div className="project-section-title font-weight-normal">

                                {this.showNoOfOpenTextQuestions(overallWidget.noOfOpenTextQuestionsAssociated)}
                                {overallWidget.totalKeyPhraseCount > 0 || overallWidget.totalEmptyKeyPhraseCount > 0 ? null : <p className="text-center no-response">
                                  No responses yet
                                </p>}
                              </div>}
                              <div className="people-comments-section">
                                {overallWidget.talkAbout
                                  && overallWidget.talkAbout.length > 0
                                  && overallWidget.talkAbout.map((keyPhrase, index) => (
                                    <div title={keyPhrase.phrase} key={index} className={'a-badge badge-general'}
                                      onClick={() => {
                                        this.showOpenTextRightPanel(keyPhrase.phrase);
                                      }}
                                    >{keyPhrase.phrase}
                                    </div>
                                  ))
                                }
                                {(overallWidget.noOfOpenTextQuestionsAssociated == undefined || overallWidget.noOfOpenTextQuestionsAssociated === 0)
                                  && <div className="no-resp-data">
                                    There are no open text questions assigned to this topic
                                  </div>
                                }
                                {(overallWidget.totalKeyPhraseCount > 0
                                  || overallWidget.totalEmptyKeyPhraseCount > 0)
                                  && <div key={overallWidget.alltalkAboutLength} className={'a-badge a-badge badge-general'}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      this.props.showHideOverlay(true);
                                      this.showOpenTextNonMatchRightPanel(
                                        this.getKeyPhrases(overallWidget.talkAbout),
                                        selectedSegmentValue
                                      );
                                    }}
                                  >...
                                  </div>
                                }
                              </div>
                              {overallWidget.surveyEngagement && (
                                <div className="survey-section">
                                  <p className="project-section-title">  <MaterialIcon icon="person_outline" /> Stakeholder Response Rate
                                    <span class="pop-out-help user-guid">
                                      <i id="user_guidance" data-for="user_guidance"
                                        data-tip="result_tip"
                                        class="material-icons md-24 md-dark" currentitem="false">info</i>
                                      <ReactTooltip id='user_guidance' className='result_tip' place='bottom'
                                        effect='solid'>
                                        <span>The Stakeholder Response Rate is determined by dividing the total number of responses received by the total number of questions asked to all topic stakeholders. resulting in
                                          a % response rate.</span>
                                      </ReactTooltip>
                                    </span></p>
                                  <div className="a-customized-ratings a-customized-primary a-customized-white-background a-customized-8">
                                    <div className="a-ratings-detail">
                                      <div className="a-ratings-total">
                                        <div className="a-ratings-percent"
                                          style={{ width: `${overallWidget.surveyEngagement.project}%` }}>
                                        </div>
                                        {overallWidget.surveyEngagement.showRedLine
                                          && <div className="average-marker" style={{ left: `${overallWidget.surveyEngagement.allProjectsAvg}%` }}
                                          ></div>
                                        }
                                      </div>

                                    </div>

                                  </div>
                                  <div className="a-ratings-value">{overallWidget.surveyEngagement.project}%
                                    {overallWidget.surveyEngagement.project
                                      !== overallWidget.surveyEngagement.allProjectsAvg
                                      && overallWidget.surveyEngagement.showRedLine && (
                                        <span className='widget-survey-engagement'>
                                          {overallWidget.surveyEngagement.project
                                            > overallWidget.surveyEngagement.allProjectsAvg
                                            ? ' (Above Average)' : ' (Below Average)'}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              )}
                              {(customQuestionData.length > 0 || futureDropCustomQuestions.length > 0) && <div className="panel-actions">
                                <div style={{ marginRight: '7px' }}>
                                  <ReactSVG style={{ marginRight: '7px' }} wrapper='svg' width="20px" height="20px" src={customQuestionsIconSvg} />
                                  {customQuestionData.length} Custom Questions</div>
                                <div className=" "><a href="javascript:void(0)"
                                  onClick={() => {
                                    this.props.showHideOverlay(true);
                                    this.showCustomQuestionsDetailRightPanel(overallWidget.isOptionalCommentAnonymity);
                                  }}>View All</a></div>
                              </div>
                              }
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* custom domain widgets */}
                  <div className="widgets-grid"
                    style={{ width: domainWidgets && domainWidgets.length ? (Math.floor(domainWidgets.length / 2) * 300) : '0px' }}
                  >
                    {domainWidgets && domainWidgets.length > 0 && domainWidgets.map((widget, index) => (
                      widget.isInEditMode ? (
                        <div key={index} className={`col-t1 customise-domain-box-wrap`}>
                          <div className="data-item-box project-box">
                            <div className="a-panel pl-0 pr-0 pt-2 pb-2 a-shadow-sm text-center">
                              <Formik
                                enableReinitialize={true}
                                initialValues={widget}
                                validateOnBlur={true}
                                onSubmit={(values, actions) => {
                                  const formData = { ...values };
                                  formData.projectId = selectedProject.ProjectId;
                                  this.validateAndSaveDomain(formData, actions);
                                }}
                                validationSchema={Yup.object().shape({
                                  domainName: Yup.string()
                                    .trim()
                                    .required(`Focus area name is required.`)
                                    .max(50, 'Character limit exceeded.'),
                                  attributes: Yup.array().unique("Attribute already exists, please enter a different value"),
                                })}
                              >
                                {({
                                  errors, touched, setFieldValue, values, setFieldTouched, setFieldError,
                                }) => {

                                  // eslint-disable-next-line
                                  return <Form>
                                    <div className="custom-tag">
                                      Custom
                                    </div>
                                    <div className="crossIcon" >
                                      <span data-for='discardCustomDomainEditMode' data-tip='tooltip' onClick={() => this.discardUnsavedChanges()} class="material-icons">
                                        close
                                      </span>
                                      <ReactTooltip id="discardCustomDomainEditMode" className='tooltip' place='top'
                                        effect='solid'>
                                        Discard changes
                                      </ReactTooltip>
                                    </div>
                                    <div className="main-icon">
                                      <ReactSVG src={customDomainSvg} />
                                    </div>
                                    <div className={`main-textbox ${errors.domainName ? 'pb-25' : ''}`}>
                                      <div className={`focusAreaCharCount ${customDomainTitleCharCount > 50 ? 'color-red' : ''}`}>{customDomainTitleCharCount}/50 characters</div>
                                      <Field
                                        name="domainName"
                                        autoComplete="off"
                                        className={
                                          errors.domainName ? 'a-input-field-error a-text-input' : 'a-text-input'
                                        }
                                        onChange={e => this.setFieldValueOnChange('domainName', e, setFieldValue)}
                                        placeholder="Focus Area"
                                      />
                                      <div id="attr-error-block" className="error text-left">
                                        {' '}
                                        <ErrorMessage name="domainName" />
                                      </div>
                                    </div>
                                    <div className="custom-ques-box-body">
                                      <FieldArray
                                        name="attributes"
                                        render={arrayHelpers => (
                                          <div>
                                            {values.attributes && values.attributes.length > 0 && values.attributes.map((attribute, index) => (
                                              <div id={index} className="fields-wrap">
                                                <div className="body-input-container">
                                                  <Field
                                                    name={`attributes[${index}].title`}
                                                    autoComplete="off"
                                                    className="a-text-input"
                                                    onChange={e => this.setFieldValueOnChange(`attributes[${index}].title`, e, setFieldValue, index)}
                                                    placeholder="Attribute"
                                                    values={attribute.title}
                                                  />

                                                  <Field
                                                    name={`attributes[${index}].id`}
                                                    autoComplete="off"
                                                    className="a-text-input"
                                                    values={attribute.id}
                                                    type="hidden"
                                                  />
                                                  <i data-for='discardCustomAttribute' data-tip='tooltip' class="appkiticon mr-0 a-btn-icon icon-delete-outline" onClick={(e) => this.deleteCustomAttribute(index, setFieldValue)}></i>
                                                </div>

                                                <ReactTooltip id="discardCustomAttribute" className='tooltip' place='top'
                                                  effect='solid'>
                                                  Delete this attribute
                                                </ReactTooltip>
                                                <div className="error block text-left">
                                                  {' '}
                                                  <ErrorMessage name={`attributes[${index}].title`} />
                                                </div>
                                              </div>

                                            ))}
                                          </div>
                                        )}
                                      />
                                      {customAttributes && customAttributes.length < constant.MAX_CUSTOM_ATTRIBUTES && <a href="#" className="attribute-add" onClick={() => this.renderAttributes('attributes', setFieldValue)}>+ Add more attributes</a>}
                                    </div>
                                    <div className="custom-ques-box-footer text-right">
                                      {widget.domainId > 0 && (<button
                                        type="button"
                                        aria-label="Close"
                                        className="a-btn a-btn-default a-btn-lg a-btn-gray a-mr-10 cstm-dmn-del-btn"
                                        onClick={() => this.deleteCustomDomain(widget.domainId)}
                                      >
                                        Delete
                                      </button>)}
                                      <SpinnerButton
                                        isLoading={isCustomDomainSubmitting}
                                        label='Save'
                                        disabled={errors.domainName}
                                      />
                                    </div>
                                  </Form>
                                }}</Formik>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div key={index} className={`col-t1`}>
                          <div className="data-item-box project-box">
                            <div className="a-panel a-shadow-sm text-center">
                              {widget.isCustomDomain && <div class="custom-tag left-0">Custom</div>}


                              <div className="project-title d-flex justify-content-center align-items-center ">
                                {widget.isCustomDomain && isUserAuthoriseToView
                                  && !roles.projectViewer && <dev className="cstm-dmn-icn-grp"><div className="dropdown-icon">
                                    <div id='menu-icon-index' class="footer-dropdowns cursor-pointer">
                                      <MaterialIcon className="material-icons cursor-pointer" data-bs-toggle="dropdown" icon="more_horiz" />
                                      <div class="dropdown-menu dropdown-menu-right  a-shadow-sm border-0 a-user-setting-panel-one " x-placement="top-start"  >
                                        <ul class="user-infor-list">
                                          <li class="user-infor-list-item" onClick={() => this.editCustomDomain(widget.domainId)}>
                                            <a href="javascript:void(0)">Edit</a>
                                          </li>
                                          <li class="user-infor-list-item" onClick={() => this.deleteCustomDomain(widget.domainId)}>
                                            <a href="javascript:void(0)">Delete</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                  </div>
                                    <div className="dropdown-icon">
                                      {isSegmentDropDownLoaded && projectSegments && projectSegments.length > 0 && <SegmentDropDown projectSegments={projectSegments} selectedSegments={widget.domainSegments} assignSegments={(s) => this.assignSegmentsToDomain(s, widget.domainId, widget.domainName)} key={index} tooltipIdPostfix={index} isComparisonView={false} projectViewer={true} />}
                                    </div></dev>
                                }
                                {!widget.isCustomDomain && isUserAuthoriseToView
                                  && !roles.projectViewer && <div className="dropdown-icon">
                                    {isSegmentDropDownLoaded && projectSegments && projectSegments.length > 0 && <SegmentDropDown projectSegments={projectSegments} selectedSegments={widget.domainSegments} assignSegments={(s) => this.assignSegmentsToDomain(s, widget.domainId, widget.domainName)} key={index} tooltipIdPostfix={index} isComparisonView={false} projectViewer={true} />}

                                  </div>}
                                {roles.projectViewer ? <div className="dropdown-icon">
                                  {isSegmentDropDownLoaded && projectSegments && projectSegments.length > 0 &&
                                    <SegmentDropDown projectSegments={projectSegments} selectedSegments={widget.domainSegments}
                                      assignSegments={(s) => this.assignSegmentsToDomain(s, widget.domainId, widget.domainName)}
                                      key={index} tooltipIdPostfix={index} isComparisonView={false} projectViewer={true} />}</div>
                                  : roles.portfolioStakeholder ?
                                    <div className="dropdown-icon">
                                      {isSegmentDropDownLoaded && projectSegments && projectSegments.length > 0 &&
                                        <SegmentDropDown projectSegments={projectSegments} selectedSegments={widget.domainSegments}
                                          assignSegments={(s) => this.assignSegmentsToDomain(s, widget.domainId, widget.domainName)}
                                          key={index} tooltipIdPostfix={index} isComparisonView={false} projectViewer={true} />}

                                    </div> : roles.projectStakeholder ?
                                      <div className="dropdown-icon">
                                        {isSegmentDropDownLoaded && projectSegments && projectSegments.length > 0 &&
                                          <SegmentDropDown projectSegments={projectSegments} selectedSegments={widget.domainSegments}
                                            assignSegments={(s) => this.assignSegmentsToDomain(s, widget.domainId, widget.domainName)}
                                            key={index} tooltipIdPostfix={index} isComparisonView={false} projectViewer={true} />}

                                      </div> : null}
                                <p className="m-0" data-for={widget.domainName} data-tip
                                  onMouseEnter={
                                    () => {
                                      if (widget.description) {
                                        this.setTooltipPosition(
                                          `${widget.domainName}`,
                                          constant.tooltipArea.domain,
                                        )
                                      }
                                    }
                                  }>{widget.domainName}</p>

                                {/* {widget.description && <span class="pop-out-help d-inline-flex desc_icons">
                                <MaterialIcon id={widget.domainName} data-for={widget.domainName} data-tip icon="help"
                                  onMouseEnter={
                                    () => this.setTooltipPosition(
                                      `${widget.domainName}`,
                                      constant.tooltipArea.domain,
                                    )
                                  } />
                              </span>} */}
                                {widget.description && <ReactTooltip id={widget.domainName} className='tooltip' place={tooltipPosition}
                                  effect='solid'>
                                  {widget.description}
                                </ReactTooltip>}
                              </div>

                              <div className="project-percentage project-page">
                                {/* add domain icon here */}
                                {widget.isCustomDomain && (<div className="main-icon main-icon-cstm-domain">
                                  <ReactSVG src={customDomainSvg} />
                                </div>)}
                                {!widget.isCustomDomain && (<i
                                  className={`appkiticon
                    ${widget.icon} `}
                                >
                                </i>)}
                                <h3
                                  className={`a-dropdown-item
                    ${widget.showScore ? '' : 'no-oc-data'} `}
                                >
                                  <span style={widget.scoreColor}>
                                    {(widget.overallScore)}%
                                  </span>

                                </h3>
                              </div>
                              {showDateFilter && <div className="project-progress ">
                                {customDatesData.fromDate && customDatesData.toDate
                                  && customDatesData.range !== constant.CurrentPeriodRange
                                  && customDatesData.range !== constant.LastPeriodRange && <p
                                    className={`m-0
                    ${widget.showPercentageDiff
                                        && widget.showScore ? '' : ' no-trends-data'} `}
                                  >
                                    <i
                                      /* eslint-disable no-nested-ternary */
                                      className={`appkiticon
                    ${widget.percentageChange === 0 || widget.percentageChange === null ? 'grey-color'
                                          : widget.percentageChange < 0 ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill'} `}
                                    /* eslint-disable no-nested-ternary */
                                    >
                                      <span>&nbsp;</span>
                                      <span>  {widget.showPercentageDiff
                                        && widget.showScore ? widget.percentageChange === 0 ? 'No'
                                        : `${Math.abs(widget.percentageChange)}%` : ''}</span>
                                    </i>{' '}change in past {previousDaysCount} {pastDays}


                                  </p>}
                                {customDatesData.fromDate && customDatesData.toDate
                                  && (customDatesData.range === constant.CurrentPeriodRange
                                    || customDatesData.range === constant.LastPeriodRange) && <p
                                      className={`m-0
                    ${widget.showPercentageDiff
                                          && widget.showScore ? '' : ' no-trends-data'} `}
                                    >
                                    <i
                                      /* eslint-disable no-nested-ternary */
                                      className={`appkiticon
                    ${widget.percentageChange === 0 || widget.percentageChange === null ? 'grey-color'
                                          : widget.percentageChange < 0 ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill'} `}
                                    /* eslint-disable no-nested-ternary */
                                    >
                                      <span>&nbsp;</span>
                                      <span>  {widget.showPercentageDiff
                                        && widget.showScore ? widget.percentageChange === 0 ? 'No'
                                        : `${Math.abs(widget.percentageChange)}%` : ''}</span>
                                    </i>{' '}change in past {widget.periodLength / 2} weekday{widget.periodLength / 2 > 1 ? 's' : ''}


                                  </p>}
                                {customDatesData.fromDate === '' && customDatesData.toDate === '' && <p
                                  className={`m-0
                    ${widget.showPercentageDiff
                                      && widget.showScore ? '' : ' no-trends-data'} `}
                                >
                                  <i
                                    /* eslint-disable no-nested-ternary */
                                    className={`appkiticon
                    ${widget.percentageChange === 0 || widget.percentageChange === null ? 'grey-color'
                                        : widget.percentageChange < 0 ? ' icon-downtrend-fill icon-downtrend-color' : ' icon-uptrend-fill'} `}
                                  /* eslint-disable no-nested-ternary */
                                  >
                                    <span>&nbsp;</span>
                                    <span>  {widget.showPercentageDiff
                                      && widget.showScore ? widget.percentageChange === 0 ? 'No'
                                      : `${Math.abs(widget.percentageChange)}%` : ''}</span>
                                  </i>{' '}change in past {widget.periodLength / 2} weekday{widget.periodLength / 2 > 1 ? 's' : ''}


                                </p>}
                              </div>}
                              <div className="a-accordion-wrapper ">
                                <div className="a-accordion">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.expandWidgets(index, widget);
                                    }}
                                    className="a-accordion-title bg-transparent panel-expander" >
                                    <i className={`appkiticon
                                    ${widget.isExpanded ? ' icon-up-chevron-circle-outline'
                                        : ' icon-down-chevron-circle-outline'}
                                    `}
                                    ></i></div>
                                  {widget.isExpanded && <div className="a-accordion-content bg-transparent show d-block">
                                    <div className="tag-container">
                                      {widget.attributes.map((attr) => {
                                        const domainQuestionCount = customQuestionBasket.customQuestions
                                          .filter(x => x.attributeId === attr.id
                                            && x.domainId === widget.domainId);
                                        const noOfAutoQuestions = customQuestionBasket.automatedQuestions.reduce((acc, cur) => {
                                          return [...acc, ...cur.questions || []]
                                        }, []);
                                        const autoQuesCount = noOfAutoQuestions
                                          .filter(x => x.attributeId === attr.id
                                            && x.domainId === widget.domainId);
                                        const savedcustomQuestion = customQuestionData.filter(x => x.attribute_id === attr.id
                                          && x.domain_id === widget.domainId);
                                        const questionCount = domainQuestionCount.length
                                          + autoQuesCount.length + savedcustomQuestion.length;
                                        let disableAttribute = false;
                                        customQuestionBasket.disableDomainAttribute.map((item) => {
                                          var arr = item.split(',');
                                          if (attr.id == parseInt(arr[1]) && attr.domain_id == parseInt(arr[0])) {
                                            disableAttribute = true;
                                            return
                                          }
                                        });
                                        if (attr.isAttrAssociatedWithQuestions) {
                                          return (
                                            <div key={attr.id}
                                              id={attr.title}
                                              className={`a-tag a-tag-d2e attribute-pill ${showCustomQuestionOption ? 'custom-menu' : ''} ${attr.color} ${questionCount > 0 ? 'tagnumber' : ''} `}
                                              data-for={attr.title} data-tip
                                              onClick={() => {
                                                this.props.showHideOverlay(true);
                                                this.showOptionalCommentRightPanel(
                                                  attr.id,
                                                  attr.title,
                                                  widget.domainName,
                                                  attr.description,
                                                  attr.isShowCommentBox,
                                                  widget.domainId,
                                                  attr.isPassed,
                                                  showCustomQuestionOption,
                                                  attr.is_custom_attribute,
                                                  widget.isCustomDomain,
                                                  showCustomQuestionOption,
                                                  attr.score,
                                                  overallWidget.isOptionalCommentAnonymity,
                                                  associatedStakeholdersWithFilterValue,
                                                  allUserFilterGroupValues,
                                                  this.state.userSelectedFilterGroupValues,
                                                  overallWidget.projectDraftedStatus,
                                                  widget.domainSegments,
                                                  isProjectPortfolioActive,
                                                  attr.isDomainAnonymityOverride,
                                                );
                                              }}>
                                              <span className="a-mr-10 tag-title">
                                                {attr.title}  </span>
                                              <span
                                                className={'appkiticon-cust right-icon d-flex'}
                                              >
                                                {((attr.isShowCommentBox && overallWidget.isOptionalCommentAnonymity && attr.attrCommentAnonymityFlag) || (!attr.isDomainAnonymityOverride && attr.isShowCommentBox && attr.attrCommentAnonymityFlag)) && <i class="fa fa-commenting mr-1 cm-color" aria-hidden="true"></i>}
                                                {`${attr.score === '' ? '' : `${attr.score}%`}`}
                                              </span>
                                              {(attr.title && attr.title.length > 25) && <ReactTooltip id={attr.title} className='tooltip' place={tooltipPosition}
                                                effect='solid' >
                                                {attr.title}
                                              </ReactTooltip>}
                                              {isUserAuthoriseToView && !roles.projectViewer && <div className="tag-hover-menu"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (((!overallWidget.projectDraftedStatus && projectStakeholdersCount != 0)
                                                    || widget.isCustomDomain) && isProjectPortfolioActive) {
                                                    if (this.props.showProcessingRecords) {
                                                      return;
                                                    }
                                                    this.setState({
                                                      showAddCustomQuestionModel: true,
                                                      currentAttributeId: attr.id,
                                                      currentAttributeName: attr.title,
                                                      currentDomainId: widget.domainId,
                                                      currentDomainName: widget.domainName,
                                                      isCustomDomain: widget.isCustomDomain,
                                                      dSegments: widget.domainSegments,
                                                      isAutomatedQuestion: false,
                                                    });
                                                  }
                                                }}
                                                aria-disabled={true}>
                                                <span onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (((!overallWidget.projectDraftedStatus && projectStakeholdersCount != 0)
                                                    || widget.isCustomDomain) && isProjectPortfolioActive) {
                                                    if (this.props.showProcessingRecords) {
                                                      return;
                                                    }
                                                    this.setState({
                                                      showAddCustomQuestionModel: true,
                                                      currentAttributeId: attr.id,
                                                      currentAttributeName: attr.title,
                                                      currentDomainId: widget.domainId,
                                                      currentDomainName: widget.domainName,
                                                      isCustomDomain: widget.isCustomDomain,
                                                      dSegments: widget.domainSegments,
                                                      isAutomatedQuestion: false,
                                                    });
                                                  }
                                                }} className='custom-tooltip'>
                                                  <ReactSVG src={askCustomQuestionSvg} wrapper="svg" className={this.disableUnableQuestion(overallWidget, projectStakeholdersCount, widget)} />
                                                  <div>
                                                    <span className='tooltip-text'>Ask a custom question</span>
                                                  </div>
                                                </span>

                                                {/* temporary Commented
                                              <ReactTooltip id='customQuestion' className='custom-tooltip' place='bottom'
                                                effect='solid'>
                                                Ask a custom question
                                            </ReactTooltip> */}
                                                {/* temporary Commented
                                              * for #Product Backlog Item 10897: Hide the option for autogenerated questions
                                              {!widget.isCustomDomain && (
                                                <React.Fragment>
                                                  <span onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (autoQuesCount.length <= 0 && projectStakeholdersCount !== 0
                                                      && !disableAttribute && !overallWidget.projectDraftedStatus
                                                      && isProjectPortfolioActive) {
                                                      this.setState({
                                                        showAddCustomQuestionModel: true,
                                                        currentAttributeId: attr.id,
                                                        currentAttributeName: attr.title,
                                                        currentDomainId: widget.domainId,
                                                        currentDomainName: widget.domainName,
                                                        isCustomDomain: widget.isCustomDomain,
                                                        dSegments: widget.domainSegments,
                                                        isAutomatedQuestion: true,
                                                      });
                                                    }
                                                  }}
                                                    data-for={`${autoQuesCount.length > 0 || disableAttribute ? 'additionalQuestion' : ''}`} data-tip>
                                                    <ReactSVG src={askAutoQuestionSvg} wrapper="svg" className={this.disableUnableQuestion(overallWidget, projectStakeholdersCount, widget)} data-tip={true} />
                                                  </span>

                                                  {autoQuesCount.length === 0 && !disableAttribute
                                                    && <ReactTooltip id='additionalQuestion' className='tooltip' place='bottom'
                                                      effect='solid'>
                                                      Auto-generate more questions
                                                    </ReactTooltip>
                                                  }
                                                  {autoQuesCount.length > 0 && !disableAttribute
                                                    && <ReactTooltip id='additionalQuestion' className='tooltip' place='bottom'
                                                      effect='solid'>
                                                      You can only auto-generate 1 set of
                                                      questions per attribute,
                                                      per out of cycle drop
                                                    </ReactTooltip>
                                                  }
                                                  {disableAttribute
                                                    && <ReactTooltip id='additionalQuestion' className='tooltip' place='bottom'
                                                      effect='solid'>
                                                      All auto genrated questions for this attribute have
                                                      already deployed to the stakeholders.
                                                    </ReactTooltip>
                                                  }
                                                </React.Fragment>
                                              )}
                                              */}
                                              </div>}

                                              {questionCount > 0
                                                && <div className="tag-number-badge">{questionCount}</div>}
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                  }
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>)
                    ))}
                    <Feedback pageRef={`/${projectReplaceText && projectReplaceText.toLowerCase()}-dashboard`} selectedProjectTitle={selectedProject.ProjectTitle} isShowSupportBtn={true} />
                  </div>
                </div>
              </div>

            )}
          </RenderIf>
        </RenderIf >
        <RenderIf showComponent={showAddCustomQuestionModel}>
          <AddCustomQuestion
            projectId={this.props.selectedProject.ProjectId}
            domainId={this.state.currentDomainId}
            attributeId={this.state.currentAttributeId}
            domainName={this.state.currentDomainName}
            attributeName={this.state.currentAttributeName}
            isCustomDomain={this.state.isCustomDomain}
            associatedStakeholdersCount={associatedStakeholdersWithFilterValue}
            filterGroupValues={dSegments.includes(0) ? allUserFilterGroupValues : []}
            userFilterGroupValue={dSegments.includes(0) ? this.state.userSelectedFilterGroupValues : null}
            customQuestionDeployed={false}
            cancelClick={() => this.setState({
              showAddCustomQuestionModel: false,
            })}
            selectedSegmentValue={selectedSegmentValue}
            createSegment={dSegments.includes(0) ? allUserFilterGroupValues?.length > 0 ? 'yes' : 'no' : 'no'}
            dSegments={dSegments}
            sendCustomQuestions={(questions) => this.sendCustomDomainQuestions(questions)}
            showWarningMessage={allUserFilterGroupValues.length ? !showWarningMessage : false}
            isAutomatedQuestion={isAutomatedQuestion}
            userSelectedFilterGroupValues={this.state.userSelectedFilterGroupValues}
          />
        </RenderIf>
        <RenderIf showComponent={this.state.showDeleteModal}>
          <DeleteConfirmation
            title={`Delete ${this.state.slectedtext} ?`}
            message={[<p>This will delete the segement permanently.
              You cannot undo this action.</p>]}
            confirmationClick={() => this.deleteSegement()}
            cancelClick={() => this.setState({ showDeleteModal: false })}
            deleteButtomLabel={'Yes, delete it'}

          />
        </RenderIf>
        <DeleteAlertWithTimer
          customQuestionBasket={customQuestionBasket}
          selectedProject={selectedProject}
        />
        <div className="success-alert-with-timer a-alert custom-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success">
          <div className="a-alert-content">
            <div className="a-alert-badge success">
              <span className="appkiticon icon-confirmed-fill"></span>
            </div>
            <span className="a-content preview-pane-font">
              {`${customQuestionBasket.questionCount} additional question
          sent to ${selectedProject.ProjectTitle} stakeholders`}
            </span>
            <span class="close-btn a-countdown-wrapper" data-hide="a-alert">
              <span class="appkiticon icon-close-fill"></span>
            </span>
          </div>
        </div>
        {
          openTextTypeQuestionsAddedToTopic && (<SuccessAlertWithTimer
            alertMesage={openTextTypeQuestionsSuccessMessage}
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {
          this.props.isQuestionEmailResent === true && (<SuccessAlertWithTimer
            // alertMesage={this.props.resendStakeHolderFirstName ? `${this.props.resendStakeHolderFirstName} ${this.props.resendStakeHolderLastName}` : `${this.props.resendStakeHolderEmail}`}
            alertMesage={`Question drop email has been re-sent to ${this.props.resendStakeHolderFirstName ? this.props.resendStakeHolderFirstName + ' ' + this.props.resendStakeHolderLastName : this.props.resendStakeHolderEmail}`}
            isFromProjectLaunch={false}
            isFromResentQuestions={true}
            closeResentQuestions={() => this.props.closeResentQuestionsAlert()}
            showSuccessAlert={true}
          />)

        }
        {
          customQuestionsAddedToTopic && (<SuccessAlertWithTimer
            alertMesage={customQuestionsSuccessMessage}
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {isReportLoading && <ReportSuccess />}
        {
          customDomainAddedToTopic && (<SuccessAlertWithTimer
            alertMesage={customDomainSuccessMessage}
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {
          customQuestionBasket.laterQuestionSaveMessage && (<SuccessAlertWithTimer
            alertMesage={customQuestionBasket.laterQuestionSaveMessage + selectedProject.ProjectTitle}
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {
          !importStackholdersFlag && isFilterGroupSaveSuccessfully && (<SuccessAlertWithTimer
            alertMesage="Filter tags for topic stakeholders have been updated, click Save in the stakeholder pane to save your filters"
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {
          isSegmentSavedSuccessfully && (<SuccessAlertWithTimer
            alertMesage="New segment successfully saved"
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {
          isSegmentAlreadyCreated && (<FailureAlertWithTimer
            alertMesage={duplicateMessage}
            isRequestFromSegment={true}
            showFailureAlert={true}
          />)
        }
        {
          areSegmentsAssignedSuccessfully && (<SuccessAlertWithTimer
            alertMesage={segmentsAssigningMessage}
            isFromProjectLaunch={false}
            showSuccessAlert={true}
          />)
        }
        {
          isProjectConfigured && (<FailureAlertWithTimer
            alertMesage={`Not able to launch ${selectedProject.ProjectTitle} as there are no stakeholders and/or focus area added to this topic.`}
            isRequestFromSegment={false}
            showSuccessAlert={true}
            showFailureAlert={true}
          />)
        }
        {
          isProjectLaunchSuccessfully && (<SuccessAlertWithTimer
            alertMesage={`${selectedProject.ProjectTitle} is launched.`}
            isFromProjectLaunch={true}
            showSuccessAlert={true}
          />)
        }
        {
          isSendAgedQuestionMailSuccessfully && (<SuccessAlertWithTimer
            alertMesage='Reminder for current question cycle successfully sent.'
            isFromProjectLaunch={true}
            showSuccessAlert={true}
          />)
        }
        {
          isDropCancelledSuccessfully && (<SuccessAlertWithTimer
            alertMesage={`Question drop for ${this.props.projectDetails.name} is cancelled.`}
            isFromProjectLaunch={true}
            showSuccessAlert={true}
          />)
        }
        {
          this.props.isQuestionUrlCopied === true && (<SuccessAlertWithTimer
            alertMesage={`Question link for ${this.props.copiedLinkStakeHolderFirstName ? this.props.copiedLinkStakeHolderFirstName + ' ' + this.props.copiedLinkStakeHolderLastName : this.props.copiedLinkStakeHolderEmail} successfully copied.`}
            isFromProjectLaunch={false}
            isFromQuestionUrlLink={true}
            closeQuestionurlLinkAlert={() => this.props.closeQuestionUrlLinkAlert()}
            showSuccessAlert={true}
          />)
        }
      </div >
    );
  }
}

const mapStateToProps = state => ({
  isPortfolioExist: isAnyPortfolioExist(state),
  valchek: state.project.isProjectCheckValue,
  selectedPortfolio: getSelectedPortfolio(state),
  selectedProject: getSelectedProject(state),
  overAllWidget: state.project.overAllWidget,
  projectDetails: state.project.projectDetails,
  isSysAdmin: state.login.isSysAdmin,
  roles: state.portfolio.portfolioData.roles,
  systemTerminology: getSystemTerminologySelector(state),
  userFilterGroups: state.project.userFilterGroups,
  customQuestionBasket: state.project.customQuestionBasket,
  relevantDomains: state.project.relevantDomains,
  unsavedChangesModalFocusDomain: state.project.unsavedChangesModalFocusDomain,
  unsavedChangesFocusDomain: state.project.unsavedChangesFocusDomain,
  unsavedChangesModalCustomDomain: state.project.unsavedChangesModalCustomDomain,
  unsavedChangesCustomDomain: state.project.unsavedChangesCustomDomain,
  isOpenDomainFocusPanel: state.project.isOpenDomainFocusPanel,
  showOpenTextPanelBool: state.project.showOpenTextPanelBool,
  domainWidgets: state.project.domainWidgets,
  projectStakeholdersCount: state.project.projectStakeholdersCount,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  isFilterGroupSaveSuccessfully: state.project.isFilterGroupSaveSuccessfully,
  projectSegments: state.project.projectSegments,
  isProjectSegmentsLoaded: state.project.isProjectSegmentsLoaded,
  isProjectStakeholderAssociated: state.project.isProjectStakeholderAssociated,
  questionSentToFutureDrop: state.project.questionSentToFutureDrop,
  questionSentToFutureDropMessage: state.project.questionSentToFutureDropMessage,
  segmentGroupValue: state.project.segmentGroupValue,
  segmentGroupOverall: state.project.segmentGroupOverall,
  duplicateSegment: state.project.duplicateSegment,
  isQuestionEmailResent: state.project.isQuestionEmailResent,
  resendStakeHolderFirstName: state.project.resendStakeHolderFirstName,
  resendStakeHolderLastName: state.project.resendStakeHolderLastName,
  resendStakeHolderEmail: state.project.resendStakeHolderEmail,
  isQuestionUrlCopied: state.project.isQuestionUrlCopied,
  copiedLinkStakeHolderFirstName: state.project.copiedLinkStakeHolderFirstName,
  copiedLinkStakeHolderLastName: state.project.copiedLinkStakeHolderLastName,
  copiedLinkStakeHolderEmail: state.project.copiedLinkStakeHolderEmail,
  isProjectActive: state.project.isProjectActive,
  importStackholdersFlag: state.project.importStackholdersFlag,
  nextAdhocDropDate: state.project.nextAdhocDropDate,
  adhocDropOffset: state.project.adhocDropOffset,
  dropTimeZone: state.project.dropTimeZone,
  isProjectHavingNextAdhocDrop: state.project.isProjectHavingNextAdhocDrop,
  isProjectAdhoc: state.project.isProjectAdhoc,
  projectUpdatedStatus: state.project.projectUpdatedStatus,
  isExternalUser: state.login.isExternalUser,
  newdata: state.project.newdata,
  isTopicReadyForArchive: state.project.isTopicReadyForArchive,
});

const mapDispatchToProps = {
  isChecking,
  deleteSegementsData,
  showProjectRightPanel,
  hideProjectRightPanel,
  hideOptionalCommentRightPanel,
  showOptionalCommentRightPanel,
  push,
  showProjectStakeholderRightPanel,
  getProjectDashboardToolbarDetails,
  updateProjectStage,
  updateProjectSaveState,
  showOpenTextRightPanel,
  getSystemTerminology,
  changeSelectedPortfolioAndProject,
  showOpenTextNonMatchRightPanel,
  getUserFilterGroupsForProject,
  getUserTagFiltersData,
  getPortfolioQuestionModelList,
  showCustomQuestionRightPanel,
  getAutomatedQuestions,
  emptyCustomQuestionBasket,
  sendCustomQuestionsData,
  saveOpenTextTypeQuestionsForTopic,
  getProjectDomainsAction,
  checkUncheckDomain,
  submitProjectDomains,
  disableUnsavedChangesModalFocusDomain,
  disableUnsavedChangesFocusDomain,
  enableUnsavedChangesFocusDomain,
  enableUnsavedChangesModalFocusDomain,
  enableUnsavedChangesForCustomDomain,
  enableUnsavedChangesModalForCustomDomain,
  disableUnsavedChangesForCustomDomain,
  disableUnsavedChangesModalForCustomDomain,
  openDomainFocusPanel,
  showOpenTextPanel,
  addNewCustomDomainToTopic,
  updateDomainsToTopic,
  saveTopicDomainAction,
  showCustomQuestionsDetailRightPanel,
  deleteRelevantDomains,
  deleteCustomDomain,
  getProjectStakeholders,
  projectAssociatedStakeholdersCount,
  createNewSegment,
  saveSegmentAlreadyCreated,
  getProjectSegments,
  getUserSegmentFiltersData,
  assignSegmentsToDomain,
  toggleProjectSegmentDrowdown,
  launchProjectToFirstQuestionCycle,
  cancelNextAdhocDrop,
  updateProjectStakeholderState,
  updateCurrentlySelectedProjectId,
  updatePendingCustomQuestion,
  updateProjectDraftedStatus,
  getSegmentGroupStakeholderCounts,
  updateQuestionSentToFutureDrop,
  saveProjectOverAllWidget,
  saveSegmentOverall,
  saveSelectedSegmentValue,
  saveSegmentGroupDashboard,
  saveSegmentGroupFormValues,
  sendAgedQuestionReminderNotification,
  closeResentQuestionsAlert,
  getProjectDetailsAction,
  getPortfoliosData,
  userSelectedfilterGroupValuesClear,
  userSelectedfilterGroupValuesUpdate,
  showRedBanner,
  hideRedBanner,
  closeQuestionUrlLinkAlert,
  projectActiveState,
  projectAdhocDropDate,
  projectUpdatedData,
  showHideOverlay,
  showOpenTextDropQuesRightPanel,
  generateExcelReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Projects);
